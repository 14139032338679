import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";

const EllipsisButtonCourseNav = ({ title, buttonNumber, isActive, onClick }) => {
  const [btnSize, setBtnSize] = useState(getButtonSize());

  function getButtonSize() {
    const screenSize = window.innerWidth;
    if (screenSize < 576) {
      return "md"; // No size class for X-Small screens
    } else if (screenSize < 768) {
      return "md"; // Small screens
    } else if (screenSize < 992) {
      return "sm"; // Medium screens
    } else if (screenSize < 1200) {
      return "md"; // Large screens
    } else if (screenSize < 1400) {
      return "xl"; // Extra large screens
    } else {
      return "xxl"; // Extra extra large screens
    }
  }

  useEffect(() => {
    function handleResize() {
      setBtnSize(getButtonSize());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    onClick(buttonNumber);
  };

  const classes = isActive ? `ellipsis-button-course-nav active ` : `ellipsis-button-course-nav `;
  return (
    <Button variant="default" size={btnSize} className={classes} onClick={handleClick}>
      {title}
    </Button>
  );
};

export default EllipsisButtonCourseNav;
