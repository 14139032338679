
import React, { useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import TruncatedSpanText from "./TruncatedSpanText";

function CardEvent({ params }) {
  const [dataEvents, setDataEvents] = useState([]);

  const filteredParams = params.filter && params
  .filter(item => item.dateFormated)
  .slice(-3);

  const isMobile = window.innerWidth <= 575.98; 
  const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
  const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
  const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
  const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
  const isExtraExtraLarge = window.innerWidth >= 1400;
  
  useEffect(() => {
    setDataEvents(filteredParams); // error came from here
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short' };
    return new Intl.DateTimeFormat('id-ID', options).format(date);
  };

  return (
    <Card>
      <Card.Body className="card-event">
        <Card.Title
          className="d-flex justify-content-between align-items-center"
          style={{ fontSize: "13px", fontWeight: 'bold'  }}
        >
          Next Event{" "}
          <Link to='/calendar' className="view-all-activity" style={{fontSize: "13px"}}>
              View All
          </Link>
        </Card.Title>
        <div className="card-event-content">
          <ListGroup className="custom-list-group ">
            {dataEvents && dataEvents.map((item, index) => (
              <ListGroup.Item 
                className="custom-list-group-item mb-2"
                key={index}
              >
                <div className="d-flex justify-content-between custom-list-item">
                  <span>{formatDate(item.dateFormated)}</span>
                  {
                    isMobile ? (
                        <TruncatedSpanText text={item.courseName} maxLength={30} />
                    ) : isSmall ? (
                        <TruncatedSpanText text={item.courseName} maxLength={70} />
                    ) : isMedium ? (
                      <TruncatedSpanText text={item.courseName} maxLength={70} />
                    )  : isLarge ? (
                      <TruncatedSpanText text={item.courseName} maxLength={15} />
                    )  : isExtraLarge ? (
                      <TruncatedSpanText text={item.courseName} maxLength={15} />
                    )  : isExtraExtraLarge ? (
                      <TruncatedSpanText text={item.courseName} maxLength={15} />
                    ) : null
                  }
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CardEvent;