import React from 'react'
import { Badge, Card, Col, Container, Image, Row } from 'react-bootstrap';
import CircleImageDropdown from '../components/CircleImageDropdown';
import { Link, useLoaderData } from 'react-router-dom';
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { getAuthData } from '../util/auth';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector, useDispatch } from 'react-redux';

function Leaderboard() {
    const authData = getAuthData();

    useEffect(() => {
        if (!authData) {
            return;
        }
    }, [authData]);


    const decodedToken = jwt_decode(authData.jwt); // Decode the JWT to obtain the payload
    // const name = decodedToken.empName;
    // const empCode = decodedToken.empCode;
    // const foto = authData.foto;

    const [data, setData] = useState(null);
    const [sortedData, setSortedData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchProgress, setFetchProgress] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(null);

    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/me/get_leaderboard";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
    const currentYear = currentDate.getFullYear();

    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    const name    = useSelector((state) => state.user.empName);
    const empCode = useSelector((state) => state.user.empCode);
    const foto    = useSelector((state) => state.user.empPhoto);

    useEffect(() => {
        setIsFetching(true);
        setFetchProgress(0);
        const fetchData = async () => {
        try {

            const response = await fetch(
            url,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ 
                    'month': month,
                    'year': year
                }),
            }
            );

            if (!response.ok) {
            throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();

            setData(responseBody.data);
            setFilteredData(responseBody.data);
            setFetchProgress(100);
            setIsFetching(false);
            
        } catch (error) {
            setError(error);
            window.location.href = '/auth';
        }
        };
        fetchData();
    }, []);

    const CurrentMonthYear = currentDate.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
    });

    return (
        <>
            <Container fluid className="p-0 " >
                
            <Row className="">
                            <div className="d-flex justify-content-start pt-3 pb-1 mr-3 ">
                                <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <h6>Dashboard</h6> 
                                </Link>
                            </div>

                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-0 ">
                              <h1 className="h4">Leaderboard</h1>
                            </div>
                        </Row>

                <Row className="p-1 g-3">
                    <Col>
                        <Card className="mb-4" style={{background: 'white'}}>
                        {data !== null ? (
                            <Card.Body className="card-leaderboar-profile-summary">
                                <Row className=''>
                                    <Col className=" card-leaderboard-profile-info bg-white w-100">
                                        <Col lg={1} className=' d-flex justify-content-center'>
                                            <img src={`${foto}?timestamp=${Date.now()}`} alt="Profile" className="circle-image-dropdown-img" />
                                        </Col>
                                        <Col className=' text-start bg-white d-none d-md-block' >
                                            <h5 className="circle-image-dropdown-title bg-white">{name}</h5>
                                        </Col>
                                        {data && data.my_rank && (
                                            <Col lg={8} className=' '>
                                                <h5 className="circle-image-dropdown-title bg-white">
                                                Rank {data.my_rank[0].rank} . {data.my_rank[0].learning_hours} Hours . {data.my_rank[0].total_course} Course . {data.my_rank[0].score} Points
                                                </h5>
                                            </Col>
                                        )}
                                    </Col>

                                    <Col lg={2} md={2} className=" d-none d-md-flex d-lg-flex flex-column justify-content-center align-items-start align-items-md-center align-items-lg-center py-2">
                                        <Link
                                            className="link-my-rank" style={{ textDecoration: 'none', position: 'relative' }}>
                                            See My Rank
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    bottom: '-5px',
                                                    left: '0',
                                                    width: '100%',
                                                    height: '1px',
                                                    backgroundColor: '#0f4372'
                                                }}
                                            />
                                        </Link>
                                    </Col>
                                </Row>
                            </Card.Body>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "50px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                            
                        </Card>
                    </Col>
                </Row>

                <Row className="p-1 g-3 mb-4">
                    <Col lg={12}>
                        {data !== null ? (
                            <Card className="mb-4">
                                <Card.Body className="card-leaderboar-profile-summary">
                                    <Row className=''>
                                        <Col className=" card-leaderboard-profile-info bg-white w-100">
                                            <Col className=' text-start bg-white d-none d-md-block' >
                                                <h5 className="circle-image-dropdown-title bg-white">{CurrentMonthYear}</h5>
                                            </Col>
                                        </Col>

                                        <Col lg={2} md={2} className=" d-none d-md-flex d-lg-flex flex-column justify-content-center align-items-start align-items-md-center align-items-lg-center py-2">

                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-center mt-3 mb-4 ">
                                        <Col lg="3" className='d-flex justify-content-center' style={{ padding: '40px 0px 0px 0px' }}>
                                            <Card style={{ width: '14rem', maxHeight: '10rem'}} >

                                            {data && data.top_3 && data.top_3.length > 1 && (
                                                <Card.Body className='card-leaderboard p-0'>
                                                    <div className='p-3 text-center' style={{borderRadius: '15px'}}>
                                                    <Card.Img variant="top" src={data.top_3[1].foto} />
                                                    <Card.Title className='mt-3'>{data.top_3[1].empName}</Card.Title>

                                                    <Badge bsPrefix id='badge-point-leaderboard'>{data.top_3[1].score} Points</Badge>
                                                    <p className="mt-2">{data.top_3[1].learning_hours} Hours <b>&middot;</b> {data.top_3[1].total_course} Course</p>
                                                    </div>

                                                    <div style={{width: '100%', backgroundColor: "#86b939", height: '15px'}}></div>
                                                    <div style={{ width: '100%', backgroundColor: "#48851b", height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img
                                                        src="/2nd_rank.png" // Replace with the actual path to your image
                                                        alt="Profle Picture"
                                                        style={{ background: 'transparent' }}
                                                    />
                                                    </div>
                                                    <div style={{width: '100%', backgroundColor: "#86b939", height: '15px', borderRadius: '0px 0px 15px 15px'}}></div>
                                                </Card.Body>
                                            )}

                                            </Card>
                                        </Col>
                                        <Col md="auto" className='d-flex justify-content-center'>
                                            <Card style={{ width: '18rem'}} className='card-leaderboard'>
                                                {data && data.top_3 && data.top_3.length > 0 && (
                                                    <Card.Body className='card-leaderboard p-0'>
                                                        <div className='p-3 text-center' style={{borderRadius: '15px'}}>
                                                            <Card.Img variant="top" src={data.top_3[0].foto} />
                                                            <Card.Title className='mt-3'>{data.top_3[0].empName}</Card.Title>

                                                            <Badge bsPrefix id='badge-point-leaderboard' >{data.top_3[0].score} Points</Badge>
                                                            <p className="mt-2">{data.top_3[0].learning_hours} Hours <b>&middot;</b> {data.top_3[0].total_course} Course</p>
                                                        </div>

                                                        <div style={{width: '100%', backgroundColor: "#0d99c9", height: '15px'}}></div>
                                                        <div style={{ width: '100%', backgroundColor: "#007fb4", height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img
                                                            src="/1st_rank.png" // Replace with the actual path to your image
                                                            alt="Profle Picture"
                                                            style={{ background: 'transparent' }}
                                                        />
                                                        </div>
                                                        <div style={{width: '100%', backgroundColor: "#0d99c9", height: '15px', borderRadius: '0px 0px 15px 15px'}}></div>
                                                    </Card.Body>
                                                )}
                                            </Card>
                                        </Col>
                                        <Col lg="3" className='d-flex justify-content-center' style={{ padding: '40px 0px 0px 0px' }}>
                                            <Card style={{ width: '14rem', maxHeight: '10rem'}} >
                                                
                                                {data && data.top_3 && data.top_3.length > 2 && (
                                                    <Card.Body className='card-leaderboard p-0'>
                                                        <div className='p-3 text-center' style={{borderRadius: '15px'}}>
                                                            <Card.Img variant="top" src={data.top_3[2].foto} />
                                                            <Card.Title className='mt-3'>{data.top_3[2].empName}</Card.Title>

                                                            <Badge bsPrefix id='badge-point-leaderboard' >{data.top_3[2].score} Points</Badge>
                                                            <p className="mt-2">{data.top_3[2].learning_hours} Hours <b>&middot;</b> {data.top_3[2].total_course} Course</p>
                                                        </div>

                                                        <div style={{width: '100%', backgroundColor: "#86b939", height: '15px'}}></div>
                                                        <div style={{ width: '100%', backgroundColor: "#48851b", height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img
                                                            src="/3rd_rank.png" // Replace with the actual path to your image
                                                            alt="Profle Picture"
                                                            style={{ background: 'transparent' }}
                                                        />
                                                        </div>
                                                        <div style={{width: '100%', backgroundColor: "#86b939", height: '15px', borderRadius: '0px 0px 15px 15px'}}></div>
                                                    </Card.Body>
                                                )}

                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row className='p-3 mt-4'>
                                        <Col className="list-rank">
                                            <Row className="p-2 list-rank border-0" style={{fontWeight: 'bold'}}>
                                                <Col lg={1}  className="text-center">Rank</Col>
                                                <Col>Employee</Col>
                                                <Col lg={2}>Hours</Col>
                                                <Col lg={2}>Course</Col>
                                                <Col lg={1}>Points</Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    {data && data.others && data.others.map((item, index) => (
                                        <Row className='row-list-rank ' >
                                            <Col className="list-rank">
                                                <Row className="p-1 list-rank border-0 d-flex align-items-center" style={{fontWeight: 'normal'}}>
                                                    <Col lg={1} className="text-center">{index + 1 + data.top_3.length}</Col>
                                                    <Col>
                                                    <Row>
                                                        <Col lg={2} className=" p-0">
                                                        <Image className="image-participant" src={item.foto} roundedCircle />
                                                        </Col>
                                                        <Col lg={10} className="d-flex">
                                                            <div className="align-self-center">
                                                                <h5 style={{ backgroundColor: 'transparent' }}>{item.empName}</h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    </Col>
                                                    <Col lg={2}>{item.learning_hours}</Col>
                                                    <Col lg={2}>{item.total_course}</Col>
                                                    <Col lg={1}>{item.score}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}

                                    


                                </Card.Body>
                            </Card>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                    </Col>

                </Row>

            </Container>
        </>
    )
}

export default Leaderboard