// import React, { useState }  from "react";
import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';
import { useLocation, useSubmit } from "react-router-dom";
import {
  faThLarge,
  faLayerGroup,
  faBook,
  faCalendar,
  faWindowMaximize,
  faTasks,
  faComments,
  faStar,
  faUser,
  faSignOut,
  // faBook,
  // faWindowMaximize,
  // faTasks,
  // faComments,
  // faCalendar,
  // faStar,
  // faPager
} from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";
import BadgeInboxCount from "./BadgeInboxCount";

function ListMenu() {
  const bg_ = "bg-light pt-2 ";
  const [activeLink, setActiveLink] = useState('/');
  const submit = useSubmit();
  const handleLogout = () => {
      submit(null, { action: '/logout', method: 'post' });
  };
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-3 mb-1 bg-light">
                                                    <span className="fw-light text-dark bg-light text-opacity-25" style={{fontColor: '#0f4372 !im'}}>
                                                        Main Menu
                                                    </span> 
                                                </h6> */}
      <Nav defaultActiveKey="/dashboard" as="ul" className={`flex-column ${bg_}`} >
        <Nav.Item as="li" className="mb-1">
          <Nav.Link to="/" as={NavLink} className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}>
            <FontAwesomeIcon icon={faThLarge} className="nav-icon-sidebar" /> Dashboard
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li" className="mb-1"> 
          <Nav.Link to="/course" as={NavLink} className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}>
            <FontAwesomeIcon icon={faLayerGroup} className="nav-icon-sidebar" /> Course
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li" className="mb-1"> 
          <Nav.Link to="/myclass" as={NavLink} className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}>
            <FontAwesomeIcon icon={faBook} className="nav-icon-sidebar" /> My Class
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li" className="mb-1"> 
          <Nav.Link to="/webinar" as={NavLink} className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}>
            <FontAwesomeIcon icon={faWindowMaximize} className="nav-icon-sidebar" /> Webinar
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li" className="mb-1"> 
          <Nav.Link to="/evaluate" as={NavLink} className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}>
            <FontAwesomeIcon icon={faTasks} className="nav-icon-sidebar" /> Evaluate
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li" className="mb-1"> 
          <Nav.Link to="/calendar" as={NavLink} className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}>
            <FontAwesomeIcon icon={faCalendar} className="nav-icon-sidebar" /> Calendar
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li" className="mb-1"> 
          <Nav.Link to="/inbox" as={NavLink} className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}>
            <FontAwesomeIcon icon={faComments} className="nav-icon-sidebar" /> Inbox 
            {' '}
            <BadgeInboxCount isActive={useLocation().pathname === '/inbox'} /> 
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li"> 
          <Nav.Link to="/leaderboard" as={NavLink} className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}>
            <FontAwesomeIcon icon={faStar} className="nav-icon-sidebar" /> Leaderboard
          </Nav.Link>
        </Nav.Item>
    </Nav>

    {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 bg-light">
                                                    <span className="fw-light text-dark bg-light text-opacity-25">
                                                        Others
                                                    </span> 
                                                </h6> */}
        <Nav as="ul" className={`flex-column ${bg_}`} >
          <Nav.Item as="li" className="mb-1">
            <Nav.Link to="/my-profile" as={NavLink} className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}>
              <FontAwesomeIcon icon={faUser} className="nav-icon-sidebar" /> My Profile
            </Nav.Link>
          </Nav.Item>

          <Nav.Item as="li" className="mb-1">
            <Nav.Link to="#" className="" onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOut} className="nav-icon-sidebar" /> Logout
            </Nav.Link>
          </Nav.Item>
        </Nav>
    </>
  );
}

export default ListMenu;
