import { Button, Card, Col, Dropdown, DropdownButton, Form, Image, InputGroup, ListGroup, ProgressBar, Row, Stack } from "react-bootstrap";
import { json } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAuthData } from "../util/auth";
import CardTeamSummary from "./CardTeamSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate, faCircle, faClock, faDotCircle, faExclamationCircle, faListDots, faSearch } from "@fortawesome/free-solid-svg-icons";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function CardTeamContainer() {
  const isMobile = window.innerWidth <= 575.98; // Adjust the breakpoint as needed
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [sortOption, setSortOption] = useState(""); // Add sorting state

  const [isFetching, setIsFetching] = useState(false);
  const [fetchProgress, setFetchProgress] = useState(0);

  const nest_url = process.env.REACT_APP_NEST_URL;

  const secretCode = process.env.REACT_APP_SECRET_CODE;
  const token = getAuthData();

  const headers = {
    Authorization: `Bearer ${token.jwt}`,
    secret: secretCode,
    'Content-Type': 'application/json',
  };

  if (!token) {
    window.location.href = '/auth';
  }

  useEffect(() => {
    setIsFetching(true);
    setFetchProgress(0);
    const fetchData = async () => {
      try {
        console.log('selectedEmps ' + selectedEmp);
        let url;
        if (selectedEmp == null) {
          url = nest_url + "public/me/my_team";
        } else {
          url = nest_url + "public/me/my_team_detail";
        }

        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: selectedEmp != null ? JSON.stringify({ 'empCode': selectedEmp }) : undefined,
        });

        if (!response.ok) {
          throw new Error('Could not fetch data');
        }

        const responseBody = await response.json();

        if (selectedEmp == null) {
          setData(responseBody);
          setFilteredData(responseBody?.data || []);
        } else {
          // if (responseBody?.data !== undefined) {
          setData(responseBody);
          // }
        }
        setFetchProgress(100);
        setIsFetching(false);
      } catch (error) {
        setError(error);
        window.location.href = '/auth';
      }
    };

    // setTimeout(() => {
    //   fetchData();
    //   }, 600000);

    fetchData();
    // const fetchInterval = setInterval(fetchData, 2000);

    // return () => {
    //   clearInterval(fetchInterval);
    // };
  }, [selectedEmp]);

  const handleSearchQueryChange = event => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query === '') {
      setFilteredData(data?.data || []);
    } else {
      const filteredResponse = data?.data?.filter(item => {
        const empCodeMatch = item.empCode?.toLowerCase().includes(query.toLowerCase());
        const empNamaMatch = item.empNama?.toLowerCase().includes(query.toLowerCase());
        return empCodeMatch || empNamaMatch;
      }) || [];
      setFilteredData(filteredResponse);
    }
  };

  const handleSortOptionChange = option => {
    setSortOption(option);
    let sortedData = [...filteredData];
    if (option === "Employee Name (Asc)") {
      sortedData.sort((a, b) => a.empNama.localeCompare(b.empNama));
    } else if (option === "Employee Name (Desc)") {
      sortedData.sort((a, b) => b.empNama.localeCompare(a.empNama));
    }
    setFilteredData(sortedData);
  };

  useEffect(() => {
  }, [selectedEmp]);

  const handleButtonClick = (empCode) => {
    console.clear();
    console.log('empCode ' + empCode);
    setSelectedEmp(empCode);

  };


  if (selectedEmp == null) {
    return (
      <Card>
        <Card.Body className="card-overview h-100">
          <Col lg={12} md={12} className="mt-2 d-none d-md-block d-lg-block ">
            {/* <Row style={{ backgroundColor: "white" }}>

              <Col lg={6} md={8} xs={9} style={{ backgroundColor: "white"}} className=" d-flex justify-content-start  ">

                <div className=" search-sort-container p-0" >
                  <div className="search-input-container  p-0 " style={{ backgroundColor: "white" }} >
                    <input
                      type="text"
                      placeholder="Search Team"
                      className="search-input w-100"
                      value={searchQuery} onChange={handleSearchQueryChange}
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="search-icon"
                    />
                  </div>
                </div>
              </Col>

              <Col lg={{ span: 1, offset: 5 }} md={4} xs={3} className=" p-0" style={{ backgroundColor: "white" }}>
                <div className="dropdown-container" style={{ width: '100%', height: '100%', backgroundColor: "white" }}>
                  <DropdownButton
                    title="Sort By"
                    id="dropdown-sort"
                    style={{backgroundColor: "white"}}
                  >
                    <Dropdown.Item onClick={() => handleSortOptionChange("Employee Name (Asc)")}>
                      Employee Name (Asc)
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSortOptionChange("Employee Name (Desc)")}>
                      Employee Name (Desc)
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </Col>
            </Row> */}
            <Stack className="" direction="horizontal" gap={3} style={{ backgroundColor: "white ", paddingRight: '0px'}}>
                                        <Form.Control size="sm" className="me-auto" placeholder="Search Team..." 
                                        value={searchQuery} onChange={handleSearchQueryChange}
                                        />
                                        <DropdownButton size="sm"
                                            title="Sort By"
                                            id="dropdown-sort-2"
                                            // value={sort} 
                                            // onChange={handleSortChange} 
                                        >
                                            <Dropdown.Item onClick={() => handleSortOptionChange("Employee Name (Asc)")}>
                                              Employee Name (Asc)
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSortOptionChange("Employee Name (Desc)")}>
                                              Employee Name (Desc)
                                            </Dropdown.Item>
                                        </DropdownButton>

                                    </Stack>

          </Col>

          <Col className="d-md-none d-lg-none" style={{ backgroundColor: "white " }} >
            {/* <Stack className="" direction="horizontal" gap={3} style={{ backgroundColor: "white ", paddingRight: '0px'}}>
              <div className="search-sort-container p-0" >
                  <div className="search-input-container  p-0 " style={{ backgroundColor: "white" }} >
                  <Form.Control className="me-auto" placeholder="Search Team" /> 
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="search-icon"
                    />
                  </div>
              </div>
                <DropdownButton
                    title="Sort By"
                    size='m'
                    id="dropdown-sort-2"
                  >
                    <Dropdown.Item onClick={() => handleSortOptionChange("Employee Name (Asc)")}>
                      Employee Name (Asc)
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSortOptionChange("Employee Name (Desc)")}>
                      Employee Name (Desc)
                    </Dropdown.Item>
                </DropdownButton>
            </Stack> */}
            <Stack className="" direction="horizontal" gap={3} style={{ backgroundColor: "white ", paddingRight: '0px'}}>
                                        <Form.Control size="sm" className="me-auto" placeholder="Search Team..." 
                                        value={searchQuery} onChange={handleSearchQueryChange}
                                        />
                                        <DropdownButton size="sm"
                                            title="Sort By"
                                            id="dropdown-sort-2"
                                            // value={sort} 
                                            // onChange={handleSortChange} 
                                        >
                                            <Dropdown.Item onClick={() => handleSortOptionChange("Employee Name (Asc)")}>
                                              Employee Name (Asc)
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleSortOptionChange("Employee Name (Desc)")}>
                                              Employee Name (Desc)
                                            </Dropdown.Item>
                                        </DropdownButton>

                                    </Stack>
          </Col>

          <Col className="mt-4 p-0  " style={{ backgroundColor: "white " }}>
            <div className="text-center" style={{ backgroundColor: "white " }}>
              <div className="row row-cols-1 row-cols-lg-2 g-2 g-lg-3" style={{ backgroundColor: "white " }}>
                {data !== null ? (
                  filteredData.map((item, index) => (
                    <div className="col col-team" style={{ backgroundColor: "white" }} key={index}>
                      <a
                        onClick={() => handleButtonClick(item.empCode)}
                        style={{ textDecoration: "none" }}
                      >
                        <CardTeamSummary params={item} />
                      </a>
                    </div>
                  ))
                ) : 
                  <Col lg={12} md={12} className="" style={{ backgroundColor: "white" }} >
                      <Col lg={6} md={6} className=" skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                        <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                      </Col>
                  </Col>
                }
              </div>
            </div>
          </Col>
        </Card.Body>
      </Card>
    );

  } else {
    // if (data.data.master !== undefined) {
      return (
        <Card>
          <Card.Body className="card-overview h-100">
            <Button className="rounded-pill" size="sm" variant="outline-secondary" onClick={() => handleButtonClick(null)}> Back </Button>

            <Card className="mt-3 mb-4" style={{ backgroundColor: "#fffcdb" }}>

                  {data.data.master ? (
                    <Card.Body
                      style={{
                        backgroundColor: "#fffcdb",
                        borderColor: "#f2994a",
                        borderRadius: "15px",
                        border: "1px solid #f2994a",
                      }}
                    >
                      <Row style={{ backgroundColor: "#fffcdb" }}>
                        <Col lg={6} md={12} className="" style={{ backgroundColor: "#fffcdb" }}>
                          <Row className="">
                            <Col className=" d-flex justify-content-center align-items-center" xs={4} md={3} lg={3} style={{ backgroundColor: "#fffcdb" }}>
                              <div className="circular_image_container">
                                  <div
                                      className="circular_image "
                                      style={{
                                      backgroundImage: `url(data:image/jpeg;base64,${data.data.master[0].foto})`,
                                      }}
                                  ></div>
                              </div>
                            </Col>
                            <Col
                              xs={8}
                              md={9}
                              lg={9}
                              className="text-start border-0"
                              style={{
                                backgroundColor: "#fffcdb",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="p-0" style={{ backgroundColor: "#fffcdb" }}>
                                <p className="team-name">{data.data.master[0].empNama}</p>
                                <p className="team-nip">{data.data.master[0].empCode}</p>
                              </div>
                              <div style={{ marginTop: "auto", backgroundColor: "#fffcdb" }}>
                                <Row
                                  className="team-progress-label mb-2 mb-md-2 mb-lg-3"
                                  style={{ backgroundColor: "#fffcdb" }}
                                >
                                  <Col  lg={8} md={8} sm={8} xs={8}  style={{ backgroundColor: "#fffcdb" }}>
                                    <p
                                      id="p1"
                                      style={{
                                        backgroundColor: "#fffcdb",
                                        fontSize: "15px",
                                        color: "#0f4372",
                                        marginBottom: "0px",
                                        padding: "0 !important",
                                      }}
                                    >
                                      Course Progress
                                    </p>
                                  </Col>
                                  <Col
                                    className="text-end"
                                    style={{ backgroundColor: "#fffcdb" }}
                                  >
                                    <p
                                      id="p2"
                                      style={{
                                        backgroundColor: "#fffcdb",
                                        fontSize: "15px",
                                        color: "#0f4372",
                                        marginBottom: "0px",
                                        padding: "0 !important",
                                      }}
                                    >
                                      {data.data.master[0].percent_complete}%
                                    </p>
                                  </Col>
                                </Row>
                                <ProgressBar
                                  className={
                                    data.data.master[0].percent_complete === 100
                                      ? "progressbar-complete-color-1"
                                      : "progressbar-ongoing-color-1"
                                  }
                                  style={{ fontWeight: "100" }}
                                >
                                  <ProgressBar
                                    className={
                                      data.data.master[0].percent_complete === 100
                                        ? "progressbar-complete-color-2"
                                        : "progressbar-ongoing-color-2"
                                    }
                                    now={data.percent_complete}
                                    variant="custom"
                                  />
                                </ProgressBar>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        
                        <Col id="parent" lg={6} md={12} className=" d-flex flex-column"
                          style={{ backgroundColor: "#fffcdb" }}
                        >
                          <Row id="row1" className=" mt-2 mb-2" style={{ backgroundColor: "#fff9b8", borderRadius: '15px',  }}>
                            <Col className=" text-center d-flex justify-content-start"
                              style={{ backgroundColor: "transparent", color: '#0f4372' }}
                            >
                              Rank<br />{data.data.master[0].rank}
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center" style={{ fontSize: '7px', backgroundColor: "transparent", color: '#0f4372' }}>
                              <FontAwesomeIcon icon={faCircle} />
                            </Col>
                            <Col className=" text-center"
                              style={{ backgroundColor: "transparent" , color: '#0f4372' }}
                            >
                              Points<br />{data.data.master[0].points}
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center" style={{ fontSize: '7px', backgroundColor: "transparent" , color: '#0f4372'}}>
                              <FontAwesomeIcon icon={faCircle} />
                            </Col>
                            <Col className=" text-center d-flex justify-content-end"
                              style={{ backgroundColor: "transparent", color: '#0f4372' }}
                            >
                              Hour<br />{data.data.master[0].learning_hour}
                            </Col>
                          </Row>
      
                          <Row id="row2" className="mt-lg-auto" style={{ backgroundColor: "#fffcdb" }}>
                            <Col className="card-summary-team-completed" >
                              <Row className="align-items-center p-2">
                                <Col md={8} className="">
                                  <div className="d-flex flex-column text-start">
                                    Completed
                                    <br />
                                    <b>{data.data.master[0].completed}</b>
                                  </div>
                                </Col>
                                <Col md={4} className="d-none d-md-block d-lg-block">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                      icon={faCertificate}
                                      style={{ fontSize: "20px" }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            &nbsp;&nbsp;
                            <Col className="card-summary-team-ongoing" >
                              <Row className="align-items-center p-2">
                                <Col md={8} className="">
                                  <div className="d-flex flex-column text-start">
                                    On Progress
                                    <br />
                                    <b>{data.data.master[0].ongoing}</b>
                                  </div>
                                </Col>
                                <Col md={4} className="d-none d-md-block d-lg-block">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                      icon={faClock}
                                      style={{ fontSize: "20px" }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            &nbsp;&nbsp;
                            <Col className="card-summary-team-unstarted">
                              <Row className="align-items-center p-2">
                                <Col md={8} className="">
                                  <div className="d-flex flex-column text-start">
                                    Unstarted
                                    <br />
                                    <b>{data.data.master[0].not_attempt}</b>
                                  </div>
                                </Col>
                                <Col md={4} className="d-none d-md-block d-lg-block">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                      icon={faExclamationCircle}
                                      style={{ fontSize: "20px" }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  ) : (
                    <div className="" style={{ backgroundColor: "white" }} >
                      <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                        <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                      </div>
                    </div>
                  )}
              
            </Card>

            <h5 style={{ backgroundColor: 'transparent' }}>Course List</h5>

            <div className="d-none d-lg-block" style={{ backgroundColor: 'transparent' }}>
            {data.data.detail ? (
                  <Card className="mt-3 mb-4">
                    <Card.Body
                      style={{
                        backgroundColor: "#fffcdb",
                        borderColor: "#f2994a",
                        borderRadius: "15px",
                        border: "1px solid #f2994a",
                      }}
                    >
    
                      <Row className="p-0 align-items-center" style={{ color: '#0f4372', fontWeight: 'bold', backgroundColor: 'transparent', fontSize: "14px" }}>
                        <Col style={{ backgroundColor: 'transparent' }} className="d-flex align-items-center" lg={1}>No</Col>
                        <Col style={{ backgroundColor: 'transparent' }} className="d-flex justify-content-start align-items-center" lg={7}>Course</Col>
                        <Col style={{ backgroundColor: 'transparent' }} className="d-flex justify-content-end align-items-center" lg={2}>Completion Date</Col>
                        <Col style={{ backgroundColor: 'transparent' }} className="d-flex justify-content-end align-items-center" lg={1}>Status</Col>
                        <Col style={{ backgroundColor: 'transparent' }} className="d-flex justify-content-end align-items-center" lg={1}>Score</Col>
                      </Row>
    
                    </Card.Body>
                  </Card>
                  ) : (
                    <div className="mt-3 mb-4" style={{ backgroundColor: "white" }} >
                        <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                          <Skeleton style={{ height: "50px", borderRadius: '15px' }} />
                        </div>
                    </div>
                  )}
              

              {data.data.detail  ? (
                  <ListGroup style={{ backgroundColor: "#fffcdb", borderColor: "#f2994a", borderRadius: "15px", border: "1px solid #f2994a" }}>

                    {data.data.detail.map((item, index) => (
                      <ListGroup.Item key={index} style={{ backgroundColor: 'transparent', borderBottom: '1px solid #f2994a' }}>
                        <Row className="p-0 align-items-center" style={{ color: '#0f4372', fontWeight: 'normal', backgroundColor: 'transparent', fontSize: "14px" }}>
                          <Col style={{ backgroundColor: 'transparent' }} className="d-flex align-items-center" lg={1}>
                            {index + 1} {/* Row number */}
                          </Col>
                          <Col style={{ backgroundColor: 'transparent' }} className="d-flex justify-content-start align-items-center" lg={7}>
                            {item.courseName}
                          </Col>
                          <Col style={{ backgroundColor: 'transparent' }} className="d-flex justify-content-end align-items-center" lg={2}>
                            {item.completionDate}
                          </Col>
                          <Col style={{ backgroundColor: 'transparent' }} className="d-flex justify-content-end align-items-center" lg={1}>
                            {item.completionStatus}
                          </Col>
                          <Col style={{ backgroundColor: 'transparent' }} className="d-flex justify-content-end align-items-center" lg={1}>
                            <Button variant="warning" size="sm">{item.grade}</Button>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
    
                  </ListGroup>
                  ) : (
                    <div className="" style={{ backgroundColor: "white" }} >
                      <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                        <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                      </div>
                    </div>
                  )}

            </div>

            <div className="d-lg-none" style={{ backgroundColor: 'transparent' }}>
                {data.data.detail ? (
                    <ListGroup style={{ backgroundColor: "#fffcdb", borderColor: "#f2994a", borderRadius: "15px", border: "1px solid #f2994a" }}>

                      {data.data.detail.map((item, index) => (
                        <ListGroup.Item key={index} style={{ backgroundColor: 'transparent', borderBottom: '1px solid #f2994a' }}>
                          <Col className="" style={{ backgroundColor: 'transparent',  fontSize: '13px' }}>
                            <Row className="p-0 align-items-center" style={{ color: '#0f4372', fontWeight: 'normal', backgroundColor: 'transparent' }}>
                              <Col style={{ backgroundColor: 'transparent' }}><strong style={{ backgroundColor: 'transparent' }}>{item.courseName}</strong></Col>
                              <Col className="d-flex justify-content-end" style={{ backgroundColor: 'transparent', fontSize: '13px' }}>{item.completionDate}</Col>
                            </Row>
                            <Row className="p-0 align-items-center " style={{ color: '#0f4372', fontWeight: 'normal', backgroundColor: 'transparent' }}>
                              <Col style={{ backgroundColor: 'transparent' }}>{item.completionStatus}</Col>
                              <Col className="d-flex justify-content-end" style={{ backgroundColor: 'transparent' }}>
                                <strong style={{ backgroundColor: 'transparent' }}>{item.grade} Points</strong>
                              </Col>
                            </Row>
                          </Col>
                        </ListGroup.Item>
                      ))}
      
                    </ListGroup>
                  ) : (
                    <div className="" style={{ backgroundColor: "white" }} >
                      <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                        <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                      </div>
                    </div>  
                  )}
            </div>

          </Card.Body>
        </Card>
      );
    // } else {
    //   return (
    //     <Card>
    //       <Card.Body className="card-overview h-100">
    //         <Button className="rounded-pill" size="sm" variant="outline-secondary" onClick={() => handleButtonClick(null)}> Back </Button>
    //       </Card.Body>
    //     </Card>
    //   );
    // }
  }


}

export default CardTeamContainer;
