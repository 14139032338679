import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Image,
  Row,
  Stack,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getAuthData } from "../util/auth";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function EvaluateProgramParticipant() {
  const params = useParams();
  const [data, setData] = useState(null);
  const [sortedData, setSortedData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchProgress, setFetchProgress] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const nest_url = process.env.REACT_APP_NEST_URL;
  const url = nest_url + "public/me/evaluate_participant_list";
  const secretCode = process.env.REACT_APP_SECRET_CODE;

  const token = getAuthData();

  const headers = {
    Authorization: `Bearer ${token.jwt}`,
    secret: secretCode,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    setIsFetching(true);
    setFetchProgress(0);
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            courseId: params.courseId,
            SubCategoryId: params.subCategoryId,
            feedbackId: params.feedbackId,
          }),
        });

        if (!response.ok) {
          throw new Error("Could not fetch data");
        }

        const responseBody = await response.json();

        setData(responseBody.data);
        setFilteredData(responseBody.data.participant_list);

        console.log(responseBody.data.participant_list);
        // setFilteredData(responseBody.data);
        setFetchProgress(100);
        setIsFetching(false);
      } catch (error) {
        setError(error);
        // window.location.href = '/auth';
      }
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filteredParticipants = data.participant_list.filter(
      (item) =>
        item.empName.toLowerCase().includes(query.toLowerCase()) ||
        item.empCode.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredParticipants);
  };

  const [sortOption, setSortOption] = useState("default");

  const [sortBy, setSortBy] = useState("Sort By");

  const handleSortBySelect = (value) => {
    switch (value) {
      case "empNameAsc":
        // Sort the data in ascending order based on course name
        setFilteredData(
          [...data.participant_list].sort((a, b) =>
            a.empName.localeCompare(b.empName)
          )
        );
        setSortBy("Emp Name (Asc)");
        break;
      case "empNameDesc":
        // Sort the data in descending order based on course name
        setFilteredData(
          [...data.participant_list].sort((a, b) =>
            b.empName.localeCompare(a.empName)
          )
        );
        setSortBy("Emp Name (Desc)");
        break;
      default:
        // Reset the filtered data to the original participant list
        setFilteredData(data.participant_list);
        break;
    }
  };

  const badgeStyle = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "red !important",
  };

  return (
    <>
      <Container fluid className="p-0 ">
        <Row className="">
          {data && data.course_name && (
            <div className="d-flex justify-content-start flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mr-3">
              <Link to={`/evaluate`} style={{ textDecoration: "none" }}>
                <h6>Evaluate</h6>
              </Link>
              <h6> &nbsp; &gt; &nbsp; </h6>
              <h6>{data.course_name}</h6>
            </div>
          )}
        </Row>

        <Row className="mt-2 mt-lg-2">
          <Col lg={12}>
            <Card className="mb-4">
              {data !== null ? (
                <Card.Body className="card-today-schedule">
                  <Row className=" d-flex justify-content-between  flex-wrap">
                    <Col className="d-flex flex-column align-items-start justify-content-start">
                      {data && data.course_name && (
                        <h5 className="mb-0">{data.course_name}</h5>
                      )}

                      {data && data.course_name && (
                        <p className="mt-0">
                          {data.participant_total} participants
                        </p>
                      )}
                    </Col>
                    <Col className="d-flex justify-content-end  p-0">
                      <Stack direction="horizontal" gap={3}>
                        <div className="search-input-container  w-100">
                          <input
                            type="text"
                            placeholder="Search Participant"
                            className="search-input"
                            value={searchQuery}
                            onChange={handleSearch}
                          />
                          <FontAwesomeIcon
                            icon={faSearch}
                            className="search-icon"
                          />
                        </div>
                        <div className="dropdown-container">
                          <DropdownButton
                            title={sortBy}
                            id="dropdown-sort"
                            onSelect={handleSortBySelect}
                          >
                            <Dropdown.Item
                              eventKey="empNameAsc"
                              style={{ fontSize: "14px" }}
                            >
                              Emp Name (Asc)
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="empNameDesc"
                              style={{ fontSize: "14px" }}
                            >
                              Emp Name (Desc)
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </Stack>
                    </Col>
                  </Row>

                  <Row className="p-2">
                    <Col className=" ">
                      <Row className="p-2 header-participant-evaluate-list">
                        <Col lg={1}>No</Col>
                        <Col>Participant</Col>
                        <Col lg={2}>Action</Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="p-2">
                    {Array.isArray(filteredData) &&
                      filteredData.map((item, index) => (
                        <Col
                          lg={12}
                          className="evaluate-participant-list"
                          key={index}
                        >
                          <Row style={{ padding: "0.75rem 1rem 0.75rem 1rem" }}>
                            <Col
                              lg={1}
                              className=" p-0 d-flex align-items-centerd-flex align-items-center"
                            >
                              {index + 1}
                            </Col>
                            <Col className="">
                              <Row>
                                <Col lg={1} className=" p-0">
                                  <Image
                                    className="evaluate-participant"
                                    src={item.empPhoto}
                                    roundedCircle
                                  />
                                </Col>
                                <Col
                                  className="d-flex p-0"
                                  style={{
                                    height: "60px",
                                    position: "relative",
                                  }}
                                >
                                  <h6
                                    style={{
                                      backgroundColor: "transparent",
                                      position: "absolute",
                                      top: "5px",
                                    }}
                                  >
                                    <b>{item.empName}</b>
                                  </h6>
                                  <h6
                                    style={{
                                      backgroundColor: "transparent",
                                      position: "absolute",
                                      bottom: "0px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {item.empCode}
                                  </h6>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={2} className="d-flex align-items-center">
                              {item.is_finish === 1 ? (
                                <Link
                                  to={`/evaluate/questions/${item.userid}/${item.courseId}/${item.SubCategoryId}/${item.feedbackId}`}
                                  target="_self"
                                >
                                  <h6
                                    style={{ textAlign: "center" }}
                                    className="text-success"
                                  >
                                    Done{" "}
                                    <Badge bg="success" style={badgeStyle}>
                                      <FontAwesomeIcon
                                        icon={faEye}
                                        style={{ backgroundColor: "#198754" }}
                                      />
                                    </Badge>
                                  </h6>
                                </Link>
                              ) : item.is_finish === 0 ? (
                                <Link
                                  to={`/evaluate/questions/${item.userid}/${item.courseId}/${item.SubCategoryId}/${item.feedbackId}`}
                                  target="_self"
                                >
                                  <Button variant="outline-success">
                                    Evaluate
                                  </Button>
                                </Link>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                      ))}
                  </Row>
                </Card.Body>
              ) : (
                <div className="" style={{ backgroundColor: "transparent" }}>
                  <div
                    className="skeleton-wrapper"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EvaluateProgramParticipant;
