import React from "react";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";
import { Form, Link, useActionData, useNavigation} from "react-router-dom";

function AuthForm() {

  const data = useActionData();
  const navigation = useNavigation();

  const isSubmitting = navigation.state === 'submitting'; 

  let alert;
  if(data && !data.success){
    alert = ''
  }

  return (
    <Container fluid>
      <Row>
        <Col
          lg={8}
          className="d-none d-lg-block"
          style={{
            backgroundImage: "url(/login-image.png)",
            backgroundSize: "cover",
            height: "100vh",
          }}
        ></Col>
        <Col
          lg={4}
          md={12}
          className="d-flex align-items-center"
          style={{
            position: "relative",
            backgroundColor: "white",

            height: "100vh",
          }}
        >
          <img
            src="/img-1.png"
            className="top-left-image-login" style={{ backgroundColor: "transparent" }}
          />

          <Col className="d-flex justify-content-center" style={{ backgroundColor: "white" }}>
            <Card className="mt-4 ">
              <Card.Img
                className="logo-login"
                src="/blacklogo.png"
                style={{ backgroundColor: "white" }}
              />
              <Card.Body style={{ backgroundColor: "white" }}>
                <Card.Title className="mb-4" style={{ backgroundColor: "white" }}>
                  <small style={{ backgroundColor: "white" }}>
                    Nice to see you again, please welcome ;)
                  </small>
                </Card.Title >

                {data && !data.success ? (
                  <Alert variant="danger">
                    {data.message}
                  </Alert>
                ) : null}
                

                <Form
                  className="w-100"
                  method="post"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="mb-3" style={{ backgroundColor: "white" }}>
                    <label
                      className="form-label"
                      htmlFor="email"
                      style={{ backgroundColor: "white" }}
                    >
                      Login
                    </label>
                    <input
                      name="username"
                      placeholder="email or phone number"
                      type="text"
                      id="email"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3" style={{ backgroundColor: "white" }}>
                    <label
                      className="form-label"
                      htmlFor="password"
                      style={{ backgroundColor: "white" }}
                    >
                      Password
                    </label>
                    <input
                      name="password"
                      placeholder="Password"
                      type="password"
                      id="password"
                      className="form-control"
                    />
                  </div>

                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      {isSubmitting ? 'Signing in...' : 'Sign In'}
                    </button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          
          <img
            src="/img-2.png"
            alt="Bottom Right Image"
            className="bottom-right-image-login"
            style={{ backgroundColor: "transparent" }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AuthForm;
