import React, { useState } from "react";
import AuthForm from "../components/AuthForm";
import { Container, Row, Col, Button, Card, Alert} from "react-bootstrap";
import { Form, useNavigate } from 'react-router-dom';
import { json, redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../store/index';
import jwt_decode from 'jwt-decode';

function AuthenticationPage() {

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const nest_url = process.env.REACT_APP_NEST_URL;
  const url = nest_url+"public/me/login";
  const secretCode = process.env.REACT_APP_SECRET_CODE;
  
  const dispatch = useDispatch();

  const headers = {
    'secret': secretCode,
    'Content-Type': 'application/json'
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            'username': username,
            'password': password,
        }),
      });

      const responseBody = await response.json();

      if(!responseBody.success){
        setErrorMessage(responseBody.message);
      } else {
        localStorage.setItem('auth_data', JSON.stringify(responseBody.data));
        
        const decodedToken = jwt_decode(responseBody.data.jwt);
        
        dispatch(userActions.login({
          empCode: decodedToken.empCode,
          empName: decodedToken.empName,
          empEmail: responseBody.data.email
        }));

        dispatch(userActions.updatePhoto({
          empPhoto: `${responseBody.data.foto}?timestamp=${Date.now()}`
        }));

        navigate("/");
      }

    } catch (error) {
      throw json({ message: error},{
        status: 500,
      });
    }
    setIsSubmitting(false);
  };
  
  // return <AuthForm/>
  return (
    <>
      <Container fluid>
        <Row>
          <Col
            lg={8}
            className="d-none d-lg-block"
            style={{
              backgroundImage: "url(/login-image.png)",
              backgroundSize: "cover",
              height: "100vh",
            }}
          ></Col>
          <Col
            lg={4}
            md={12}
            className="d-flex align-items-center"
            style={{
              position: "relative",
              backgroundColor: "white",
              height: "100vh",
            }}
          >
            <img
              src="/img-1.png"
              className="top-left-image-login" style={{ backgroundColor: "transparent" }}
            />

            <Col className="d-flex justify-content-center" style={{ backgroundColor: "white" }}>
              <Card className="mt-4 ">
                <Card.Img
                  className="logo-login"
                  src="/blacklogo.png"
                  style={{ backgroundColor: "white" }}
                />
                <Card.Body style={{ backgroundColor: "white" }}>
                  <Card.Title className="mb-4" style={{ backgroundColor: "white" }}>
                    <small style={{ backgroundColor: "white" }}>
                      Nice to see you again, please welcome ;)
                    </small>
                  </Card.Title >

                  {errorMessage ? (
                    <Alert variant="danger">
                      {errorMessage}
                    </Alert>
                  ) : null}
                  

                  <Form
                    className="w-100"
                    method="post"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="mb-3" style={{ backgroundColor: "white" }}>
                      <label
                        className="form-label"
                        htmlFor="email"
                        style={{ backgroundColor: "white" }}
                      >
                        Login
                      </label>
                      <input
                        name="username"
                        placeholder="email or phone number"
                        type="text"
                        id="email"
                        className="form-control"
                        onChange={handleUsernameChange}
                      />
                    </div>
                    <div className="mb-3" style={{ backgroundColor: "white" }}>
                      <label
                        className="form-label"
                        htmlFor="password"
                        style={{ backgroundColor: "white" }}
                      >
                        Password
                      </label>
                      <input
                        name="password"
                        placeholder="Password"
                        type="password"
                        id="password"
                        className="form-control"
                        onChange={handlePasswordChange}
                      />
                    </div>

                    <div className="d-grid gap-2">
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting} onClick={handleLogin}>
                        {isSubmitting ? 'Signing in...' : 'Sign In'}
                      </button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            
            <img
              src="/img-2.png"
              alt="Bottom Right Image"
              className="bottom-right-image-login"
              style={{ backgroundColor: "transparent" }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AuthenticationPage;