import React from "react";
import { useParams } from "react-router-dom";

function convertToHours(timeString) {
  const [hours, minutes] = timeString.split(":");
  const totalHours = parseInt(hours) + parseInt(minutes) / 60;
  return totalHours;
}

export function TimeLabel(timeString) {
  if (!timeString || timeString.trim() === "") {
    return "0 hour";
  }

  const totalHours = convertToHours(timeString);

  let convertedTime;
  if (totalHours === 1) {
    convertedTime = `${totalHours} hour`;
  } else {
    convertedTime = `${totalHours} hours`;
  }

  return convertedTime;
}

export function ucwords(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

// export default TimeConversionExample;
