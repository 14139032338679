import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row, Stack } from "react-bootstrap";
import EllipsisButton from "../components/EllipsisButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import EllipsisButtonCourseNav from "../components/EllipsisButtonCourseNav";
import { getAuthData } from "../util/auth";
import { Link, json, redirect, useLoaderData } from "react-router-dom";
import CardCourse from "../components/CardCourse";
import CardScheduleList from "../components/CardScheduleList";
import WebinarCard from "../components/WebinarCard";
import ContainerFluid from "../components/ContainerFluid";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function EvaluatePage() {

    const [data, setData] = useState(null);
    const [sortedData, setSortedData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchProgress, setFetchProgress] = useState(0);
    const [filteredData, setFilteredData] = useState(null);
    const [sortBy, setSortBy] = useState('Sort By');

    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/feedback/get_course_to_evaluate";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        setIsFetching(true);
        setFetchProgress(0);
        const fetchData = async () => {
          try {
    
            const response = await fetch(
              url,
              {
                method: "POST",
                headers: headers,
              }
            );
    
            if (!response.ok) {
              throw new Error('Could not fetch data');
            }
    
            const responseBody = await response.json();

            if(responseBody.data.course){
                setData(responseBody.data.course);
            }
            setFilteredData(responseBody.data.course);
            setFetchProgress(100);
            setIsFetching(false);
          } catch (error) {
            setError(error);
            window.location.href = '/auth';
          }
        };
        fetchData();
      }, []);

      const [searchQuery, setSearchQuery] = useState('');
    
      const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
      
        if (!query) {
          setFilteredData(data); 
        } else {
          setFilteredData(
            data.filter((item) =>
              item.courseName.toLowerCase().includes(query.toLowerCase())
            )
          );
        }
      };
    

    const [sortOption, setSortOption] = useState('default');
    const handleSort = (option) => {
        setSortOption(option);
    };

    const handleSortBySelect = (eventKey) => {
        setSortBy(eventKey);

        let sortedData = [...filteredData]; 

        if (eventKey === "courseNameAsc") {
            sortedData.sort((a, b) =>
                a.courseName.localeCompare(b.courseName)
            );
            setSortBy('Course Name (Asc)');
        } else if (eventKey === "courseNameDesc") {
            sortedData.sort((a, b) =>
                b.courseName.localeCompare(a.courseName)
            );
            setSortBy('Course Name (Desc)');
        } else if (eventKey === "Sort By") {
            sortedData.sort((a, b) =>
                a.courseName.localeCompare(b.courseName)
            );
            setSortBy('Course Name (Asc)');
        }

        setFilteredData(sortedData);
    };

    const badgeStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: 'red !important'

    };

    const iconStyle = {
        color: 'red',
    };


    return (
        <>
            <Container fluid className="p-0 " >
            <Row className="">
                            <div className="d-flex justify-content-start pt-3 pb-1 mr-3 ">
                                <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <h6>Dashboard</h6> 
                                </Link>
                            </div>

                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-0 ">
                              <h1 className="h4">Evaluate</h1>
                            </div>
                        </Row>

                <Row className="mt-2 mt-lg-2">
                    <Card className="mb-4">

                        {filteredData !== null ? (
                            <Card.Body className="card-today-schedule">

                            <Container fluid>

                                <Row className="mb-4">
                                    <Col className="">
                                        <h1 className="h5">Course to Evaluate</h1>
                                    </Col>

                                    <Col className="d-none d-md-block d-lg-block">
                                        <Stack direction="horizontal" gap={3}>
                                            <div className="search-input-container " >
                                                <input
                                                    type="text"
                                                    placeholder="Search Course"
                                                    className="search-input"
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                    className="search-icon"
                                                    onClick={() => handleSearch()}
                                                />
                                            </div>
                                            <div className="dropdown-container" >
                                                <DropdownButton
                                                    title={sortBy}
                                                    onSelect={handleSortBySelect}
                                                    id="dropdown-sort"
                                                >
                                                    <Dropdown.Item eventKey="courseNameAsc" style={{fontSize: '14px'}}>Course Name (Asc)</Dropdown.Item>
                                                    <Dropdown.Item eventKey="courseNameDesc" style={{fontSize: '14px'}}>Course Name (Desc)</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>

                                {filteredData &&
                                filteredData
                                    .filter((item) => {
                                        if (!searchQuery) {
                                            return true; 
                                        } else {
                                            return item.courseName.toLowerCase().includes(searchQuery.toLowerCase());
                                        }
                                    })
                                    .map((item, index) => (
                                        <Row className="mt-3" key={index}>
                                            <Col className="evaluate-program-list px-3 py-1">
                                                <Row className="">
                                                    <Col className=" d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-0"
                                                        style={{ backgroundColor: '#f5ffe1' }}
                                                    >
                                                        <h6 ><strong style={{ backgroundColor: '#f5ffe1', color: '#219653' }}>{item.courseName}</strong></h6>
                                                    </Col>

                                                    <Col className=" d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-0 text-success"
                                                        style={{ backgroundColor: '#f5ffe1' }}>
                                                        <h6 style={{ backgroundColor: '#f5ffe1' }} className="text-success">
                                                            Evaluated <b style={{ backgroundColor: '#f5ffe1', color: '#219653' }}>{item.evaluated}</b>
                                                        </h6>

                                                        <h2 style={{ backgroundColor: '#f5ffe1' }} className="text-success">&#x2f;</h2>
                                                        <h6 style={{ backgroundColor: '#f5ffe1' }} className="text-success">{item.participant}</h6>
                                                    </Col>

                                                    <Col
                                                    lg={2}
                                                    className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-0"
                                                    style={{ backgroundColor: '#f5ffe1' }}
                                                    >
                                                    {item.evaluation_status === 1 ? (
                                                        <h6 style={{ textAlign: 'center', backgroundColor: '#f5ffe1' }} className="text-success">
                                                        Done{' '}
                                                        <Badge bg="success" style={badgeStyle}>
                                                            <FontAwesomeIcon icon={faEye} style={{ backgroundColor: '#198754' }} />
                                                        </Badge>
                                                        </h6>
                                                    ) : item.evaluation_status === 0 ? (
                                                        <Link to={`/evaluate/${item.courseId}/${item.SubCategoryId}/${item.feedbackId}`}
                                                        target="_self" style={{ backgroundColor: '#f5ffe1' }}>
                                                        <Button variant="outline-success">
                                                            Evaluate
                                                        </Button>
                                                        </Link>
                                                    ) : null}
                                                    </Col>
                                                </Row>
                                            </Col>
                                    </Row>
                                ))}

                            </Container>

                        </Card.Body>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                        
                    </Card>


                </Row>

            </Container>
        </>

    );
}

export default EvaluatePage;

