import { faAngleDown, faAngleUp, faLayerGroup, faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Tabs, Tab, Nav, Row, Col, Card, Accordion, Button, Table, AccordionButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { ucwords } from '../util/general';

function CourseContentSummary({ params, parameter }) {
  // const isMobile = window.innerWidth <= 767; // Check if viewing on a mobile device
  const star = params.original.ratings;
  const badge = 'gold';
  const course = params;
  const courseContents = params.original.contents;
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  
  const isMobile = window.innerWidth <= 575.98; 
  const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
  const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
  const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
  const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
  const isExtraExtraLarge = window.innerWidth >= 1400;
  const summary = DOMPurify.sanitize(params.original.summary);

  let totalModules = 0;
  for (let i = 0; i < courseContents.length; i++) {
    totalModules += courseContents[i].modules.length;
  }

  if (isMobile) {
    return (
      <>
        <div id="mobile-tabs" >
          <Tabs defaultActiveKey="info" >
            <Tab eventKey="info" title="Info" style={{ backgroundColor: 'white' }} >
              <div className="tab-content mt-3" style={{ backgroundColor: '#f0f7fd' }}>
                <div className="tab-pane-content p-3 " style={{ backgroundColor: '#f0f7fd' }}>
                  <div className="row" style={{ backgroundColor: '#f0f7fd' }}>
                    <div className="col-sm p-0" style={{ backgroundColor: '#f0f7fd' }}>

                      <div className="card p-0 mb-1 " style={{ backgroundColor: '#f0f7fd' }}>
                        <div className="row g-0 p-1 align-items-center" style={{ backgroundColor: '#f0f7fd' }}>
                          <div className="col-md-8 col-8 p-0 d-flex align-items-center">
                            <div className="card-body p-2" style={{ backgroundColor: '#f0f7fd' }}>
                              <div className="d-flex flex-column" style={{ backgroundColor: '#f0f7fd' }}>
                                <h6 className="card-title m-0" style={{ fontSize: '15px', fontWeight: 'bold', backgroundColor: '#f0f7fd' }}>Created By</h6>
                                <h6 className="card-title mt-2" style={{ fontSize: '15px', backgroundColor: '#f0f7fd' }}>{params.original.created_by}</h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-4 p-0 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#f0f7fd' }}>
                            <img
                              style={{ backgroundColor: '#f0f7fd', width: '70%', height: 'auto', maxWidth: '100px' }}
                              src="/profil-pic.jpg"
                              alt="Profile"
                              className="circle-image-dropdown-img increased-image-size-mobile"
                            />
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="col-sm p-0 border-top" style={{ backgroundColor: '#f0f7fd' }}>
                      <div className="card p-1 mt-0 " style={{ backgroundColor: '#f0f7fd' }}>
                        <div className="row g-0 p-0 align-items-center" >
                          <div className="col-md-12 p-0 d-flex align-items-center">
                            <div className="card-body p-2" style={{ backgroundColor: '#f0f7fd' }}>
                              <div className="d-flex flex-column " style={{ backgroundColor: '#f0f7fd' }}>
                                <h6 className="card-title m-0" style={{ backgroundColor: '#f0f7fd', fontSize: '15px', fontWeight: 'bold' }}>Program</h6>
                                <h6 className="card-title mt-2" style={{ backgroundColor: '#f0f7fd', fontSize: '15px' }}>{params.original.subCategoryName}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm p-0 border-top" style={{ backgroundColor: '#f0f7fd' }}>
                      <div className="card p-1 mt-0 " style={{ backgroundColor: '#f0f7fd' }}>
                        <div className="row g-0 p-0 " >
                          <div className="col-md-12 p-0  ">
                            <div className="card-body p-2" style={{ backgroundColor: '#f0f7fd' }}>
                              <div className="" style={{ backgroundColor: '#f0f7fd' }}>
                                <h6 className="card-title m-0" style={{ backgroundColor: '#f0f7fd', fontSize: '15px', fontWeight: 'bold' }}>Review</h6>
                                <div className=' w-100' style={{ backgroundColor: '#f0f7fd' }}>
                                  <span className='mt-2 ' style={{ display: 'inline-block', backgroundColor: '#f0f7fd' }}>
                                    {Array.from({ length: Math.floor(star) }, (_, index) => (
                                      <FontAwesomeIcon key={`star-${index}`} icon={faStar} className={`bronze-star ${badge}-star`} style={{ color: 'gold', fontSize: '20px' }} />
                                    ))}
                                    {star % 1 !== 0 && (
                                      <FontAwesomeIcon icon={faStar} className={`bronze-star ${badge}-star`} style={{ color: 'gold', fontSize: '20px' }} />
                                    )}
                                    <small style={{ color: '#0f4372', fontSize: '13px' }}> 5.0 ( 1k reviews )</small>
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="about" title="About" style={{ borderRadius: '15px', border: 'none' }}>
              <Card className='mt-3' >
                <Card.Body className="card-about" >
                  <Card.Title
                    className="d-flex justify-content-between align-items-center"
                    style={{ fontSize: "17px", fontWeight: 'bold' }}
                  >
                    About Course

                  </Card.Title>

                  <div id="about-container" dangerouslySetInnerHTML={{ __html: summary }}></div>

                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="content" title="Content" style={{ borderRadius: '15px', border: 'none' }}>
              <Card className='mt-3'>
                <Card.Body className="card-course-content-list h-100">
                  <Card.Title
                    className="d-flex justify-content-between align-items-center"
                    style={{ fontSize: "17px", fontWeight: 'bold' }}
                  >
                    Course
                    <Link to={`/course/detail/start/${parameter.courseId}/${parameter.subCategoryId}/${parameter.userId}`} className="view-all-activity">
                      Start Now
                    </Link>
                  </Card.Title>

                  <p className="mb-1">{courseContents.length} Parts . {totalModules} Lessons </p>

                  <Accordion className="mt-2" defaultActiveKey="0" >

                  {courseContents && courseContents.map((item, index) => (
                    <Accordion.Item eventKey={`item-${item.id}`} key={`item-${item.id}`}>
                      <Accordion.Header onClick={() => setIsAccordionOpen(prevState => ({
                        ...prevState,
                        [index]: !prevState[index]
                      }))}>
                        <Col style={{ backgroundColor: 'transparent' }}>
                          <AccordionButton
                            bsPrefix
                            style={{ border: 'none', color: '#0f4372', marginRight: '10px', backgroundColor: 'transparent' }}
                            onClick={() => setIsAccordionOpen(prevState => ({
                              ...prevState,
                              [index]: !prevState[index]
                            }))}
                          >
                            <FontAwesomeIcon style={{ backgroundColor: 'transparent' }} icon={isAccordionOpen[index] ? faAngleUp : faAngleDown} />
                          </AccordionButton>
                          <b style={{ backgroundColor: 'transparent' }}>{item.name}</b>
                        </Col>
                        <Col lg={2} className="text-end" style={{ backgroundColor: 'transparent' }}>
                          {item.modules.length} Lessons
                        </Col>
                      </Accordion.Header>

                      <Accordion.Body style={{ backgroundColor: '#f0f7fd' }}>
                        {item.modules.length > 0 && item.modules.map((moduleItem, moduleIndex) => (

                          <Row key={moduleIndex} className="mb-2" style={{ backgroundColor: 'red' }}>
                            <Col className="" style={{ fontSize: '14px', backgroundColor: '#f0f7fd' }}>
                              <FontAwesomeIcon icon={faLayerGroup} className="nav-icon-sidebar" />
                              {moduleItem.name}
                            </Col>
                            <Col lg={3} className="text-end" style={{ fontSize: '14px', backgroundColor: '#f0f7fd' }}>
                              {ucwords(moduleItem.modname)}
                            </Col>
                          </Row>

                        ))}
                      </Accordion.Body>

                    </Accordion.Item>
                  ))}
                  </Accordion>

                </Card.Body>
              </Card>
            </Tab>
          </Tabs>
        </div>
      </>

    );
  }

  return (
    <>
      <div id="info" className="p-3 mt-3">
        <div className="row p-0" >

          <div className="col-3 col-sm-4 col-md-3 col-lg-5 p-0 " style={{ backgroundColor: '#f0f7fd' }}>
            <div className="card p-2" >
              <div className="row g-0 p-0 align-items-center" >
                <div className=" col-sm-7 p-0 d-flex align-items-center">
                  <div className="card-body p-2" style={{ backgroundColor: '#f0f7fd' }}>
                    <div className="d-flex flex-column " style={{ backgroundColor: '#f0f7fd' }}>
                      <h6 className="card-title m-0" style={{ backgroundColor: '#f0f7fd', fontSize: '15px', fontWeight: 'bold' }}>Created By</h6>
                      <h6 className="card-title mt-2" style={{ backgroundColor: '#f0f7fd', fontSize: '15px' }}>{params.original.created_by}</h6>
                    </div>
                  </div>
                </div>
                <div className=" col p-0 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#f0f7fd' }}>
                  <img style={{ backgroundColor: '#f0f7fd' }}
                    src="/profil-pic.jpg"
                    alt="Profile"
                    className="circle-image-dropdown-img increased-image-size"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col p-0 border-left">
            <div className="card p-1 mt-0 " >
              <div className="row g-0 p-0 align-items-center" >
                <div className="col-md-12 p-0 d-flex align-items-center">
                  <div className="card-body p-2" style={{ backgroundColor: '#f0f7fd' }}>
                    <div className="d-flex flex-column " style={{ backgroundColor: '#f0f7fd' }}>
                      <h6 className="card-title m-0" style={{ backgroundColor: '#f0f7fd', fontSize: '15px', fontWeight: 'bold' }}>Program</h6>
                      <h6 className="card-title mt-2" style={{ backgroundColor: '#f0f7fd', fontSize: '15px' }}>{params.original.subCategoryName}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-sm-4 col-md-3 p-0 border-left">
            <div className="card p-1 mt-0 " >
              <div className="row g-0 p-0 " >
                <div className="col-md-12 p-0  ">
                  <div className="card-body p-2" style={{ backgroundColor: '#f0f7fd' }}>
                    <div className="" style={{ backgroundColor: '#f0f7fd' }}>
                      <h6 className="card-title m-0" style={{ backgroundColor: '#f0f7fd', fontSize: '15px', fontWeight: 'bold' }}>Review</h6>
                      <div className=' w-100' style={{ backgroundColor: '#f0f7fd' }}>
                        <span className='mt-2 ' style={{ display: 'inline-block', backgroundColor: '#f0f7fd' }}>
                          {Array.from({ length: Math.floor(star) }, (_, index) => (
                            <FontAwesomeIcon key={`star-${index}`} icon={faStar} className={`bronze-star ${badge}-star`} style={{ color: 'gold', fontSize: '20px' }} />
                          ))}
                          {star % 1 !== 0 && (
                            <FontAwesomeIcon icon={faStar} className={`bronze-star ${badge}-star`} style={{ color: 'gold', fontSize: '20px' }} />
                          )}
                          <small style={{ color: '#0f4372', fontSize: '13px' }}> 5.0 ( 1k reviews )</small>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <Card className='mt-3' >
        <Card.Body className="card-about">
          <Card.Title
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "17px", fontWeight: 'bold' }}
          >
            About Course

          </Card.Title>
          
          <div id="about-container" dangerouslySetInnerHTML={{ __html: summary }} ></div>
          
        </Card.Body>
      </Card>


      <Card className='mt-3'>
        <Card.Body className="card-course-content-list h-100">
          <Card.Title
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "17px", fontWeight: 'bold' }}
          >
            Course
            <Link to={`/course/detail/start/${parameter.courseId}/${parameter.subCategoryId}/${parameter.userId}`} className="view-all-activity">
              Start Now
            </Link>
          </Card.Title>

          <p className="mb-1">{courseContents.length} Parts . {totalModules} Lessons </p>

          <Accordion className="mt-2">

            {courseContents && courseContents.map((item, index) => (
              <Accordion.Item eventKey={`item-${item.id}`} key={`item-${item.id}`}>
                <Accordion.Header onClick={() => setIsAccordionOpen(prevState => ({
                  ...prevState,
                  [index]: !prevState[index]
                }))}>
                  <Col style={{ backgroundColor: 'transparent' }}>
                    <div
                      style={{ border: 'none', color: '#0f4372', marginRight: '10px', backgroundColor: 'transparent' }}
                      onClick={() => setIsAccordionOpen(prevState => ({
                        ...prevState,
                        [index]: !prevState[index]
                      }))}
                    >
                      <FontAwesomeIcon style={{ backgroundColor: 'transparent' }} icon={isAccordionOpen[index] ? faAngleUp : faAngleDown} />
                      <b style={{ backgroundColor: 'transparent', marginLeft: '10px' }}>{item.name}</b>
                    </div>
                    
                  </Col>
                  <Col lg={2} className="text-end" style={{ backgroundColor: 'transparent' }}>
                    {item.modules.length} Lessons
                  </Col>
                </Accordion.Header>

                <Accordion.Body style={{ backgroundColor: '#f0f7fd' }}>
                  {item.modules.length > 0 && item.modules.map((moduleItem, moduleIndex) => (

                    <Row key={moduleIndex} className="mb-2" style={{ backgroundColor: 'red' }}>
                      <Col className="" style={{ fontSize: '14px', backgroundColor: '#f0f7fd' }}>
                        <FontAwesomeIcon icon={faLayerGroup} className="nav-icon-sidebar" />
                        {moduleItem.name}
                      </Col>
                      <Col lg={3} className="text-end" style={{ fontSize: '14px', backgroundColor: '#f0f7fd' }}>
                        {ucwords(moduleItem.modname)}
                      </Col>
                    </Row>

                  ))}
                </Accordion.Body>

              </Accordion.Item>
            ))}
          </Accordion>

        </Card.Body>
      </Card>
    </>
  );
}


export default CourseContentSummary