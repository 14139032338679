import React from "react";
import { Button, Card } from "react-bootstrap";

function CardApply() {
  const isMobile = window.innerWidth <= 575.98; // Adjust the breakpoint as needed
  return (
    <Card className="card-apply-now">
      <Card.Body>
        <div className="text-wrapper" >
          <p
            className="text-light text-start mb-0"
            style={{ backgroundColor: "transparent" }}
          >
            Dapatkan keterampilan sebenarnya!
          </p>
          <p
            className="text-light text-start mb-0"
            style={{ backgroundColor: "transparent" }}
          >
            Kursus yang diajar oleh ahli di dunia nyata.
          </p>
          <p
            className="text-light text-start mb-0"
            style={{ backgroundColor: "transparent" }}
          >
            Pendaftaran sampai 14 Juni 2022
          </p>
        </div>

        <div className="image-container p-0 d-flex justify-content-end">
          <img
            src="/learning_rb.png"
            alt="Image"
            className="custom-image"
            style={{ backgroundColor: "#f2aa6a" }}
          />
        </div>
        
      </Card.Body>
      <Card.Footer>
        {isMobile ? (
          <Button variant="primary" className="button-apply-now mb-3 btn-sm">
            Daftar Sekarang
          </Button>
        ) : (
          <Button variant="primary" className="button-apply-now mb-3">
            Daftar Sekarang
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
}

export default CardApply;
