import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faChartSimple,
  faClock,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row } from "react-bootstrap";

function CardLearningHours({ params }) {
  const learning_hour = params.learning_hour;
  const learning_hour_total = params.learning_hour_total;
  let percentage = Math.ceil((learning_hour / learning_hour_total) * 100);
  percentage = isNaN(percentage) ? 0 : percentage;
  
  const isMobile = window.innerWidth <= 575.98; 
  const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
  const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
  const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
  const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
  const isExtraExtraLarge = window.innerWidth >= 1400;

  let circle_width = "80%";
  let circle_height = "80%";

  if(isSmall){
      circle_width = "55%";
      circle_height = "55%";
  } else if (isMedium){
      circle_width = "55%";
      circle_height = "55%";
  }

  return (
    <Card>
      <Card.Body className="card-learning-hours">
        <Card.Title
          className="d-flex justify-content-between align-items-center"
          style={{ fontSize: "17px", fontWeight: 'bold' }}
        >
          Learning Hours
          <FontAwesomeIcon
            icon={faClock}
            className="card-learning-hours-icon"
          />
        </Card.Title>


        <Row className="mt-4" style={{ backgroundColor: "transparent" }}>
          <Col
            md="5" sm="5" xs="4"
            className="mb-2 p-0  d-flex align-items-center"
            style={{ backgroundColor: "transparent" }}
          >

            <div style={{ display: 'flex', justifyContent: 'center', padding: '0', backgroundColor: "#fee7da" }}>
              <svg width={circle_width} height={circle_height} viewBox="0 0 160 160" style={{ padding: '0', backgroundColor: "#fee7da" }}>
                <defs>
                  <linearGradient id="linearLearningHours" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#fe8542" />
                    <stop offset="100%" stopColor="#fecdb2" />
                  </linearGradient>
                </defs>
                <circle cx="80" cy="80" r="70" stroke="white" strokeWidth="10" fill="transparent" />
                <path
                  d="M 80,10 A 70 70 0 0 1 80 150 A 70 70 0 0 1 80 10"
                  stroke="url(#linearLearningHours)"
                  strokeLinecap="round"
                  strokeWidth="10"
                  fill="transparent"
                  strokeDasharray={`${(percentage * 440) / 100}, 440`}
                />
                <text x="50%" y="50%" textAnchor="middle" dominantBaseline="central" fontSize="24" fill="#0f4372" style={{ zIndex: 1 }}>
                  {percentage}%
                </text>
              </svg>
            </div>

          </Col>
          <Col
            md="7" sm="7" xs="8"
            className="mb-2  "
            style={{ backgroundColor: "transparent" }}
          >

                  {
                    isMobile ? (
                      <>
                        <Row className="p-1 d-flex align-items-center" style={{ backgroundColor: "transparent" }}>
                          <Col className="mb-2" xs={12} style={{ fontSize: "15px",backgroundColor: "#fecdb2", borderRadius: "10px" }}>
                            <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}>{learning_hour} </b>
                            <b style={{ backgroundColor: "transparent" }}>hours</b>
                          </Col>
      
                          <Col xs={12} style={{ fontSize: "15px",backgroundColor: "#fecdb2", borderRadius: "10px" }}>
                            from total
                            <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}>{learning_hour_total} </b>
                            <b style={{ backgroundColor: "transparent" }}>hours</b>
                          </Col>
                        </Row>
                      </>
                    ) : isSmall ? (
                        <>
                          <Row className="p-1 d-flex align-items-center" style={{ backgroundColor: "transparent" }}>
                            <Col className="mb-2" xs={12} style={{ fontSize: "25px", padding: "20px 20px 0 20px", backgroundColor: "#fecdb2", borderRadius: "15px" }}>
                              <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "35px" }}>{learning_hour} </b>
                              <b style={{ backgroundColor: "transparent" }}>hours</b>
                            </Col>
        
                            <Col xs={12} style={{ fontSize: "25px",backgroundColor: "#fecdb2", borderRadius: "15px", padding: '15px' }}>
                              from total
                              <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}>{learning_hour_total} </b>
                              <b style={{ backgroundColor: "transparent" }}>hours</b>
                            </Col>
                          </Row>
                        </>
                    ) : isMedium ? (
                        <>
                          <Row className="p-1 d-flex align-items-center" style={{ backgroundColor: "transparent" }}>
                            <Col className="mb-2" xs={12} style={{ fontSize: "25px", padding: "20px 20px 0 20px", backgroundColor: "#fecdb2", borderRadius: "15px" }}>
                              <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "35px" }}>{learning_hour} </b>
                              <b style={{ backgroundColor: "transparent" }}>hours</b>
                            </Col>
        
                            <Col xs={12} style={{ fontSize: "25px",backgroundColor: "#fecdb2", borderRadius: "15px", padding: '15px' }}>
                              from total
                              <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}>{learning_hour_total} </b>
                              <b style={{ backgroundColor: "transparent" }}>hours</b>
                            </Col>
                          </Row>
                        </>
                    )  : isLarge ? (
                      <>
                        <Card className="card-learning-hours">
                          <Card.Body style={{ fontSize: "15px", padding: "10px 10px 0 10px", backgroundColor: "#fecdb2", borderRadius: "15px" }}>
                            <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "34px" }}>{learning_hour} </b>
                            <b style={{ backgroundColor: "transparent" }}>hours</b>
                          </Card.Body>
                        </Card>

                        <Card className="card-learning-hours mt-3">
                          <Card.Body style={{ fontSize: "12px", backgroundColor: "#fecdb2", borderRadius: "15px" }}>
                            from total <b style={{ backgroundColor: "transparent" }}>{learning_hour_total} hours</b>
                          </Card.Body>
                        </Card>
                      </>
                    )  : isExtraLarge ? (
                      <>
                        <Card className="card-learning-hours">
                          <Card.Body style={{ fontSize: "15px", padding: "10px 10px 0 10px", backgroundColor: "#fecdb2", borderRadius: "15px" }}>
                            <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "34px" }}>{learning_hour} </b>
                            <b style={{ backgroundColor: "transparent" }}>hours</b>
                          </Card.Body>
                        </Card>

                        <Card className="card-learning-hours mt-3">
                          <Card.Body style={{ fontSize: "12px", backgroundColor: "#fecdb2", borderRadius: "15px" }}>
                            from total <b style={{ backgroundColor: "transparent" }}>{learning_hour_total} hours</b>
                          </Card.Body>
                        </Card>
                      </>
                    )  : isExtraExtraLarge ? (
                      <>
                        <Card className="card-learning-hours">
                          <Card.Body style={{ fontSize: "15px", padding: "10px 10px 0 10px", backgroundColor: "#fecdb2", borderRadius: "15px" }}>
                            <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "34px" }}>{learning_hour} </b>
                            <b style={{ backgroundColor: "transparent" }}>hours</b>
                          </Card.Body>
                        </Card>

                        <Card className="card-learning-hours mt-3">
                          <Card.Body style={{ fontSize: "12px", backgroundColor: "#fecdb2", borderRadius: "15px" }}>
                            from total <b style={{ backgroundColor: "transparent" }}>{learning_hour_total} hours</b>
                          </Card.Body>
                        </Card>
                      </>
                    ) : null
                  }
            
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CardLearningHours;
