import React from 'react'
import Notifications from './Notifications'
import NotificationItems from './NotificationItems'
import { Dropdown } from 'react-bootstrap'
import { useEffect } from 'react';
import { getAuthData } from '../util/auth';
import { useState } from 'react';
import moment from 'moment';


function HeaderNotifications() {

    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/inbox/totalUnread";
    const secretCode = process.env.REACT_APP_SECRET_CODE;
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    const fetchData = async () => {
        try {

            const response = await fetch(
            url,
            {
                method: "POST",
                headers: headers,
            }
            );

            if (!response.ok) {
            throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();
            setTotal(responseBody.data.total);
            setData(responseBody.data.list);

        } catch (error) {
            // setError(error);
            // window.location.href = '/auth';
        }
    };
        
    useEffect(() => {
        fetchData();
    }, []);

  return (
    <>
        <Notifications count={total} >

            {data && data.length > 0 && data.map((item, index) => (
                    
                    <NotificationItems  
                        key = {index}
                            className=""
                            title= {item.InboxMsgTitle}
                            subtitle={moment(item.msgDate).format('D MMM YYYY')}
                            badge={
                                item.InboxMsgType === 'daily_quiz' ? 'Daily Quiz' :
                                item.InboxMsgType === 'inclass' ? 'Classroom' :
                                item.InboxMsgType === 'news' ? 'News' :
                                'Others'
                            }
                            color={
                                item.InboxMsgType === 'daily_quiz' ? 'danger' :
                                item.InboxMsgType === 'inclass' ? 'warning' :
                                item.InboxMsgType === 'news' ? 'primary' :
                                'light'
                            }
                    />
            ))}

                                    
        </Notifications>
    </>
  )
}

export default HeaderNotifications