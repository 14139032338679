import React from 'react'
import { Badge } from 'react-bootstrap'
import { useEffect } from 'react';
import { getAuthData } from '../util/auth';
import { useState } from 'react';

const BadgeInboxCount = ({isActive}) => {
    console.log(isActive);
    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/inbox/totalUnread";
    const secretCode = process.env.REACT_APP_SECRET_CODE;
    const [total, setTotal] = useState(0);

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    const fetchData = async () => {
        try {

            const response = await fetch(
            url,
            {
                method: "POST",
                headers: headers,
            }
            );

            if (!response.ok) {
            throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();
            setTotal(responseBody.data.total);

        } catch (error) {
            // setError(error);
            // window.location.href = '/auth';
        }
    };
        
    useEffect(() => {
        fetchData();
    }, []);

    if(total >0){
        return (
            <>
                <Badge as="span" bsPrefix id={`${isActive ? 'badge-inbox-active' : 'badge-inbox'}`}>{total}</Badge>
            </>
          )
    } else {
        return null;
    }

  
}

export default BadgeInboxCount