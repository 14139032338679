import React, { useState } from 'react'
import { Badge, Button, Col, ProgressBar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCalendar, faCalendarAlt, faClock, faLayerGroup, faMapPin } from '@fortawesome/free-solid-svg-icons';

function EventClassList({ params }) {
    const inclassStartTimestamp = params.inclassStart;

    const inclassStartDate = new Date(inclassStartTimestamp * 1000);

    const dateFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    };

    const timeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
        hour12: true,
      };
      
    const formattedTime = inclassStartDate.toLocaleTimeString('id-ID', timeFormatOptions);

    const formattedDate = inclassStartDate.toLocaleDateString('id-ID', dateFormatOptions);

    // console.log(formattedDate); // Output: Kamis, 18 Januari 2023
    // console.log(formattedTime); // Output: 01.30.00


    return (
        <Col className="card-event-list p-1 row mb-2">
            <Col md={4} lg={4} className=" d-flex flex-column justify-content-center align-items-center p-3">
                <div className="image-event-container">
                    <img src="/course-image.jpg" alt="Course Image" className="rounded" />
                </div>
            </Col>
            <Col md={8} lg={8} className="p-3">
                <Row>
                    <Col>
                        <h6 style={{ color: "#0f4372" }}>
                            <FontAwesomeIcon icon={faCalendar} /> &nbsp; {formattedDate} &nbsp; &#x2022; &nbsp;
                            <FontAwesomeIcon icon={faClock} /> &nbsp; {formattedTime}
                        </h6>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <Link to={`/myclass/detail/${params.courseId}/${params.SubCategoryId}/${params.userid}`}>
                            <Button className="btn-event-class" size="sm">
                                <FontAwesomeIcon icon={faBook} /> Class
                            </Button>
                        </Link>
                        
                    </Col>
                </Row>

                <Row className='mt-2'>
                    <Col>
                        <h5>
                            <b>{params.courseName}</b>
                        </h5>
                    </Col>
                </Row>

                <Row className='mt-1'>
                    <Col className="d-flex gap-10" style={{ backgroundColor: 'white' }}>
                        <p style={{ color: "#0f4372" }}>
                            <FontAwesomeIcon icon={faMapPin} /> &nbsp; {params.inclassVenue}
                        </p>
                    </Col>
                </Row>
            </Col>
        </Col>
    )
}

export default EventClassList;