import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EllipsisButtonCourseNav from "../components/EllipsisButtonCourseNav";
import { getAuthData } from "../util/auth";
import { Link, json, redirect, useLoaderData, useParams, useNavigate } from "react-router-dom";
import CardCourse from "../components/CardCourse";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ProgramDetailPage() {
    const params = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchProgress, setFetchProgress] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeButton, setActiveButton] = useState(1);
    const [dataCourse, setDataCourse] = useState(null);
    const navigate = useNavigate();

    const isMobile = window.innerWidth <= 575.98; 
    const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
    const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
    const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
    const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
    const isExtraExtraLarge = window.innerWidth >= 1400;

    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/dashboard/get_course_by_subcategory";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        setIsFetching(true);
        setFetchProgress(0);
        const fetchData = async () => {
            try {

                const response = await fetch(
                    url,
                    {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify({
                            'subCategoryId': params.subCategoryId,
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error('Could not fetch data');
                }

                const responseBody = await response.json();

                setData(responseBody.data);
                setDataCourse(responseBody.data.detail);
                setFetchProgress(100);
                setIsFetching(false);
            } catch (error) {
                setError(error);
                window.location.href = '/auth';
            }
        };
        fetchData();
    }, []);

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const [sortOption, setSortOption] = useState('default');
    const handleSort = (option) => {
        setSortOption(option);
    };

    const handleButtonClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
      };

    
    let font_size_summary  = '15px';

    if(isMobile){
        font_size_summary = "14px";
    } else if (isSmall){
        font_size_summary = "10px";
    } else if (isMedium){
        font_size_summary = "12px";
    } else if (isLarge){
        font_size_summary = "15px";
    } else if (isExtraLarge){
        font_size_summary = "15px";
    } else if (isExtraExtraLarge){
        font_size_summary = "14px";
    } 

    // if (data) {
        return (
            <>
                <Container fluid className="p-0 " >
                    <Row >
                        <Col>
                            
                            {data !== null ? (
                                <div className="d-flex justify-content-start pt-3 pb-2 mr-3">
                                    <Link to={`/`} style={{ textDecoration: 'none' }}>
                                        <h6>Dashboard</h6> 
                                    </Link>
                                    <h6>&nbsp; &gt; &nbsp;</h6>
                                    <Link style={{ textDecoration: 'none' }} onClick={() => navigate(-1)}>
                                        <h6>Program</h6> 
                                    </Link>
                                    <h6>&nbsp; &gt; &nbsp;</h6>
                                    <h6>{data.summary.name}</h6> 
                                </div>
                            ) : (
                                <div className="" style={{ backgroundColor: "transparent" }} >
                                    <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                    <Skeleton style={{ height: "50px", borderRadius: '15px' }} />
                                    </div>
                                </div>
                            )}
                            
                        </Col>
                    </Row>
                    <Row className="p-2">
                        
                        {data !== null ? (
                            <Col id="card-program-detail-summary" className="p-2">
                                <Row className="p-3">
                                    <Col className="d-flex justify-content-center align-items-center">
                                        <div className="text-center" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <h4 style={{ fontWeight: 'bold' }}>{data.summary.name}</h4>
                                            <h5>{data.summary.description}</h5>
                                        </div>
                                    </Col>
                                    <Col sm={7} md={7} lg={7} id="card-program-detail-summary-info" style={{ height: '100%'}}>
                                        <Row>
                                            <Col sm={4} md={4} lg={3} className="d-flex justify-content-center p-0">
                                                <div className=" d-flex justify-content-center text-center" style={{ padding: '10px', backgroundColor: "transparent" }}>
                                                    <svg width="90%" height="90%" viewBox="0 0 160 160" style={{ padding: '0', backgroundColor: "transparent" }}>
                                                        <defs>
                                                        <linearGradient id="linearLearningHours" x1="0%" y1="0%" x2="100%" y2="0%">
                                                            <stop offset="0%" stopColor="#175084" />
                                                            <stop offset="100%" stopColor="#4197e5" />
                                                        </linearGradient>
                                                        </defs>
                                                        <circle cx="80" cy="80" r="70" stroke="white" strokeWidth="10" fill="transparent" />
                                                        <path
                                                        d="M 80,10 A 70 70 0 0 1 80 150 A 70 70 0 0 1 80 10"
                                                        stroke="url(#linearLearningHours)"
                                                        strokeLinecap="round"
                                                        strokeWidth="10"
                                                        fill="transparent"
                                                        strokeDasharray={`${(data.summary.percent_completion * 440) / 100}, 440`}
                                                        />
                                                        <text  x="50%" y="50%" textAnchor="middle" dominantBaseline="central" fontSize="30" fill="#0f4372" style={{ zIndex: 1, fontWeight: '600' }}>
                                                        {data.summary.percent_completion}%
                                                        </text>
                                                    </svg>
                                                </div>
                                            </Col>
                                            <Col className=" d-flex align-items-center p-0">
                                                <Container className="">
                                                    <Row className="row-cols-3 row-cols-md-3 row-cols-lg-4 g-0 " style={{fontSize: font_size_summary }}>
                                                        <Col>
                                                            <div className='p-3'>
                                                                Total Course
                                                                <h5><b>{data.summary.total_course}</b></h5>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className='p-3'>
                                                                Completed
                                                                <h5><b>{data.summary.completed}</b></h5>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className='p-3'>
                                                                On Progress
                                                                <h5><b>{data.summary.ongoing}</b></h5>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className='p-3'>
                                                                Unstarted
                                                                <h5><b>{data.summary.unstarted}</b></h5>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className='p-3'>
                                                                Rank
                                                                <h5><b>{data.summary.rank}</b> of {data.summary.total_rank}</h5>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className='p-3'>
                                                                Avg. Score
                                                                <h5><b>{data.summary.avg_score}</b></h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                                {/* <Container style={{display: 'flex', flexDirection: 'column' }} className="p-0 gy-2">
                                                    <Row className="" style={{ height: '25%', fontSize: font_size_summary }}>
                                                        <Col>
                                                            Total Course
                                                            <h5><b>{data.summary.total_course}</b></h5>
                                                        </Col>
                                                        <Col>
                                                            Completed
                                                            <h5><b>{data.summary.completed}</b></h5>
                                                        </Col>
                                                        <Col>
                                                            On Progress
                                                            <h5><b>{data.summary.ongoing}</b></h5>
                                                        </Col>
                                                        <Col>
                                                            Unstarted
                                                            <h5><b>{data.summary.unstarted}</b></h5>
                                                        </Col>
                                                    </Row>
                                                    <Row className=""  style={{ height: '25%', fontSize: font_size_summary}}> 
                                                        <Col lg={3}>
                                                            Rank
                                                            <h5><b>{data.summary.rank}</b> of {data.summary.total_rank}</h5>
                                                        </Col>
                                                        <Col lg={4}>
                                                            Average Score
                                                            <h5><b>{data.summary.avg_score}</b></h5>
                                                        </Col>
                                                    </Row>
                                                </Container> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                        
                    </Row>
                    
                    <Row className="mt-3">
                        {data !== null ? (
                            <Col >
                                <EllipsisButtonCourseNav
                                    buttonNumber={1}
                                    isActive={activeButton === 1}
                                    onClick={() => handleButtonClick(1)}
                                    title={`All (${data.summary.total_course})`}
                                />

                                <EllipsisButtonCourseNav
                                buttonNumber={2}
                                isActive={activeButton === 2}
                                onClick={() => handleButtonClick(2)}
                                title={`Completed (${data.summary.completed})`}
                                />

                                <EllipsisButtonCourseNav
                                buttonNumber={3}
                                isActive={activeButton === 3}
                                onClick={() => handleButtonClick(3)}
                                title={`On Progress (${data.summary.ongoing})`}
                                />

                                <EllipsisButtonCourseNav
                                buttonNumber={4}
                                isActive={activeButton === 4}
                                onClick={() => handleButtonClick(4)}
                                title={`Unstarted (${data.summary.unstarted})`}
                                />
                            </Col>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "50px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                        
                    </Row>

                    <Row className="mt-3 mt-lg-3 gy-3">


                    {dataCourse ? (
                        dataCourse
                            .filter((course) => {
                            if (activeButton === 1) {
                                // "All" button is selected, so no filtering needed
                                return true;
                            } else if (activeButton === 2) {
                                // "Completed" button is selected, filter by completionStatus === "completed"
                                return course.completionStatus === "completed";
                            } else if (activeButton === 3) {
                                // "On Progress" button is selected, filter by completionStatus === "ongoing"
                                return course.completionStatus === "ongoing";
                            } else if (activeButton === 4) {
                                // "Unstarted" button is selected, filter by completionStatus !== "completed" && completionStatus !== "ongoing"
                                return (
                                course.completionStatus !== "completed" &&
                                course.completionStatus !== "ongoing"
                                );
                            }
                            return false;
                            })
                            .map((course) => (
                            <Col sm={4} md={6} key={course.courseId + course.subCategoryId + course.userid} lg={4}>
                                <CardCourse course={course} />
                            </Col>
                            ))
                        ) : (
                        <div className="" style={{ backgroundColor: "transparent" }}>
                            <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}>
                            <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                            </div>
                        </div>
                        )}
                    </Row>

                </Container>
            </>

        );
    // }

}

export default ProgramDetailPage;

