import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function TruncatedSpanText({ icon, text, maxLength }) {
  if (text.length <= maxLength) {
    return (
      <span title={text}>
        {icon && <FontAwesomeIcon icon={icon} />} {text}
      </span>
    );
  }

  const truncatedText = text.substring(0, maxLength) + "...";
  return (
    <span title={text}>
      {icon && <FontAwesomeIcon icon={icon} />} {truncatedText}
    </span>
  );
}

export default TruncatedSpanText;