import { redirect  } from "react-router-dom";

export function getAuthData() {
    const data = localStorage.getItem('auth_data');
    if (!data) {
      return redirect('/auth');
    }
    return JSON.parse(data);
}

export function authLoader(){
    return getAuthData();
}

export function checkAuthLoader() {
    const token = getAuthData();
    
    if (!token) {
      return redirect('/auth');
    }
   
    return null; 
  }

  export function ucwords(str) {
    return str.replace(/^(.)|\s+(.)/g, function($1) {
      return $1.toUpperCase();
    });
  }

  export function ucfirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  export function lcfirst(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  export function strtoupper(str) {
    return str.toUpperCase();
  }

  function strtolower(str) {
    return str.toLowerCase();
  }