import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'typeface-raleway';
import './css/main.css';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import store, { persistor } from '../src/store';
import { PersistGate } from 'redux-persist/integration/react';

window.Buffer = window.Buffer || require("buffer").Buffer;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Provider store={store}>
  //   <React.StrictMode>
  //     <App />
  //   </React.StrictMode>
  // </Provider>

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
