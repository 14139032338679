import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import EllipsisButton from "../components/EllipsisButton";
import CardApply from "../components/CardApply";
import CardEvent from "../components/CardEvent";
import CardCourseProgress from "../components/CardCourseProgress";
import CardLearningOverview from "../components/CardLearningOverview";
import CardCourseContainerDashboard from "../components/CardCourseContainterDashboard";
import { json, redirect, useLoaderData } from "react-router-dom";
import { getAuthData } from "../util/auth";
import CardProgramContainerDashboard from "../components/CardProgramContainerDashboard";
import CardCertDashboardContainer from "../components/CardCertDashboardContainer";
import CardTeamContainer from "../components/CardTeamContainer";
import CardTrainerContainer from "../components/CardTrainerContainer";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect } from "react";

function IndexDashboard() {
  
  const [activeButton, setActiveButton] = useState(1);
  const [error, setError] = useState(null);
  const [dashboard, setDashboard] = useState(null);

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/dashboard/dashboard_v2/";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    const fetchData = async () => {
        try {
  
          const response = await fetch(
            url,
            {
              method: "POST",
              headers: headers,
            }
          );
  
          if (!response.ok) {
            throw new Error('Could not fetch data');
          }
  
          const responseBody = await response.json();
          console.log(responseBody);

          if(responseBody){
            setDashboard(responseBody.data);
          }
        } catch (error) {
          setError(error);
          console.log(error);
          // window.location.href = '/auth';
        }
      };

    useEffect(() => {
        // setTimeout(() => {
            fetchData();
        // }, 10000);
    }, []);

  return (
    <>
      <Container fluid className="p-0" >
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1">
          <h1 className="h4">Dashboard</h1>
        </div>
        <Row>
          <Col >
            <EllipsisButton style={{ width: '50px !important' }}
              buttonNumber={1}
              isActive={activeButton === 1}
              onClick={handleButtonClick}
              title={"Personal"}
            />

            <EllipsisButton
              buttonNumber={2}
              isActive={activeButton === 2}
              onClick={handleButtonClick}
              title={"Team"}
            />

            <EllipsisButton
              buttonNumber={3}
              isActive={activeButton === 3}
              onClick={handleButtonClick}
              title={"Trainer"}
            />
          </Col>
        </Row>

        {activeButton === 1 && (
          <div id="personalContainer" >
            <Row className="">
              <Col lg={6} className="mt-4 ">
                {dashboard !== null ? (
                    <CardApply />
                  ) : (
                    <div className="skeleton-wrapper">
                      <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                    </div>
                  )}
              </Col>

              <Col lg={3} className="mt-4 ">
                {dashboard !== null ? (
                  <CardEvent params={dashboard.agenda} />
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                  </div>
                )}
              </Col>

              <Col lg={3} className="mt-4">
                {dashboard !== null ? (
                  <CardCourseProgress dataCourses={dashboard.course} />
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                  </div>
                )}
              </Col>
            </Row>

            <Row className="mt-4 mt-lg-4">
              {dashboard !== null ? (
                  <CardLearningOverview dataLevelStatus={dashboard.level_status} dataLearningHours={dashboard.learning_hour} dataCourseLearned={dashboard.course_learned} />
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                  </div>
                )}
            </Row>

            <Row className="mt-4 mt-lg-4">

                {dashboard !== null ? (
                  <CardCourseContainerDashboard params={dashboard.course} />
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                  </div>
                )}

            </Row>

            <Row className="mt-4 mt-lg-4">

              <Col lg={8} className="mb-4">
                {dashboard !== null ? (
                  <CardProgramContainerDashboard params={dashboard.subcategory} />
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                  </div>
                )}
              </Col>

              <Col lg={4} className="mb-4 d-flex align-items-stretch">

                {dashboard !== null ? (
                  <CardCertDashboardContainer params={dashboard.certification} />
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                  </div>
                )}  
              </Col>
            </Row>
          </div>

        )}


        {activeButton === 2 && (
          <div id="teamContainer">
            <Row className="mt-4 mt-lg-4">
              <CardTeamContainer />
            </Row>
          </div>
        )}

        {activeButton === 3 && (
          <div id="trainerContainer">
            <Row className="mt-4 mt-lg-4">
              <CardTrainerContainer/>
            </Row>
          </div>
        )}

      </Container>
    </>
  );
}

export default IndexDashboard;

// export async function loader({ request, params }) {
//   const nest_url = process.env.REACT_APP_NEST_URL;
//   const url = nest_url + "public/dashboard/dashboard_v2/";
//   const secretCode = process.env.REACT_APP_SECRET_CODE;

//   const token = getAuthData();

//   if (!token) {
//     return redirect('/auth');
//   } else {
//     const headers = {
//       Authorization: `Bearer ${token.jwt}`,
//       secret: secretCode,
//     };

//     const response = await fetch(
//       url,
//       {
//         mode: "cors",
//         method: "POST",
//         headers: headers,
//       }
//     );
//     if (!response.ok) {
//       throw json({ message: 'Could not fetch data' }, {
//         status: response.status,
//       });
//     } else {
//       return response;
//     }
//   }
// }
