import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Accordion, Badge, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AccordionCustom = ({params}) => {
  
  const [activeKey, setActiveKey] = useState('');

  const handleAccordionToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? '' : eventKey);
  };

  const headerStyle = {
    backgroundColor: activeKey === '0' ? '#fef4ef' : '#fee3d5',
    borderBottom: '1px solid #f4aaaa',
    cursor: 'pointer'
  };

  const sectionKeys = Object.keys(params); // Get all section keys dynamically

  return (
    
    <div style={{ borderRadius: '15px', overflow: 'hidden', border: '2px solid #f4aaaa' }}>
        <Accordion defaultActiveKey="0" id="accordion-trainer">

          <Accordion bsPrefix>
            {sectionKeys.map((sectionKey, index) => (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header><b style={{ backgroundColor: 'transparent', color: '#0f4372' }}>{sectionKey}</b> <small style={{ backgroundColor: 'transparent' }}></small></Accordion.Header>
                <Accordion.Body>
                  <Row className='g-4 d-flex justify-content-center' style={{ backgroundColor: 'transparent' }}>
                    {params[sectionKey].map((item, itemIndex) => (
                      <Col sm={6} md={6} lg={6} xl={4} xxl={4} key={itemIndex} style={{ backgroundColor: 'transparent' }}>
                        <Card className="card-course-parent" style={{ backgroundColor: 'white' }}>
                          <Link 
                          to={`/myclasstrainer/detail/${item.courseId}/${item.subCategoryId}/${item.userid}`}
                          style={{ textDecoration: 'none', backgroundColor: 'transparent' }} >
                            <div className="position-relative" style={{ borderRadius: '15px' }}>
                              <Card.Img className='card-course-image' src="/course-image.jpg" />
                            </div>
                            <Card.Body className='card-course' style={{ backgroundColor: 'white' }}>
                              <div className="d-flex gap-2" style={{ backgroundColor: 'white' }}>
                                <h5>
                                  <Badge style={{ fontWeight: "normal", fontSize: '13px' }}>
                                    {sectionKey}
                                  </Badge>
                                </h5>
                              </div>
                              <Card.Title style={{ backgroundColor: 'white' }}>{item.course}</Card.Title>
                              <Row className=''>
                                <Col className='' lg={6} md={6} sm={6} xs={6} style={{ backgroundColor: 'white' }}>
                                  <small style={{ backgroundColor: 'white' }}>
                                    <FontAwesomeIcon style={{ backgroundColor: 'transparent' }} icon={faCalendar} /> {item.start.split(' ')[0].split('-').reverse().join('-')}
                                  </small>
                                </Col>
                                <Col className=' p-0' lg={6} md={6} sm={6} xs={6} style={{ backgroundColor: 'white' }}>
                                  <small style={{ backgroundColor: 'white' }}>
                                    <FontAwesomeIcon style={{ backgroundColor: 'transparent' }} icon={faClock} /> {item.start.split(' ')[1].slice(0, 5)} - {item.end.split(' ')[1].slice(0, 5)}
                                  </small>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Link>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          
        </Accordion>
    </div>
  );
}

export default AccordionCustom