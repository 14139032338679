import { faCertificate, faClock, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Image, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function CardTeamSummary({ params }) {
    const data = params;

    const isMobile = window.innerWidth <= 575.98; 
    const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
    const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
    const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
    const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
    const isExtraExtraLarge = window.innerWidth >= 1400;

    let color = '#f2994a';
    let completionStatus = 'ongoing';

    if(data.percent_complete == 0){
        color = '#eb5757';
        completionStatus = 'unstarted';
    } else if(data.percent_complete > 0 && data.percent_complete < 100){
        color = '#f2994a';
        completionStatus = 'ongoing';
    } else if(data.percent_complete == 100) {
        color = '#27ae60';
        completionStatus = 'completed';
    }

    const base64ToBlob = (base64Data) => {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        return new Blob(byteArrays, { type: 'image/jpeg' });
    };

    const imageUrl = URL.createObjectURL(base64ToBlob(data.foto));

    return (
        <Card className="clickable">
            <Card.Body
                style={{
                    backgroundColor: "#fffcdb",
                    borderColor: "#f2994a",
                    borderRadius: "15px",
                    border: "1px solid #f2994a",
                }}
            >
                <Row className="">
                    <Col className=" d-flex justify-content-center align-items-center" xs={4} md={3} lg={4} style={{ backgroundColor: "#fffcdb" }}>

                        <div className="circular_image_container">
                            <div
                                className="circular_image "
                                style={{
                                backgroundImage: `url(data:image/jpeg;base64,${data.foto})`,
                                }}
                            ></div>
                        </div>

                    </Col>
                    <Col
                        // xs={8}
                        // sm={8}
                        // md={9}
                        // lg={9}
                        className="text-start border-0"
                        style={{
                            backgroundColor: "#fffcdb",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div className="p-0" style={{ backgroundColor: "#fffcdb" }}>
                            <p className="team-name">{data.empNama}</p>
                            <p className="team-nip">{data.empCode}</p>
                        </div>
                        <div style={{ marginTop: "auto", backgroundColor: "white" }}>
                            <Row
                                className="team-progress-label"
                                style={{ backgroundColor: "#fffcdb" }}
                            >
                                <Col lg={8} md={8} sm={8} xs={8} className="" style={{ backgroundColor: "#fffcdb" }}>
                                    <p
                                        id="p1"
                                        style={{
                                            backgroundColor: "#fffcdb",
                                            fontSize: "15px",
                                            color: "#0f4372",
                                            marginBottom: "0px",
                                            padding: "0 !important",
                                        }}
                                    >
                                        Course Progress
                                    </p>
                                </Col>
                                <Col 
                                    
                                    className="text-end "
                                    style={{ backgroundColor: "#fffcdb" }}
                                >
                                    <p
                                        id="p2"
                                        style={{
                                            backgroundColor: "#fffcdb",
                                            fontSize: "15px",
                                            color: "#0f4372",
                                            marginBottom: "0px",
                                            padding: "0 !important",
                                        }}
                                    >
                                        {data.percent_complete}%
                                    </p>
                                </Col>
                            </Row>
                            <ProgressBar
                                className={
                                    completionStatus === "completed"
                                        ? "progressbar-complete-color-1"
                                        : "progressbar-ongoing-color-1"
                                }
                                style={{ fontWeight: "100" }}
                            >
                                <ProgressBar
                                    className={
                                        completionStatus === "completed"
                                            ? "progressbar-complete-color-2"
                                            : "progressbar-ongoing-color-2"
                                    }
                                    now={data.percent_complete}
                                    variant="custom"
                                />
                            </ProgressBar>
                        </div>
                    </Col>
                </Row>
                
                <Row
                    className=" mt-3 team-summary-row"
                    style={{ backgroundColor: "transparent" }}
                >
                    <div
                        className="d-flex justify-content-center"
                        style={{ backgroundColor: "transparent" }}
                    >
                        <Col
                            className="card-summary-team-completed "
                            style={{ display: "inline-block", marginRight: "10px" }}
                        >
                            <Row className="align-items-center p-2">
                                <Col className="">
                                    <div className="d-flex flex-column text-start">
                                        Completed
                                        <br />
                                        <b>{data.completed}</b>
                                    </div>
                                </Col>
                                <Col xs={3} sm={3} md={3} className="d-none d-md-block d-lg-block ">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <FontAwesomeIcon
                                            icon={faCertificate}
                                            style={{ fontSize: "20px" }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            className="card-summary-team-ongoing"
                            style={{ display: "inline-block", marginRight: "10px" }}
                        >
                            <Row className="align-items-center p-2">
                                <Col className="">
                                    <div className="d-flex flex-column text-start">
                                        On Progress
                                        <br />
                                        <b>{data.ongoing}</b>
                                    </div>
                                </Col>
                                <Col xs={3} sm={3} md={3}  className="d-none d-md-block d-lg-block">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <FontAwesomeIcon
                                            icon={faClock}
                                            style={{ fontSize: "20px" }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            xs={4}
                            className="card-summary-team-unstarted"
                            style={{ display: "inline-block", marginRight: "10px" }}
                        >
                            <Row className="align-items-center p-2">
                                <Col className="">
                                    <div className="d-flex flex-column text-start">
                                        Unstarted
                                        <br />
                                        <b>{data.not_attempt}</b>
                                    </div>
                                </Col>
                                <Col xs={3} sm={3} md={3}  className="d-none d-md-block d-lg-block">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <FontAwesomeIcon
                                            icon={faExclamationCircle}
                                            style={{ fontSize: "20px" }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default CardTeamSummary;
