import React from 'react';
import PageContent from '../components/PageContent';
import { Link, redirect, useRouteError } from 'react-router-dom';

const ErrorPages = () => {

  const error = useRouteError();

  let title = 'An error occurred';
  let message = 'Something went wrong. Please try again later.';

  if(error.status === 404) {
    title = 'Page not found';
    message = 'Sorry, the page you requested does not exist.';
  }

  if(error.status === 500) {
    title = 'Internal Server Error';
    message = 'Sorry, an internal server error occurred.';
  }

  if(error.status === 401) {
    // title = 'Unauthorized';
    // message = 'Please sign in to continue';
    window.location.href = '/auth';
  }

  return (
    <PageContent>
      <div className="error-page" style={{backgroundColor: "white"}}>
        <img src="/img-1.png" className="top-left-image" alt="Top Left" style={{ backgroundColor: "transparent" }} />
        <div className="error-content" style={{backgroundColor: "white"}}> 
          <h1 className="error-title" style={{backgroundColor: "white"}}>{title}</h1>
          <p className="error-subtitle" style={{backgroundColor: "white"}}>{message}</p>
          <Link style={{backgroundColor: "white"}} to="/auth" className="login-Link btn btn-sm btn-primary" >
            Sign In
          </Link>
        </div>
        <img src="/img-2.png" className="bottom-right-image" alt="Bottom Right" style={{ backgroundColor: "transparent" }} />
        <div className="muted-text" style={{backgroundColor: "white"}}>© 2023 Colibri LMS. All rights reserved.</div>
      </div>
    </PageContent>
  )
}

export default ErrorPages