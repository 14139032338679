import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import NavSideBar from "./NavSideBar";
import { Col } from "react-bootstrap";

function ContainerFluid() {
  return (
    <Container fluid >
      <Row>
        <Col>
          <NavSideBar/>
        </Col>
      </Row>
    </Container>
  );
}

export default ContainerFluid;
