
import { Card, Col, Row } from "react-bootstrap";
import CardLevelStatus from "./CardLevelStatus";
import CardLearningHours from "./CardLearningHours";
import CardCourseLearned from "./CardCourseLearned";
import CardCourse from "./CardCourse";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

function CardCourseContainerDashboard({params}) {
  const courses = params;

  const settings = {
    dots: true,
    autoplay: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992, // Use the modified "lg" breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Use the modified "md" breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 576, // Use the modified "md" breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (    
    <Card>
        <Card.Body className="card-overview h-100">
          <Card.Title
              className="d-flex justify-content-between align-items-center"
              style={{ fontSize: "17px", fontWeight: 'bold'  }}
          >
              Course
              <Link to='/course' className="view-all-activity">
              View All
              </Link>
          </Card.Title>
          <Col className="mt-3 p-0 " style={{ backgroundColor: "white " }}>
            <div className="m-0 slider_container mb-3" style={{ backgroundColor: "white" }}>
              <Slider {...settings}>
                  {courses && courses.map(item => (
                      <div key={`item-${item.courseId}`} style={{ backgroundColor: "white" }} className="d-flex justify-content-center p-3">
                        <CardCourse key={`item-${item.courseId}`} course={item}/>
                      </div>
                    ))}

              </Slider>
            </div>
          </Col>

        </Card.Body>
    </Card>
  );
}

export default CardCourseContainerDashboard;
