
import { Card, Col, Row } from "react-bootstrap";
import CardLevelStatus from "./CardLevelStatus";
import CardLearningHours from "./CardLearningHours";
import CardCourseLearned from "./CardCourseLearned";
import CardCourse from "./CardCourse";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

function CardCertDashboardContainer({ params }) {

    const lastCert = params && params[0] && params[0].name ? params[0].name : "";
    const totalCert = params ? params.length || 0 : 0;

    return (
        <Card className="w-100">
            <Card.Body className="card-cert" >
                <Card.Title
                    className="d-flex justify-content-between align-items-center"
                    style={{ fontSize: "17px", fontWeight: 'bold' }}
                >
                    My Certification
                    <Link to='/certificate' className="view-all-activity">
                        View All
                    </Link>
                </Card.Title>
                <Col className="mt-4 p-0 " style={{ backgroundColor: "white " }}>
                    <Card>
                        <Card.Body className="card-cert-dashboard ">
                            <Col md="12" className="mb-4" style={{ backgroundColor: "transparent" }}>
                                <Card className="card-class-points">
                                    <Card.Body className="position-relative" style={{ paddingTop: "14%", paddingBottom: "14%", paddingLeft: "5px", paddingRight: "5px" }}>
                                        <Card.Title className="vertical-align">
                                            <p style={{ backgroundColor: "transparent", fontWeight: "300", margin: "5px 0" }}>Last Certificate</p>
                                            <h5 style={{ backgroundColor: "transparent", fontWeight: "bold", margin: "5px 0" }}>{lastCert}</h5>
                                        </Card.Title>
                                        <img
                                            src="/assets_2.png" // Replace with the actual path to your image
                                            alt="Certificate"
                                            style={{ position: "absolute", top: "-30px", right: "-20px", width: "30%", height: "auto", background: 'transparent' }}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md="12" className="mb-2" style={{ backgroundColor: "transparent" }}>
                                <Card className="card-class-points">
                                    <Card.Body className="p-0 overflow-hidden">
                                        <Row className="p-2" style={{ backgroundColor: "transparent" }}>
                                            <Col md={8} sm={8} xs={8} style={{ backgroundColor: "transparent" }} className="">
                                                <p style={{ backgroundColor: "transparent", fontWeight: "300", margin: "5px 0", fontSize: '15px' }}>Total Certificate</p>
                                            </Col>
                                            <Col md={4} sm={4} xs={4} style={{ backgroundColor: "transparent" }} className="">
                                                <p style={{ backgroundColor: "transparent", fontWeight: "bold", margin: "5px 0", fontSize: '15px' }}>{totalCert}</p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Card.Body>
                    </Card>
                </Col>
            </Card.Body>
        </Card>
    );
}

export default CardCertDashboardContainer;
