import React from 'react';
import { Dropdown } from 'react-bootstrap';

const CircleImageDropdown = ({ imageSrc, title, subtitle, children }) => {
  return (
    <Dropdown className='bg-light'>
      <Dropdown.Toggle id="circle-image-dropdown" className='bg-light border-0'>
        <div className="circle-image-dropdown-toggle bg-light">
          <img src={`${imageSrc}?timestamp=${Date.now()}`} alt="Profile" className="circle-image-dropdown-img" />
          <div className='text-start bg-light d-none d-sm-block d-md-block' >
            <h5 className="circle-image-dropdown-title bg-light">{title}</h5>
            <span className="circle-image-dropdown-subtitle bg-light">{subtitle}</span>
          </div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-menu-end profile-drop-list'>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default CircleImageDropdown;