import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import EllipsisButton from "../components/EllipsisButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import EllipsisButtonCourseNav from "../components/EllipsisButtonCourseNav";
import { getAuthData } from "../util/auth";
import { Link, json, redirect, useLoaderData } from "react-router-dom";
import CardCertificate from "../components/CardCertificate";

function CertificatePage() {

    const [data, setData] = useState(null);
    const [sortedData, setSortedData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchProgress, setFetchProgress] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/me/my_certificate";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        setIsFetching(true);
        setFetchProgress(0);
        const fetchData = async () => {
            try {

                const response = await fetch(
                    url,
                    {
                        method: "POST",
                        headers: headers,
                    }
                );

                if (!response.ok) {
                    throw new Error('Could not fetch data');
                }

                const responseBody = await response.json();
                setData(responseBody.data);
                setFetchProgress(100);
                setIsFetching(false);
            } catch (error) {
                setError(error);
                window.location.href = '/auth';
            }
        };

        fetchData();
        // const fetchInterval = setInterval(fetchData, 2000);

        // return () => {
        //     clearInterval(fetchInterval);
        // };
    }, []);


    if (data) {
        return (
            <>
                <Container fluid className="p-0 " >
                    <Row >
                        <Col>
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mr-3">
                                {/* <h6>Dashboard &gt; My Certificate</h6> */}
                                <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <h6>Dashboard</h6> 
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-0">
                                <h1 className="h4">My Certificate</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row className=" gy-3">
                        <Card>
                            <Card.Body className="card-today-schedule p-4">
                                <Col lg={12} id="card-cert-summary" className="p-2">
                                    <Row className="p-3">
                                        <Col className="d-flex align-items-center">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <h4 style={{ fontWeight: 'bold' }}>Congratulation! You got {data.length} certificates </h4>
                                                <h5>Complete another course to get more certificate!</h5>
                                            </div>
                                        </Col>
                                        <Col lg={3} id="" style={{ height: '100%' }} className="d-flex justify-content-end">
                                            <img src="/assets_2.png" alt="Certificate" />
                                        </Col>
                                    </Row>
                                </Col>

                                <Row className="mt-2 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 g-lg-3">
                                    {data && data.map((item, index) => (
                                        <Col key={`item-${index}`} style={{ backgroundColor: 'transparent' }}>
                                            <CardCertificate params={item} />
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Card>

                    </Row>

                    <Row className="mt-3">

                    </Row>

                </Container>
            </>

        );
    }


}

export default CertificatePage;

