import React, { useState } from "react";
import { Badge, Button, Col, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarAlt,
  faClock,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";

function EventCourseList({ params }) {
  const [showText, setShowText] = useState(false);
  const [labelSubCategoryName, setLabelSubCategoryName] = useState("");
  let color = "#f2994a";
  let completionStatus = "unstarted";

  // completionStatus = (completionStatus == 'not attempted') ? 'unstarted' : completionStatus;

  if (completionStatus === "completed") {
    color = "#27ae60";
  }

  if (completionStatus === "unstarted") {
    color = "#eb5757";
  }

  const handleMouseEnter = () => {
    setShowText(true);
  };

  const handleMouseLeave = () => {
    setShowText(false);
  };

  const dateString = params.startEnroll;
  const dateObject = new Date(dateString);

  const options = {
    weekday: "short",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = dateObject.toLocaleDateString("id-ID", options);

  const timeOptions = { hour: "2-digit", minute: "2-digit" };
  const formattedTime = dateObject.toLocaleTimeString("en-US", timeOptions);

  return (
    <Col className="card-event-list p-1 row mb-2">
      <Col
        md={4}
        lg={4}
        className=" d-flex flex-column justify-content-center align-items-center p-3"
      >
        <div className="image-event-container">
          <img src="/course-image.jpg" alt="Course Image" className="rounded" />
          <span
            className="badge badge-danger position-absolute top-0 end-0 m-0 p-3"
            style={{ borderTopRightRadius: "15px", backgroundColor: "#eb5757" }}
          >
            Score {parseInt(params.grade) || 0}
          </span>
          <span
            className="badge badge-danger position-absolute bottom-0 start-0 m-2 p-2"
            style={{ backgroundColor: color }}
          >
            {completionStatus}
          </span>
        </div>
      </Col>
      <Col md={8} lg={8} className="p-3">
        <Row>
          <Col>
            <h6 style={{ color: "#0f4372" }}>
              <FontAwesomeIcon icon={faCalendar} /> &nbsp; {formattedDate}{" "}
              &nbsp; &#x2022; &nbsp;
              <FontAwesomeIcon icon={faClock} /> &nbsp; {formattedTime}
            </h6>
          </Col>
          <Col className="d-flex justify-content-end">
            {/* <Button className="btn-event-course" size="sm">
                            <FontAwesomeIcon icon={faLayerGroup} /> Course
                        </Button> */}
            <Link
              to={`/course/detail/${params.courseId}/${params.SubCategoryId}/${params.userid}`}
              className="btn-event-course"
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
              }}
            >
              <FontAwesomeIcon icon={faLayerGroup} /> Course
            </Link>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <h5>
              <b>{params.courseName}</b>
            </h5>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col className="d-flex gap-10" style={{ backgroundColor: "white" }}>
            <h5>
              <Badge
                id="badge-category"
                title="Category Name"
                style={{ fontWeight: "normal" }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {params.CategoryName}
              </Badge>
            </h5>
            <h5>
              <Badge id="badge-lh" style={{ fontWeight: "normal" }}>
                <FontAwesomeIcon
                  style={{ backgroundColor: "transparent" }}
                  icon={faClock}
                />{" "}
                0.5H
              </Badge>
            </h5>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col style={{ backgroundColor: "white" }}>
            <p
              className={
                completionStatus === "completed"
                  ? "progressbar-complete-label mb-2"
                  : "progressbar-ongoing-label mb-2"
              }
              style={{ backgroundColor: "white", fontSize: "13px" }}
            >
              0% Complete
            </p>
            <ProgressBar
              className={
                completionStatus === "unstarted"
                  ? "progressbar-unstarted-color-1"
                  : "progressbar-ongoing-color-1"
              }
              style={{ fontWeight: "100" }}
            >
              <ProgressBar
                className={
                  completionStatus === "unstarted"
                    ? "progressbar-unstarted-color-2"
                    : "progressbar-ongoing-color-2"
                }
                now={100}
                variant="custom"
              />
            </ProgressBar>
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export default EventCourseList;
