import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DashboardPage, {loader as dashboardLoader} from "./pages/Dashboard";
import CoursePage, {loader as courseLoader} from "./pages/Course";
import RootLayout from "./pages/Root";
import ErrorPages from "./pages/Error";
import AuthenticationPage, {action as authAction} from "./pages/AuthenticationPage";
import {action as logoutAction} from "./pages/Logout";
import { authLoader } from "./util/auth";
import CourseActivity from "./pages/CourseActivity";
import CourseStartActivity from "./components/CourseStartActivity";
import MyClassPage from "./pages/MyClass";
import MyClassActivity from "./pages/MyClassActivity";
import MyClassTrainerActivity from "./pages/MyClassTrainerActivity";
import CalendarPage from "./pages/Calendar";
import WebinarPage from "./pages/Webinar";
import EvaluatePage from "./pages/Evaluate";
import EvaluateProgramParticipant from "./pages/EvaluateProgramParticipant";
import ProgramPage from "./pages/Program";
import ProgramDetailPage from "./pages/ProgramDetail";
import CertificatePage from "./pages/CertificatePage";
import InboxPage from "./pages/Inbox";
import Leaderboard from "./pages/Leaderboard";
import EvaluateQuestions from "./pages/EvaluateQuestions";
import MyProfile from "./pages/MyProfile";
import { Buffer } from 'buffer';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPages />,
    id: 'root',
    loader: authLoader,
    children: [
      {
        index: true,
        element: <DashboardPage />
      },
      { path: "course", element: <CoursePage />, loader: courseLoader },
      { path: "course/detail/:courseId/:subCategoryId/:userId", element: <CourseActivity /> },
      { path: "course/detail/start/:courseId/:subCategoryId/:userId", element: <CourseStartActivity /> },

      { path: "myclass", element: <MyClassPage /> },
      { path: "myclass/detail/:courseId/:subCategoryId/:userId", element: <MyClassActivity /> },

      { path: "myclasstrainer/detail/:courseId/:subCategoryId/:userId", element: <MyClassTrainerActivity /> },

      { path: "webinar", element: <WebinarPage /> },

      { path: "certificate", element: <CertificatePage /> },

      { path: "evaluate", element: <EvaluatePage /> },
      { path: "evaluate/:courseId/:subCategoryId/:feedbackId", element: <EvaluateProgramParticipant /> },
      { path: "evaluate/questions/:userid/:courseId/:subCategoryId/:feedbackId", element: <EvaluateQuestions /> },

      { path: "calendar", element: <CalendarPage /> },

      { path: "program", element: <ProgramPage /> },
      { path: "program/detail/:subCategoryId", element: <ProgramDetailPage /> },

      { path: "inbox", element: <InboxPage /> },

      { path: "Leaderboard", element: <Leaderboard /> },

      { path: "my-profile", element: <MyProfile /> },

      { path: "logout", action: logoutAction},
    ],
  },
  { path: "/auth", element: <AuthenticationPage />},
  
]);



function App() {
  return <RouterProvider router={router} />;
}

export default App;
