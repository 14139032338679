import React from "react";
import { Button } from "react-bootstrap";

const EllipsisButton = ({ title, buttonNumber, isActive, onClick }) => {
  const handleClick = () => {
    onClick(buttonNumber);
  };

  const classes = isActive ? `ellipsis-button active ` : `ellipsis-button `;
  return (
    <Button variant="default" className={classes} onClick={handleClick}>
      {title}
    </Button>
  );
};

export default EllipsisButton;
