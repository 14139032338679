
import { Card, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";
import CardProgram from "./CardProgram";

function CardProgramContainerDashboard({params}) {
  const subcategories = params;
  const length = Object.keys(subcategories).length;

  const settings = {
    dots: true,
    autoplay: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992, // Use the modified "lg" breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Use the modified "md" breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // Use the modified "md" breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // Use the modified "md" breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (    
    <Card>
        <Card.Body className="card-overview">
          <Card.Title
              className="d-flex justify-content-between align-items-center"
              style={{ fontSize: "17px", fontWeight: 'bold' }}
          >
              Program
              <Link to='/program' className="view-all-activity">
              View All
              </Link>
          </Card.Title>
          <Col className="mt-3 p-0 mb-3 " style={{ backgroundColor: "white" }}>
            <div className="m-0 slider_container" style={{ backgroundColor: "white" }}>

              <Slider {...settings}>
                {subcategories.map(item => (
                  <div className="border p-3 border-0" key={`item-${item.subCategoryId}`} style={{ backgroundColor: "white" }}>
                    <CardProgram params={item}/>
                  </div>
                ))}

              </Slider>
              
            </div>
          </Col>

        </Card.Body>
    </Card>
  );
}

export default CardProgramContainerDashboard;
