import React, { useEffect, useState } from 'react'
import { faCalendar, faCalendarAlt, faCertificate, faClock, faFile, faFileAlt, faLanguage, faLayerGroup, faStar, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link, json, redirect, useParams } from 'react-router-dom';
import { getAuthData, ucfirst } from '../util/auth';
import CourseContentSummary from '../components/CourseContentSummary';
import ClassContentSummary from '../components/ClassContentSummary';
import QRCode from 'qrcode.react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function MyClassActivity() {
    const params = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/me/get_course_content";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        async function fetchEvents() {
            setIsLoading(true);

            try {
                const response = await fetch(
                    url,
                    {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify({
                            'courseid': params.courseId,
                            'subcategoryid': params.subCategoryId,
                            'userid': params.userId,
                        }),
                    }
                );

                const responseBody = await response.json(); // Read the response body once
                console.log("Response body:", responseBody);


                if (!response.ok) {
                    const error = new Error('An error occurred while fetching the course');
                    error.code = response.status;
                    error.info = 'error';
                    throw error;
                }

                console.log("Fetched course:", responseBody);
                setData(responseBody); // Set the fetched course data
            } catch (error) {
                console.error("Fetch error:", error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchEvents();
    }, []);


    let ScoreBadgeColor = '#eb5757';
    let CourseStatusBadgeColor = '#f2994a';
    let ProgressBadgeColor = '#f2994a';

    // if (data) {
        // const score = data.data.original.grade;
        // const courseStatus = data.data.original.completionStatus;
        // const courseProgress = data.data.original.percent_complete;
        return (
            <>
                <Container fluid className="p-0 " >
                    <Row className="m-0">
                        
                        {data ? (
                            <div className="d-flex justify-content-start pt-3 pb-2 mr-3">
                                <Link to={`/myclass`} style={{ textDecoration: 'none' }}>
                                    <h6>My Class</h6> 
                                </Link>
                                <h6>&nbsp; &gt; &nbsp;</h6>
                                {data.data.original && data.data.original.fullname && (
                                    <h6>{data.data.original.fullname}</h6>
                                )}
                            </div>
                        ) : (
                            <div className="pt-3 pb-2 mr-3" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "20px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                    </Row>

                    <Row className="m-0">
                        <Col lg={8} className="mb-3">
                        {data ? (
                            <Card >
                                <Card.Body className="card-course-content">
                                    <Card.Title
                                        className="d-flex justify-content-between align-items-center"
                                        style={{ fontSize: "28px", fontWeight: 'bold' }}
                                    >
                                        {data.data.original.fullname}
                                    </Card.Title>
                                    <div className='col-md-6 bg-white' >
                                        <table className="bg-white" style={{ border: 'none', backgroundColor: 'white' }}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding: '10px', border: 'none', backgroundColor: 'white' }}>
                                                        Score
                                                        <br />
                                                        <span className="badge badge-danger p-2 w-100 text-start" style={{ backgroundColor: ScoreBadgeColor }}>
                                                            <FontAwesomeIcon icon={faStar} style={{ marginRight: '5px', backgroundColor: 'transparent ' }} />
                                                            {data.data.original.grade}
                                                        </span>
                                                    </td>
                                                    <td style={{ padding: '10px', border: 'none', backgroundColor: 'white' }}>
                                                        Course Status
                                                        <br />
                                                        <span className="badge badge-danger p-2  w-100 text-start" style={{ backgroundColor: CourseStatusBadgeColor, fontWeight: 'normal' }}>
                                                            {ucfirst(data.data.original.completionStatus)}
                                                        </span>
                                                    </td>
                                                    <td style={{ padding: '10px', border: 'none', backgroundColor: 'white' }}>
                                                        Progress
                                                        <br />
                                                        <span className="badge badge-danger p-2  w-100 text-start" style={{ backgroundColor: ProgressBadgeColor, fontWeight: 'normal' }}>
                                                            {data.data.original.percent_complete} %
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <ClassContentSummary params={data.data} parameter={params} />

                                </Card.Body>
                            </Card>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                            
                        </Col>
                        <Col lg={4} className="mb-3">
                            {data ? (
                                <Card >
                                    <Card.Body style={{ backgroundColor: 'white', borderRadius: '15px', border: 'none' }}>
                                        <Card.Img variant="top" src="/course-image.jpg" style={{ borderRadius: '15px' }} />
                                        <Link to={`/course/detail/start/${params.courseId}/${params.subCategoryId}/${params.userId}`} style={{ textDecoration: 'none' }}>
                                            <div className="d-grid gap-2 mt-3">
                                                <button className="btn btn-success" type="button" id="startNow" style={{ borderRadius: '15px', color: 'white' }}>
                                                    Start Now
                                                </button>
                                            </div>
                                        </Link>


                                        <Card className='mt-3' >
                                            <Card.Body className="card-course-summary-sort" style={{backgroundColor: 'white', borderColor: '#d9d9d9'}}>
                                                <ul className="list-group list-group-flush" id="class-summary-info">
                                                    <li className="list-group-item">
                                                        <div className="d-flex w-100 justify-content-between">
                                                            <small><FontAwesomeIcon icon={faCalendarAlt} className="nav-icon-sidebar" />Deadline</small>
                                                            <small>{data.data.original.deadline}</small>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <div className="d-flex w-100 justify-content-between">
                                                            <small><FontAwesomeIcon icon={faFileAlt} className="nav-icon-sidebar" />Lectures</small>
                                                            <small>{data.data.original.lectures}</small>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <div className="d-flex w-100 justify-content-between">
                                                            <small><FontAwesomeIcon icon={faClock} className="nav-icon-sidebar" />Durations</small>
                                                            <small>{data.data.original.duration}</small>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <div className="d-flex w-100 justify-content-between">
                                                            <small><FontAwesomeIcon icon={faUserGroup} className="nav-icon-sidebar" />Enrolled</small>
                                                            <small>{data.data.original.inclass_participant.length} Participant</small>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <div className="d-flex w-100 justify-content-between">
                                                            <small><FontAwesomeIcon icon={faLanguage} className="nav-icon-sidebar" />Language</small>
                                                            <small>{data.data.original.language}</small>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <div className="d-flex w-100 justify-content-between">
                                                            <small><FontAwesomeIcon icon={faCertificate} className="nav-icon-sidebar" />Certificate</small>
                                                            <small>{data.data.original.certificate}</small>
                                                        </div>
                                                    </li>
                                                </ul>


                                            </Card.Body>
                                        </Card>

                                        <Card className='mt-3' >
                                            <Card.Body className="card-course-summary-sort" style={{backgroundColor: 'white', borderColor: '#d9d9d9'}}>
                                                <Col className='p-0  d-flex justify-content-center' style={{backgroundColor: 'white', borderColor: '#d9d9d9'}}>
                                                <QRCode value={data.data.original.inclass.inclassToken} size={250} />
                                                </Col>
                                            </Card.Body>
                                        </Card>

                                    </Card.Body>
                                </Card>
                            ) : (
                                <div className="" style={{ backgroundColor: "transparent" }} >
                                    <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                    <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                                    </div>
                                </div>
                            )}
                            
                        </Col>
                    </Row>
                </Container>
            </>
        )
    // }

}

export default MyClassActivity;
