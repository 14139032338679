import React from 'react';
import { Dropdown } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';

const NotificationItems = ({ title, subtitle, badge, color }) => {
    const itemClassName = `badge bg-${color}`;
    const navigate = useNavigate();

  const handleItemClick = () => {
    navigate('/inbox');
  };

  return (
    <Dropdown.Item className="custom-dropdown-item" onClick={handleItemClick} style={{backgroundColor: "white"}}>
      <div className="d-flex align-items-center justify-content-between" >
        <div>
          <div className="custom-dropdown-title">{title}</div>
          <div className="custom-dropdown-subtitle">{subtitle}</div>
        </div>
        <span className={itemClassName}>{badge}</span>
      </div>
    </Dropdown.Item>
  );
};

export default NotificationItems;