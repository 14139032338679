import { Card, Col, Dropdown, DropdownButton, Form, Row, Stack } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getAuthData } from "../util/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AccordionCustom from "./AccordionCustom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


function CardTrainerContainer() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchProgress, setFetchProgress] = useState(0);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('asc');

  const nest_url = process.env.REACT_APP_NEST_URL;

  const secretCode = process.env.REACT_APP_SECRET_CODE;
  const token = getAuthData();

  const headers = {
    Authorization: `Bearer ${token.jwt}`,
    secret: secretCode,
    'Content-Type': 'application/json',
  };

  if (!token) {
    window.location.href = '/auth';
  }

  useEffect(() => {
    setIsFetching(true);
    setFetchProgress(0);
    const fetchData = async () => {
      try {
        let url;
        url = nest_url + "public/me/trainer_area";
        const requestBody = {
          search,
          sort
        };

        const requestOptions = {
          method: "POST",
          headers: headers,
          body: JSON.stringify(requestBody)
        };

        const response = await fetch(url, requestOptions);

        if (!response.ok) {
          throw new Error('Could not fetch data');
        }

        const responseBody = await response.json();
        setData(responseBody.data);
        setFetchProgress(100);
        setIsFetching(false);
      } catch (error) {
        setError(error);
        window.location.href = '/auth';
      }
    };
    fetchData();
  }, [search, sort]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSortChange = (sortValue) => {
    setSort(sortValue);
  };

  return (
    <Card>
      <Card.Body className="card-overview h-100">
        <Col lg={12} md={12} className="mt-2 ">
          <Row>
            <Col className="d-flex align-item-center " lg={6} md={6} sm={6} xs={12} style={{ backgroundColor: "white" }}>
              <h5 style={{ backgroundColor: 'transparent' }}>Course to Train</h5>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12} className="text-end " style={{ backgroundColor: "white" }}>
              <Row className="justify-content-end d-none d-md-flex d-lg-flex " style={{ backgroundColor: "white", marginRight: '5px' }}>
                <Stack className="" direction="horizontal" gap={3} style={{ backgroundColor: "white ", paddingRight: '0px' }}>
                  <Form.Control size="sm" className="me-auto" placeholder="Search Course..."
                    value={search} onChange={handleSearchChange}
                  />
                  <DropdownButton
                    size="sm"
                    title="Sort By"
                    id="dropdown-sort-2"
                    onSelect={handleSortChange}
                    >
                    <Dropdown.Item eventKey="asc" active={sort === "asc"}>
                        Course Name (Asc)
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="desc" active={sort === "desc"}>
                        Course Name (Desc)
                    </Dropdown.Item>
                    </DropdownButton>
                </Stack>
              </Row>
              <Row>
                                <Col className="d-md-none d-lg-none" style={{ backgroundColor: "white " }} >
                                    <Stack className="" direction="horizontal" gap={3} style={{ backgroundColor: "white ", paddingRight: '0px'}}>
                                        <Form.Control size="sm" className="me-auto" placeholder="Search Course..." 
                                        value={search} onChange={handleSearchChange}
                                        />
                                        <DropdownButton size="sm"
                                            title="Sort By"
                                            id="dropdown-sort-2"
                                            value={sort} 
                                            onChange={handleSortChange} 
                                        >
                                            <Dropdown.Item >
                                            Course Name (Asc)
                                            </Dropdown.Item>
                                            <Dropdown.Item >
                                            Course Name (Desc)
                                            </Dropdown.Item>
                                        </DropdownButton>

                                    </Stack>
                                </Col>
                            </Row>
            </Col>
          </Row>
        </Col>

        <Col className="mt-3" style={{ border: 'none', backgroundColor: 'transparent' }}>
          {data !== null ? (
            <AccordionCustom params={data} />
          ) : (
            <div className="" style={{ backgroundColor: "white" }} >
              <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}>
                <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
              </div>
            </div>
          )}
        </Col>
      </Card.Body>
    </Card>
  );
}

export default CardTrainerContainer;