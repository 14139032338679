import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row, Stack } from "react-bootstrap";
import EllipsisButton from "../components/EllipsisButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import EllipsisButtonCourseNav from "../components/EllipsisButtonCourseNav";
import { getAuthData } from "../util/auth";
import { Link, json, redirect, useLoaderData } from "react-router-dom";
import CardCourse from "../components/CardCourse";
import CardScheduleList from "../components/CardScheduleList";
import WebinarCard from "../components/WebinarCard";
import CardProgram from "../components/CardProgram";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ProgramPage() {
    const isMobile = window.innerWidth <= 575.98; 
    const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
    const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
    const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
    const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
    const isExtraExtraLarge = window.innerWidth >= 1400;

    const [data, setData] = useState(null);
    const [sortedData, setSortedData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchProgress, setFetchProgress] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/me/my_program";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        setIsFetching(true);
        setFetchProgress(0);
        const fetchData = async () => {
            try {

                const response = await fetch(
                    url,
                    {
                        method: "POST",
                        headers: headers,
                    }
                );

                if (!response.ok) {
                    throw new Error('Could not fetch data');
                }

                const responseBody = await response.json();

                setData(responseBody.data);
                setFetchProgress(100);
                setIsFetching(false);
            } catch (error) {
                setError(error);
                window.location.href = '/auth';
            }
        };

        fetchData();
    }, []);

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const [sortBy, setSortBy] = useState('Sort By');

    useEffect(() => {
        if (data) {
            setSortedData(data);
        }
    }, [data]);

    useEffect(() => {
        if (sortedData) {
            // Perform sorting based on the selected sort option
            if (sortBy === 'programNameAsc') {
                setSortBy('Program Name Asc');
                setSortedData([...sortedData].sort((a, b) => a.subCategoryName.localeCompare(b.subCategoryName)));
            } else if (sortBy === 'programNameDesc') {
                setSortBy('Program Name Desc');
                setSortedData([...sortedData].sort((a, b) => b.subCategoryName.localeCompare(a.subCategoryName)));
            }
        }
    }, [sortBy, sortedData]);

    const handleSort = (eventKey) => {
        setSortBy(eventKey);
      };

    return (
        <>
            <Container fluid className="p-0 " >
                        <Row className="">
                            <div className="d-flex justify-content-start pt-3 pb-1 mr-3 ">
                                <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <h6>Dashboard</h6> 
                                </Link>
                            </div>
                        </Row>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-0">
                            <h1 className="h4">Program</h1>
                        </div>
                    </Col>

                    <Col sm={6} md={6} lg={6} className="d-block d-md-block d-lg-block mb-2">
                        <Stack direction="horizontal" gap={3}>
                            <Form.Control size="sm" className="me-auto" placeholder="Search Program..." 
                            value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <Dropdown onSelect={handleSort}>
                                <Dropdown.Toggle size="sm" variant="success" id="dropdown-sort-small" >
                                {sortBy}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="programNameAsc">Program Name (Asc)</Dropdown.Item>
                                    <Dropdown.Item eventKey="programNameDesc">Program Name (Desc)</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Stack>
                    </Col>

                    <Col className="d-none" style={{ paddingRight: '20px' }}>
                        <Row className="">
                            <Col lg={8} className=" d-flex justify-content-end  " >
                                <div className=" search-sort-container p-0" >
                                    <div className="search-input-container " >
                                        <input
                                            type="text"
                                            placeholder="Search Program"
                                            className="search-input"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            className="search-icon"
                                            onClick={() => handleSearch()}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className="d-flex justify-content-end p-0">
                                <div className="dropdown-container" style={{ width: '100%', height: '100%' }}>
                                    <DropdownButton
                                    title={sortBy}
                                    id="dropdown-sort"
                                    onSelect={handleSort}
                                    >
                                    <Dropdown.Item eventKey="programNameAsc">Program Name (Asc)</Dropdown.Item>
                                    <Dropdown.Item eventKey="programNameDesc">Program Name (Desc)</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="mt-3 mt-lg-3 gy-3 gx-3">
                {sortedData && sortedData.length > 0 ? (
                    sortedData
                        .filter((item) => item.subCategoryName.toLowerCase().includes(searchQuery.toLowerCase()))
                        .map((item) => (
                        <Col className="mt-2" sm={4} md={4} lg={3} key={`item-${item.subCategoryId}`} style={{ backgroundColor: 'transparent' }}>
                            <CardProgram params={item} />
                        </Col>
                        ))
                    ) : (
                    <div className="" style={{ backgroundColor: "transparent" }} >
                        <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                        <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                        </div>
                    </div>
                    )}

                </Row>

            </Container>
        </>

    );
}

export default ProgramPage;

