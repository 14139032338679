
import { Nav, Navbar } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListMenu from './ListMenu';
import Image  from "react-bootstrap/Image";
import ListMenuMobile from './ListMenuMobile';

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';
import { useLoaderData, useSubmit } from "react-router-dom";
import {
  faThLarge,
  faLayerGroup,
  faBook,
  faCalendar,
  faWindowMaximize,
  faTasks,
  faComments,
  faStar,
  faUser,
  faSignOut,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Badge } from "react-bootstrap";
import BadgeInboxCount from "./BadgeInboxCount";

function OffCanvasSideMenu() {
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedComponent, setSelectedComponent] = useState('');

  const handleComponentChange = (component) => {
    setSelectedComponent(component);
  };

  const bg_ = "bg-light pt-2 ";
  const linkPadding = "nav-link-mobile";
  const [activeLink, setActiveLink] = useState('/');
  const submit = useSubmit();
  const handleLogout = () => {
      submit(null, { action: '/logout', method: 'post' });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    console.log(windowWidth); // Log windowWidth inside the useEffect

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  const isMobile = window.innerWidth <= 575.98; 
  const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
  const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
  const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
  const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
  const isExtraExtraLarge = window.innerWidth >= 1400;
  
  return (
    <>
      
      {/* {windowWidth <= 992 && ( */}
        <Navbar.Toggle onClick={handleShow} style={{display: 'block !important'}}/>
      {/* )} */}

      <Offcanvas show={show} onHide={handleClose} className="d-block d-xl-none" style={{maxWidth: '200px'}}>
        <Offcanvas.Body className='bg-light p-0'>

            <Nav defaultActiveKey="/dashboard" as="ul" className={`flex-column ${bg_}`} >
              <Nav.Item as="li" className="mb-1">
                <Nav.Link to="/" as={NavLink} 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                className={(isActive) => (isActive ? `actives ${bg_} ${linkPadding}` : `  ${bg_} ${linkPadding}` )}
                onClick={handleClose}
                >
                  <FontAwesomeIcon icon={faThLarge} className="nav-icon-sidebar" /> Dashboard
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" className="mb-1"> 
                <Nav.Link to="/course" as={NavLink} 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}
                onClick={handleClose}>
                  <FontAwesomeIcon icon={faLayerGroup} className="nav-icon-sidebar" /> Course
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" className="mb-1"> 
                <Nav.Link to="/myclass" as={NavLink} 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}
                onClick={handleClose}>
                  <FontAwesomeIcon icon={faBook} className="nav-icon-sidebar" /> My Class
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" className="mb-1"> 
                <Nav.Link to="/webinar" as={NavLink} 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}
                onClick={handleClose}>
                  <FontAwesomeIcon icon={faWindowMaximize} className="nav-icon-sidebar" /> Webinar
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" className="mb-1"> 
                <Nav.Link to="/evaluate" as={NavLink} 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}
                onClick={handleClose}>
                  <FontAwesomeIcon icon={faTasks} className="nav-icon-sidebar" /> Evaluate
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" className="mb-1"> 
                <Nav.Link to="/calendar" as={NavLink} 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}
                onClick={handleClose}>
                  <FontAwesomeIcon icon={faCalendar} className="nav-icon-sidebar" /> Calendar
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" className="mb-1"> 
                <Nav.Link to="/inbox" as={NavLink} 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}
                onClick={handleClose}>
                  <FontAwesomeIcon icon={faComments} className="nav-icon-sidebar" /> Inbox 
                  {' '}
                  <BadgeInboxCount/>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li"> 
                <Nav.Link to="/leaderboard" as={NavLink} 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}
                onClick={handleClose}>
                  <FontAwesomeIcon icon={faStar} className="nav-icon-sidebar" /> Leaderboard
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Nav as="ul" className={`flex-column ${bg_}`} >
              <Nav.Item as="li" className="mb-1">
                <Nav.Link to="/my-profile" as={NavLink} 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                className={(isActive) => (isActive ? `active ${bg_}` : ` ${bg_}` )}
                onClick={handleClose}>
                  <FontAwesomeIcon icon={faUser} className="nav-icon-sidebar" /> My Profile
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" className="mb-1">
                <Nav.Link to="#" className="" 
                style={{padding: '0px', paddingLeft: '16px', paddingTop: '10px', paddingBottom: '10px'}}
                onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOut} className="nav-icon-sidebar" /> Logout
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Nav className=" fixed-bottom bg-light text-center" style={{width: '180px'}}>
              <Navbar.Brand href="/" className="col-md-3 col-lg-2 me-0 p-0 fs-6 bg-light">
                <Image src="/blacklogo.png" alt="Logo" className="colibri-logo bg-light" />
              </Navbar.Brand>
            </Nav>

        {/* <div className="position-sticky sidebar-sticky p-1 bg-light border">

          <ListMenuMobile />

        </div> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvasSideMenu;