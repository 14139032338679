import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row, Stack } from "react-bootstrap";
import EllipsisButton from "../components/EllipsisButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import EllipsisButtonCourseNav from "../components/EllipsisButtonCourseNav";
import { getAuthData } from "../util/auth";
import { json, redirect, useLoaderData, Link} from "react-router-dom";
import CardCourse from "../components/CardCourse";

function CoursePage() {

  const [activeButton, setActiveButton] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('default');
  const [sortOptionLabel, setSortOptionLabel] = useState('Sort By');

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleSort = (option) => {
    setSortOption(option);
    if(option === 'courseNameAsc') {
      setSortOptionLabel('Course Name (Asc)');
    } else if (option === 'courseNameDesc'){
      setSortOptionLabel('Course Name (Desc)');
    }
  };
  const data_ = useLoaderData();

  const { data } = JSON.parse(data_);

  console.log('data', data);

  const newData = {
    completed: [],
    "not attempted": [],
    ongoing: []
  };

  data.mandatory.forEach(item => {
    newData[item.completionStatus].push(item);
  });

  data.optional.forEach(item => {
    newData[item.completionStatus].push(item);
  });

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  const filterData = () => {
    let filteredData = [];
    let count = 0;

    switch (activeButton) {
      case 1:
        filteredData = newData.completed.concat(newData['not attempted'], newData.ongoing);
        count = filteredData.length;
        break;
      case 2:
        filteredData = newData.completed;
        count = filteredData.length;
        break;
      case 3:
        filteredData = newData.ongoing;
        count = filteredData.length;
        break;
      case 4:
        filteredData = newData['not attempted'];
        count = filteredData.length;
        break;
      default:
        filteredData = newData.completed.concat(newData['not attempted'], newData.ongoing);
        count = filteredData.length;
        break;
    }

    let sortedData = [...filteredData];
    if (sortOption === 'courseNameAsc') {
      sortedData.sort((a, b) => a.courseName.localeCompare(b.courseName));
    } else if (sortOption === 'courseNameDesc') {
      sortedData.sort((a, b) => b.courseName.localeCompare(a.courseName));
    }

    filteredData = [...sortedData];
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.courseName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return { filteredData, count };
  };

  const { filteredData, count } = filterData();

  const topRef = useRef(null);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <>
      <Container fluid className="p-0 " ref={topRef}>
                        <Row className="">
                            <div className="d-flex justify-content-start pt-3 pb-1 mr-3 ">
                                <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <h6>Dashboard</h6> 
                                </Link>
                            </div>

                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-0 ">
                              <h1 className="h4">Course</h1>
                            </div>
                        </Row>
        
        <Row className="p-1 ">
              <Col className=" d-block d-md-none d-lg-none mb-2">
                <Stack direction="horizontal" gap={3}>
                  <Form.Control size="sm" className="me-auto" placeholder="Search Course..." value={searchQuery} onChange={handleSearch}/>
                  <Dropdown>
                    <Dropdown.Toggle size="sm" variant="success" id="dropdown-sort-small" stye={{backgroundColor: "red !important"}}>
                      {sortOptionLabel}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item
                          active={sortOption === 'courseNameAsc'}
                          onClick={() => handleSort('courseNameAsc')}
                        >
                          Course Name (Asc)
                        </Dropdown.Item>
                        <Dropdown.Item
                          active={sortOption === 'courseNameDesc'}
                          onClick={() => handleSort('courseNameDesc')}
                        >
                          Course Name (Desc)
                        </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Stack>
              </Col>
              <Col sm={12} md={7} className=" ">
                <div className=" " style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                  <EllipsisButtonCourseNav
                    buttonNumber={1}
                    isActive={activeButton === 1}
                    onClick={() => handleButtonClick(1)}
                    title={`All (${newData.completed.length + newData['not attempted'].length + newData.ongoing.length})`}
                  />
                  <EllipsisButtonCourseNav
                    buttonNumber={2}
                    isActive={activeButton === 2}
                    onClick={() => handleButtonClick(2)}
                    title={`Completed (${newData.completed.length})`}
                  />
                  <EllipsisButtonCourseNav
                    buttonNumber={3}
                    isActive={activeButton === 3}
                    onClick={() => handleButtonClick(3)}
                    title={`On Progress (${newData.ongoing.length})`}
                  />
                  <EllipsisButtonCourseNav
                    buttonNumber={4}
                    isActive={activeButton === 4}
                    onClick={() => handleButtonClick(4)}
                    title={`Unstarted (${newData['not attempted'].length})`}
                  />
                </div>
              </Col>

              <Col md={5} sm={12} className=" d-flex align-items-center justify-content-end d-none d-md-block d-lg-block " >
                <Stack direction="horizontal" gap={3}>
                  <Form.Control size="md" className="me-auto" placeholder="Search Course..." value={searchQuery} onChange={handleSearch}/>
                  <Dropdown>
                    <Dropdown.Toggle size="md" variant="success" id="dropdown-sort-small" stye={{backgroundColor: "red !important"}}>
                      {sortOptionLabel}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item
                          active={sortOption === 'courseNameAsc'}
                          onClick={() => handleSort('courseNameAsc')}
                        >
                          Course Name (Asc)
                        </Dropdown.Item>
                        <Dropdown.Item
                          active={sortOption === 'courseNameDesc'}
                          onClick={() => handleSort('courseNameDesc')}
                        >
                          Course Name (Desc)
                        </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Stack>
              </Col>
            </Row>

        <Row className=" mt-2 g-3 mb-4">
          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <Col xs={12} sm={6} md={6} lg={4} key={index} className="d-flex justify-content-center">
                <CardCourse course={item} />
              </Col>
            ))
          ) : (
            <Col xs={12}>
              <p>No courses found.</p>
            </Col>
          )}
        </Row>

      </Container>
    </>

  );
}

export default CoursePage;

export async function loader() {
  const nest_url = process.env.REACT_APP_NEST_URL;
  const url = nest_url + "public/me/my_course";
  const secretCode = process.env.REACT_APP_SECRET_CODE;
  const token = getAuthData();

  if (!token) {
    return redirect('/auth');
  } else {
    const headers = {
      Authorization: `Bearer ${token.jwt}`,
      secret: secretCode,
      'Content-Type': 'application/json',
    };

    const response = await fetch(
      url,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ 'type': 'all' }),
      }
    );
    
    if (!response.ok) {
      throw json({ message: 'Could not fetch data' }, {
        status: response.status,
      });
    } else {
      return response;
    }
  }
}