import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Card, Col, Dropdown, Row } from 'react-bootstrap'
import { getAuthData } from '../util/auth';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


// toast.configure()

const CardViewMessage = ({ messageId, onData }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/inbox/getMessageById";
    const secretCode = process.env.REACT_APP_SECRET_CODE;
    const MySwal = withReactContent(Swal)
    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    const BadgeType = ({ msgType }) => {
        let label = "";
        let class_ = ""
        if (msgType == 'inclass') {
            label = 'Classroom';
            class_ = 'badge-classroom';
        } else if (msgType == 'daily_quiz') {
            label = 'Daily Quiz';
            class_ = 'badge-quiz';
        } else if (msgType == 'news') {
            label = 'News';
            class_ = 'badge-news';
        }

        return <span className={class_} >{label}</span>;

    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: '2-digit' });
        const month = date.toLocaleDateString('en-US', { month: 'short' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }

    useEffect(() => {
        if (messageId) {
            const fetchData = async () => {
                try {
                    const response = await fetch(
                        url,
                        {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify({ 'id': messageId })
                        }
                    );
    
                    if (!response.ok) {
                        throw new Error('Could not fetch data');
                    }
    
                    const responseBody = await response.json();
    
                    setData(responseBody.data[0]);
    
                } catch (error) {
                    setError(error);
                    window.location.href = '/auth';
                }
            };
    
            fetchData();
        }
    }, [messageId]);


    const handleSetAsUnread = async (id) => {
        let url = nest_url + "public/inbox/updateUnreadMessageStatus";
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ 'msgId': id })
            });
    
            if (!response.ok) {
                throw new Error('Could not fetch data');
            }
    
            const responseBody = await response.json();
            console.log(responseBody);
            onData('unread');
        } catch (error) {
            setError(error);
            window.location.href = '/auth';
        }
    };


    const handleDeleteMessage = (id) => {
        
        MySwal.fire({
            title: 'Do you want to delete this message ?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            confirmButtonColor: '#0f4372',
            cancelButtonColor: '#d33',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                deleteMessages(id);
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    };

    const deleteMessages = async (id) => {
        let url = nest_url + "public/inbox/deleteMessage";
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ 'msgId': [id] })
            });

            if (!response.ok) {
                throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();
            console.log(responseBody.success);
            if (responseBody.success) {
                onData('delete');
            }
            // onData('unread');
        } catch (error) {
            // setError(error);
            // window.location.href = '/auth';
        }
    }


        return (
            <>
                    {data !== null ? (
                        <Card className="mb-4">
                            <Card.Body className="card-today-schedule">
                                <Row>
                                    <Col className="">
                                        <Card.Title className="mb-3">{data.msgTitle}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">{formatDate(data.msgDate)} <BadgeType msgType={data.msgType} /></Card.Subtitle>
                                    </Col>
                                    <Col lg={1} className=" d-flex align-items-center justify-content-center">
                                        {/* <FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#86a0b89d', fontSize: '30px' }} /> */}
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-message-content">
                                            <FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#86a0b89d', fontSize: '30px' }} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleSetAsUnread(data.msgId)}>Mark as unread</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDeleteMessage(data.msgId)}>Delete message</Dropdown.Item>
                                            </Dropdown.Menu>
                                            
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <Row className="p-2 mt-2">
                                    <Col id="msg-container" className="p-2">
                                        {data.msgDesc}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    ) : (
                        <div className="" style={{ backgroundColor: "transparent" }} >
                            <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                            <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                            </div>
                        </div>
                    )}
                
            </>
        )
    
}

export default CardViewMessage