import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faChartSimple,
  faClock,
  faLayerGroup,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row } from "react-bootstrap";

function CardCourseLearned({params}) {
  const total_courses = params.total_course;
  const total_course_learned = params.total_course_learned;
  const percentage = Math.ceil((total_course_learned / total_courses) * 100);

  const isMobile = window.innerWidth <= 575.98; 
  const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
  const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
  const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
  const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
  const isExtraExtraLarge = window.innerWidth >= 1400;

  let circle_width = "80%";
  let circle_height = "80%";

  if(isSmall){
      circle_width = "55%";
      circle_height = "55%";
  } else if (isMedium){
      circle_width = "55%";
      circle_height = "55%";
  }

  return (
    <Card>
      <Card.Body className="card-course-learned">
        <Card.Title
          className="d-flex justify-content-between align-items-center"
          style={{ fontSize: "17px", fontWeight: 'bold'  }}
        >
          Course Learned
          <FontAwesomeIcon
            icon={faLayerGroup}
            className="card-course-learned-icon"
          />
        </Card.Title>
       
        <Row className="mt-4" style={{ backgroundColor: "transparent" }}>
          <Col
            md="5" sm="5" xs="4"
            className="mb-2 p-0 d-flex align-items-center"
            style={{ backgroundColor: "transparent" }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' ,  backgroundColor: "#daedfd"  }}>
                <svg width={circle_width} height={circle_height} viewBox="0 0 160 160" style={{ padding: '0',  backgroundColor: "#daedfd" }}>
                    <defs>
                        <linearGradient id="linearCourseLearned" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#0f4372" />
                        <stop offset="100%" stopColor="#b1dafd" />
                        </linearGradient>
                    </defs>
                    <circle cx="80" cy="80" r="70" stroke="white" strokeWidth="10" fill="transparent" />
                    <path
                      d="M 80,10 A 70 70 0 0 1 80 150 A 70 70 0 0 1 80 10"
                      stroke="url(#linearCourseLearned)"
                      strokeLinecap="round"
                      strokeWidth="10"
                      fill="transparent"
                      strokeDasharray={`${(percentage * 440) / 100}, 440`}
                    />
                    
                    <text x="50%" y="50%" textAnchor="middle" dominantBaseline="central" fontSize="24" fill="#0f4372" style={{ zIndex: 1 }}>
                        {percentage}%
                    </text>
                </svg>
            </div>
          </Col>
          <Col
            md="7" sm="7" xs="8"
            className="mb-2"
            style={{ backgroundColor: "transparent" }}
          >

{
                    isMobile ? (
                      <>
                        <Row className="p-1 d-flex align-items-center" style={{ backgroundColor: "transparent" }}>
                          <Col className="mb-2" xs={12} style={{ fontSize: "15px",backgroundColor: "#b1dafd", borderRadius: "15px" }}>
                            <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}> {total_course_learned} </b>
                            <b style={{ backgroundColor: "transparent" }}>courses</b>
                          </Col>

                          <Col xs={12} style={{ fontSize: "15px",backgroundColor: "#b1dafd", borderRadius: "15px" }}>
                            from total
                            <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}> {total_courses} </b>
                            <b style={{ backgroundColor: "transparent" }}>courses</b>
                          </Col>
                        </Row>
                      </>
                    ) : isSmall ? (
                        <>
                          <Row className="p-1 d-flex align-items-center" style={{ backgroundColor: "transparent" }}>
                            <Col className="mb-2" xs={12} style={{ fontSize: "25px", padding: "20px 20px 0 20px", backgroundColor: "#b1dafd", borderRadius: "15px" }}>
                              <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "35px" }}> {total_course_learned} </b>
                              <b style={{ backgroundColor: "transparent" }}>courses</b>
                            </Col>

                            <Col xs={12} style={{ fontSize: "25px",backgroundColor: "#b1dafd", borderRadius: "15px", padding: '15px'  }}>
                              from total
                              <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}> {total_courses} </b>
                              <b style={{ backgroundColor: "transparent" }}>courses</b>
                            </Col>
                          </Row>
                        </>
                    ) : isMedium ? (
                        <>
                          <Row className="p-1 d-flex align-items-center" style={{ backgroundColor: "transparent" }}>
                            <Col className="mb-2" xs={12} style={{ fontSize: "25px", padding: "20px 20px 0 20px", backgroundColor: "#b1dafd", borderRadius: "15px" }}>
                              <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "35px" }}> {total_course_learned} </b>
                              <b style={{ backgroundColor: "transparent" }}>courses</b>
                            </Col>

                            <Col xs={12} style={{ fontSize: "25px",backgroundColor: "#b1dafd", borderRadius: "15px" , padding: '15px' }}>
                              from total
                              <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}> {total_courses} </b>
                              <b style={{ backgroundColor: "transparent" }}>courses</b>
                            </Col>
                          </Row>
                        </>
                    )  : isLarge ? (
                        <>
                          <Card className="card-course-learned ">
                            <Card.Body style={{ fontSize: "15px", padding: "10px 10px 0 10px",  backgroundColor: "#b1dafd", "borderRadius": "15px" }}>
                                <b style={{ paddingTop: "0",  backgroundColor: "transparent" , fontSize: "34px" }} > {total_course_learned} </b>
                                <b style={{ backgroundColor: "transparent" }} >courses</b>
                            </Card.Body>
                          </Card>

                          <Card className="card-course-learned  mt-3">
                            <Card.Body style={{ fontSize: "12px", backgroundColor: "#b1dafd", "borderRadius": "15px"}}>
                              from total <b style={{ backgroundColor: "transparent" }} >{total_courses} courses</b>
                            </Card.Body>
                          </Card>
                        </>
                    )  : isExtraLarge ? (
                        <>
                          <Card className="card-course-learned ">
                            <Card.Body style={{ fontSize: "15px", padding: "10px 10px 0 10px",  backgroundColor: "#b1dafd", "borderRadius": "15px" }}>
                                <b style={{ paddingTop: "0",  backgroundColor: "transparent" , fontSize: "34px" }} > {total_course_learned} </b>
                                <b style={{ backgroundColor: "transparent" }} >courses</b>
                            </Card.Body>
                          </Card>

                          <Card className="card-course-learned  mt-3">
                            <Card.Body style={{ fontSize: "12px", backgroundColor: "#b1dafd", "borderRadius": "15px"}}>
                              from total <b style={{ backgroundColor: "transparent" }} >{total_courses} courses</b>
                            </Card.Body>
                          </Card>
                        </>
                    )  : isExtraExtraLarge ? (
                        <>
                          <Card className="card-course-learned ">
                            <Card.Body style={{ fontSize: "15px", padding: "10px 10px 0 10px",  backgroundColor: "#b1dafd", "borderRadius": "15px" }}>
                                <b style={{ paddingTop: "0",  backgroundColor: "transparent" , fontSize: "34px" }} > {total_course_learned} </b>
                                <b style={{ backgroundColor: "transparent" }} >courses</b>
                            </Card.Body>
                          </Card>

                          <Card className="card-course-learned  mt-3">
                            <Card.Body style={{ fontSize: "12px", backgroundColor: "#b1dafd", "borderRadius": "15px"}}>
                              from total <b style={{ backgroundColor: "transparent" }} >{total_courses} courses</b>
                            </Card.Body>
                          </Card>
                        </>
                    ) : null
                  }
                  
            {/* {!isMobile ? (
                <>
                  <Card className="card-course-learned ">
                    <Card.Body style={{ fontSize: "15px", padding: "10px 10px 0 10px",  backgroundColor: "#b1dafd", "borderRadius": "15px" }}>
                        <b style={{ paddingTop: "0",  backgroundColor: "transparent" , fontSize: "34px" }} > {total_course_learned} </b>
                        <b style={{ backgroundColor: "transparent" }} >courses</b>
                    </Card.Body>
                  </Card>

                  <Card className="card-course-learned  mt-3">
                    <Card.Body style={{ fontSize: "12px", backgroundColor: "#b1dafd", "borderRadius": "15px"}}>
                      from total <b style={{ backgroundColor: "transparent" }} >{total_courses} courses</b>
                    </Card.Body>
                  </Card>
                </>
              ) : (
                <>
                  <Row className="p-1 d-flex align-items-center" style={{ backgroundColor: "transparent" }}>
                    <Col className="mb-2" xs={12} style={{ fontSize: "15px",backgroundColor: "#b1dafd", borderRadius: "10px" }}>
                      <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}> {total_course_learned} </b>
                      <b style={{ backgroundColor: "transparent" }}>courses</b>
                    </Col>

                    <Col xs={12} style={{ fontSize: "15px",backgroundColor: "#b1dafd", borderRadius: "10px" }}>
                      from total
                      <b style={{ paddingTop: "0", backgroundColor: "transparent", fontSize: "25px" }}> {total_courses} </b>
                      <b style={{ backgroundColor: "transparent" }}>courses</b>
                    </Col>
                  </Row>
                </>
              )} */}
            
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CardCourseLearned;
