import { faClock, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Button, ProgressBar, Badge, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TimeLabel } from "../util/general";
import { getAuthData } from "../util/auth";
import { useNavigate } from "react-router-dom";
import TruncatedHeadingText from "./TruncatedHeadingText";

const CardCourse = ({ course }) => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [showText, setShowText] = useState(false);
  const [labelSubCategoryName, setLabelSubCategoryName] = useState("");
  const course_data = course;
  let color = "#f2994a";
  let completionStatus = course_data.completionStatus;

  completionStatus =
    completionStatus == "not attempted" ? "unstarted" : completionStatus;

  if (completionStatus === "completed") {
    color = "#27ae60";
  }

  if (completionStatus === "unstarted") {
    color = "#eb5757";
  }

  let subCategoryName;

  if (course_data.programName) {
    subCategoryName = course_data.programName;
  } else if (course_data.subCategoryName) {
    subCategoryName = course_data.subCategoryName;
  } else {
    subCategoryName = "-";
  }

  subCategoryName =
    subCategoryName.length > 17
      ? subCategoryName.slice(0, 20) + ".."
      : subCategoryName;

  let trainingHours = TimeLabel(course_data.training_hours);

  const handleMouseEnter = () => {
    setShowText(true);
  };

  const handleMouseLeave = () => {
    setShowText(false);
  };

  let subCatId;

  if (course_data.programId) {
    subCatId = course_data.programId;
  } else {
    subCatId = course_data.SubCategoryId;
  }

  let scoreLabel;

  if (course_data.grade === 0) {
    scoreLabel = "No Score";
  } else {
    scoreLabel = "Score " + course_data.grade;
  }

  let courseMaxLength;

  const isMobile = window.innerWidth <= 575.98;
  const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
  const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
  const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
  const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
  const isExtraExtraLarge = window.innerWidth >= 1400;

  if (isMobile) {
    courseMaxLength = 40;
  } else if (isSmall) {
    courseMaxLength = 35;
  } else if (isMedium) {
    courseMaxLength = 40;
  } else if (isLarge) {
    courseMaxLength = 35;
  } else if (isExtraLarge) {
    courseMaxLength = 30;
  } else if (isExtraExtraLarge) {
    courseMaxLength = 30;
  } else {
    // Set a default value if none of the conditions match
    courseMaxLength = 10;
  }

  const handleEnrollCourse = async () => {
    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/me/enroll_optional";

    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
      Authorization: `Bearer ${token.jwt}`,
      secret: secretCode,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          courseid: course_data.courseId,
          subCategoryId: subCatId,
        }),
      });

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const responseBody = await response.json();
      console.log(responseBody.success);
      if (responseBody.success) {
        navigate(
          `/course/detail/${course_data.courseId}/${subCatId}/${course_data.userid}`
        );
      }
    } catch (err) {
      Swal.fire({
        icon: "warning",
        text: "Something went wrong!",
        confirmButtonText: "OK",
      });
    }
  };

  const handleCardClick = async () => {
    if (
      course_data.enrolled == 1 ||
      ["ongoing", "completed"].includes(course_data.completionStatus)
    ) {
      navigate(
        `/course/detail/${course_data.courseId}/${subCatId}/${course_data.userid}`
      );
      return;
    }

    MySwal.fire({
      title: "Are You Sure ?",
      text: "You will enroll in this course",
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: "Yes",
      confirmButtonText: "No",
      confirmButtonColor: "#DC3546",
      cancelButtonColor: "#39B54A",
    }).then((result) => {
      MySwal.close();
      if (!result.isConfirmed) {
        handleEnrollCourse();
      }
    });
  };

  return (
    <Card
      className="card-course-parent"
      style={{ backgroundColor: "white", cursor: "pointer" }}
      onClick={handleCardClick}
    >
      <div style={{ textDecoration: "none", backgroundColor: "transparent" }}>
        <div className="position-relative" style={{ borderRadius: "15px" }}>
          <Card.Img className="card-course-image" src="/course-image.jpg" />
          <span
            className="badge badge-sm badge-danger position-absolute top-0 end-0 m-0 "
            style={{
              padding: "13px",
              borderTopRightRadius: "15px",
              backgroundColor: "#eb5757",
            }}
          >
            {scoreLabel}
          </span>
          <span
            className="badge badge-danger position-absolute bottom-0 start-0 m-2 p-2"
            style={{ backgroundColor: color }}
          >
            {completionStatus.charAt(0).toUpperCase() +
              completionStatus.slice(1)}
          </span>
        </div>
        <Card.Body className="card-course" style={{ backgroundColor: "white" }}>
          <Card.Title style={{ backgroundColor: "white" }}>
            {/* {course_data.courseName} */}
            <TruncatedHeadingText
              textHeading="h6"
              text={course_data.courseName}
              maxLength={courseMaxLength}
            />
          </Card.Title>

          <div
            className="d-flex flex-column  flex-wrap d-md-none d-lg-none"
            style={{ backgroundColor: "white" }}
          >
            <h5
              className="d-sm-flex d-md-flex gap-10"
              style={{ backgroundColor: "white" }}
            >
              {subCategoryName !== "Unknown" && (
                <Badge
                  id="badge-category"
                  title={course_data.subCategoryName}
                  style={{ fontWeight: "normal" }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {subCategoryName}
                </Badge>
              )}
            </h5>{" "}
            <h5
              className="d-sm-flex d-md-flex gap-10"
              style={{ backgroundColor: "white" }}
            >
              <Badge id="badge-lh" style={{ fontWeight: "normal" }}>
                <FontAwesomeIcon
                  style={{ backgroundColor: "transparent" }}
                  icon={faClock}
                />{" "}
                {trainingHours}
              </Badge>
            </h5>
          </div>

          <Col
            md={true}
            lg={true}
            className="d-none d-md-flex d-lg-flex"
            style={{ backgroundColor: "white" }}
          >
            <div className="d-flex gap-10" style={{ backgroundColor: "white" }}>
              <h5>
                {subCategoryName !== "Unknown" && (
                  <Badge
                    id="badge-category"
                    title={course_data.subCategoryName}
                    style={{ fontWeight: "normal" }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {subCategoryName}
                  </Badge>
                )}
              </h5>
              <h5>
                <Badge id="badge-lh" style={{ fontWeight: "normal" }}>
                  <FontAwesomeIcon
                    style={{ backgroundColor: "transparent" }}
                    icon={faClock}
                  />{" "}
                  {trainingHours}
                </Badge>
              </h5>
            </div>
          </Col>

          <div style={{ marginTop: "10px", backgroundColor: "white" }}>
            <p
              className={
                completionStatus === "completed"
                  ? "progressbar-complete-label"
                  : "progressbar-ongoing-label"
              }
              style={{ backgroundColor: "white", fontSize: "13px" }}
            >
              {course_data.percent_complete}%{" "}
              {completionStatus.charAt(0).toUpperCase() +
                completionStatus.slice(1)}
            </p>
            <ProgressBar
              className={
                completionStatus === "completed"
                  ? "progressbar-complete-color-1"
                  : "progressbar-ongoing-color-1"
              }
              style={{ fontWeight: "100" }}
            >
              <ProgressBar
                className={
                  completionStatus === "completed"
                    ? "progressbar-complete-color-2"
                    : "progressbar-ongoing-color-2"
                }
                now={course_data.percent_complete}
                variant="custom"
              />
            </ProgressBar>
          </div>
        </Card.Body>
      </div>
    </Card>
  );
};

export default CardCourse;
