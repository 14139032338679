import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function CardScheduleList({ data }) {
    console.log(data);

    const startTime = data.start_time;
    const endTime = data.end_time;
    const [startHours, startMinutes] = startTime.split(":");
    const [endHours, endMinutes] = endTime.split(":");

    const formattedStartTime = `${startHours}.${startMinutes}`;
    const formattedEndTime = `${endHours}.${endMinutes}`;

    return (
        <Col className="card-schedule-list row p-2 mb-2">
            {/* <Row className='p-2 border'> */}
                <Col lg={2} md={2} sm={2} xs={2} className=" d-flex flex-column justify-content-center align-items-center py-2">
                    <label style={{ fontSize: '20px' }}><b>{new Date(data.start_date).getDate()}</b></label>
                    <label>{new Date(data.start_date).toLocaleString('default', { month: 'short' })}</label>
                </Col>
                
                <Col lg={8} md={8} sm={8} xs={10} className=" d-flex flex-column justify-content-center align-items-start py-2">
                    <small className="text-muted">{formattedStartTime}- {formattedEndTime} &#x2022; {data.inclassVenue}</small>
                    <label><b>{data.courseName}</b></label>
                </Col>

                <Col lg={2} md={2} className="d-none d-md-flex d-lg-flex flex-column justify-content-center align-items-start align-items-md-center align-items-lg-center py-2">
                    <Link
                        to={`/myclass/detail/${data.courseId}/${data.subCategoryId}/${data.userid}`}
                        className="text-muted" style={{ textDecoration: 'none', position: 'relative' }}>
                        Class Detail
                        <span
                            style={{
                                position: 'absolute',
                                bottom: '-5px',
                                left: '0',
                                width: '100%',
                                height: '1px',
                                backgroundColor: '#d9d9d9'
                            }}
                        />
                    </Link>
                </Col>

                <Col xs={{span:10, offset: 2}} className="d-md-none d-lg-none d-flex flex-column justify-content-center align-items-start align-items-md-center align-items-lg-center py-2">
                    <Link
                        to={`/myclass/detail/${data.courseId}/${data.subCategoryId}/${data.userid}`}
                        className="text-muted" style={{ textDecoration: 'none', position: 'relative' }}>
                        Class Detail
                        <span
                            style={{
                                position: 'absolute',
                                bottom: '-5px',
                                left: '0',
                                width: '100%',
                                height: '1px',
                                backgroundColor: '#d9d9d9'
                            }}
                        />
                    </Link>
                </Col>
            {/* </Row> */}
        </Col>
    )
}

export default CardScheduleList;