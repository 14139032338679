import { createSlice, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initUserLogin = {
    empCode: 0,
    empName: '',
    empPhoto: '',
    empEmail: '',
    isLoggedIn: false
};

const userSlice = createSlice({
    name: 'user',
    initialState: initUserLogin,
    reducers: {
        login(state, action){
            state.empCode = action.payload.empCode;
            state.empName = action.payload.empName;
            state.empEmail = action.payload.empEmail;
            state.isLoggedIn = true;
        },
        logout(state){
            state.empCode = 0;
            state.empName = '';
            state.isLoggedIn = false;
        },
        updateName(state, action){
            state.empName = action.payload
        },
        updatePhoto(state, action){
            state.empPhoto = action.payload.empPhoto
        }
    }
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, userSlice.reducer);

const store = configureStore({
  reducer: {
    user: persistedReducer,
  },
});

export const userActions = userSlice.actions;

export const persistor = persistStore(store);

export default store;