import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useSubmit, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAuthData } from "../util/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCheckCircle,
  faExclamation,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cropper from "react-easy-crop";
import getCroppedImg from "../util/cropImage";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { useRef } from "react";
import CryptoJS from "crypto-js";
import { SHA256 } from "crypto-js";
import { useDispatch } from "react-redux";
import { userActions } from "../store";

function MyProfile() {
  const [show, setShow] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [selectedItem, setSelectedItem] = useState("6 Months");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const submit = useSubmit();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleRetypeNewPasswordChange = (e) => {
    setRetypeNewPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleClose = () => {
    setShow(false);
    setModalId(null);
    setCroppedImage(null);
  };

  const handleShow = (id) => {
    setModalId(id);
    setShow(true);
    setCroppedImage(null);
    setSelectedFile(null);
  };

  const options = {
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Set display property to false to hide the legends
      },
    },
  };

  const [data, setData] = useState(null);
  const [dataLearningActivity, setDataLearningActivity] = useState(null);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchProgress, setFetchProgress] = useState(0);

  const nest_url = process.env.REACT_APP_NEST_URL;
  const url = nest_url + "public/me/my_profile";
  const secretCode = process.env.REACT_APP_SECRET_CODE;
  const encryptionKey = process.env.REACT_APP_PROFILE_PICT_KEY;
  const dispatch = useDispatch();

  const token = getAuthData();

  const headers = {
    Authorization: `Bearer ${token.jwt}`,
    secret: secretCode,
    "Content-Type": "application/json",
  };

  const fetchData = async () => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Could not fetch data");
      }

      const responseBody = await response.json();

      if (responseBody) {
        setData(responseBody);
        setName(responseBody.data.profile[0].empName);
        setEmail(responseBody.data.profile[0].empEmail);

        const updatedUrl = `${
          responseBody.data.profile[0].empPhoto
        }?timestamp=${Date.now()}`;
        setProfilePhoto(updatedUrl);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    setIsFetching(true);
    setFetchProgress(0);

    // setTimeout(() => {
    fetchData();
    // }, 10000);

    fetchDataLearningAct(selectedItem);
  }, [selectedItem]);

  const fetchDataLearningAct = async (periode) => {
    try {
      let url = nest_url + "public/me/getLearningActivitySummary";

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          periode: periode,
        }),
      });

      const responseBody = await response.json();
      if (!responseBody.success) {
        throw new Error("Could not fetch data");
        window.location.href = "/auth";
      }
      setDataLearningActivity(responseBody.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDropdownSelect = (eventKey) => {
    setSelectedItem(eventKey);
    fetchDataLearningAct(eventKey); // Call the API with the selected periode
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if the selected file is a PNG or JPG file
    if (file && /\.(png|jpe?g)$/i.test(file.name)) {
      setSelectedFile(URL.createObjectURL(file));

      const image = new Image();
      image.maxWidth = 300;
      image.src = URL.createObjectURL(file);
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      // Display an error message or handle the invalid file selection
      //   alert("Invalid file format. Please select a PNG or JPG file.");
      toast.error("Invalid file format. Please select a PNG or JPG file.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme: "colored",
      });
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const encryptData = (data) => {
    const encryptedData = CryptoJS.SHA256(data).toString();
    return encryptedData;
  };

  const showCroppedImage = async () => {
    setIsButtonDisabled(true);
    try {
      const croppedImage = await getCroppedImg(
        selectedFile,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);

      const blob = await fetch(croppedImage).then((response) =>
        response.blob()
      );

      const bucketName = process.env.REACT_APP_WASABI_BUCKET;

      const objectKey = `profile-picture/${encryptData(
        data.data.profile[0].empCode.replace("/", "")
      )}.png`;

      const uploadCommand = new PutObjectCommand({
        Bucket: bucketName,
        Key: objectKey,
        Body: blob,
        ACL: "public-read",
      });

      const s3Client = new S3Client({
        region: "ap-southeast-1",
        credentials: {
          accessKeyId: process.env.REACT_APP_WASABI_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_WASABI_SECRET_KEY,
        },
        endpoint: process.env.REACT_APP_WASABI_ENDPOINT,
      });

      const result = await s3Client.send(uploadCommand);

      const url = `${process.env.REACT_APP_WASABI_ENDPOINT}/${bucketName}/${objectKey}`;

      try {
        const response = await fetch(
          nest_url + "public/me/update_profile_picture",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              url: url,
            }),
          }
        );

        const responseBody = await response.json();

        if (responseBody.success) {
          toast.success("Profile picture updated successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            icon: <CustomSuccessIcon />,
            className: "toast-success",
            bodyClassName: "toast-text-color",
            progressClassName: "toast-progress",
          });

          setSelectedFile(null);
          setCroppedImage(null);
          setShow(false);
          setModalId(null);

          const updatedUrl = `${url}?timestamp=${Date.now()}`;
          setProfilePhoto(updatedUrl);

          dispatch(
            userActions.updatePhoto({
              empPhoto: `${url}?timestamp=${Date.now()}`,
            })
          );
        } else {
          toast.error(responseBody.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "colored",
          });
          throw new Error("Could not update data");
        }
      } catch (error) {
        toast.error("Please select image first", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setIsButtonDisabled(false);
  };

  const CustomSuccessIcon = () => {
    return (
      <FontAwesomeIcon style={{ color: "#0f4372" }} icon={faCheckCircle} />
    );
  };

  const handleTest = async (e) => {
    toast.success("Profile updated successfully!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      icon: <CustomSuccessIcon />,
      className: "toast-success",
      bodyClassName: "toast-text-color",
      progressClassName: "toast-progress",
    });
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    let url = nest_url + "public/me/update_profile";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          empCode: data.data.profile[0].empCode,
          empName: name,
          empEmail: email,
        }),
      });

      const responseBody = await response.json();
      console.log(responseBody.message);

      if (responseBody.success) {
        dispatch(
          userActions.login({
            empCode: data.data.profile[0].empCode,
            empName: name,
            empEmail: email,
          })
        );

        toast.success("Profile updated successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: <CustomSuccessIcon />,
          className: "toast-success",
          bodyClassName: "toast-text-color",
          progressClassName: "toast-progress",
        });
        fetchData();
      } else {
        toast.error(responseBody.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // theme: "colored",
        });
        throw new Error("Could not update data");
      }
    } catch (error) {
      // Handle the error
    }
  };

  const handleLogout = () => {
    submit(null, { action: "/logout", method: "post" });
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    let url = nest_url + "public/me/update_password";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          empCode: data.data.profile[0].empCode,
          current_password: currentPassword,
          new_password: newPassword,
          retype_new_password: retypeNewPassword,
        }),
      });

      const responseBody = await response.json();

      if (responseBody.success) {
        toast.success("Password changed successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: <CustomSuccessIcon />,
          className: "toast-success",
          bodyClassName: "toast-text-color",
          progressClassName: "toast-progress",
          onClose: handleLogout,
        });
        // fetchData();
      } else {
        toast.error(responseBody.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // theme: "colored",
        });
        throw new Error("Could not update data");
      }
    } catch (error) {
      // Handle the error
    }
  };

  return (
    <>
      <Container fluid className="p-0 ">
        <Row className="">
          <div className="d-flex justify-content-start pt-3 pb-1 mr-3 ">
            <Link to={`/`} style={{ textDecoration: "none" }}>
              <h6>Dashboard</h6>
            </Link>
          </div>

          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-0 ">
            <h1 className="h4">My Profile</h1>
          </div>
        </Row>

        <Row className="p-1">
          <Col className="">
            <Col className="mb-3">
              <Card className="">
                {data ? (
                  <Card.Body className="card-white-rounded my-profile-photo-container text-center">
                    <img
                      src={profilePhoto}
                      alt="Profile"
                      className="my-profile-photo"
                    />
                    <Card.Title className="mt-3">
                      <h4>{data.data.profile[0].empName}</h4>
                    </Card.Title>
                    <h6>{data.data.profile[0].empCode}</h6>
                    <h6>{data.data.profile[0].empEmail}</h6>
                    <Button
                      bsPrefix="btn btn-change-picture rounded-pill mt-3"
                      onClick={() => handleShow("modal-update-photo")}
                    >
                      Change Picture
                    </Button>
                  </Card.Body>
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col className="mb-3">
              <Card className="">
                {data ? (
                  <Card.Body className="card-white-rounded text-center my-profile-overview-container">
                    <Card.Title className="mt-3">
                      <h5>Overview</h5>
                    </Card.Title>
                    <Card.Subtitle
                      onClick={() => handleShow("modal-learning-activity")}
                      style={{ cursor: "pointer" }}
                    >
                      View My Learning Activity
                    </Card.Subtitle>

                    <ListGroup variant="flush" className="mt-3 p-2">
                      <ListGroup.Item
                        bsPrefix
                        className="p-2 mb-2 my-profile-overview-item d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">Rank</div>
                        <div className="ms-2">
                          <strong>{data.data.overview.rank}</strong>
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item
                        bsPrefix
                        className="p-2 mb-2 my-profile-overview-item d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">Total Point</div>
                        <div className="ms-2">
                          <strong>{data.data.overview.score}</strong>
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item
                        bsPrefix
                        className="p-2 mb-2 my-profile-overview-item d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">Total Certificate</div>
                        <div className="ms-2">
                          <strong>{data.data.overview.cert}</strong>
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item
                        bsPrefix
                        className="p-2 mb-2 my-profile-overview-item d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">Total Course</div>
                        <div className="ms-2">
                          <strong>{data.data.overview.course}</strong>
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item
                        bsPrefix
                        className="p-2 mb-2 my-profile-overview-item d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">Total Hours</div>
                        <div className="ms-2">
                          <strong>{data.data.overview.hours}</strong>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                )}
              </Card>
            </Col>
          </Col>

          <Col lg={8}>
            <Col className="mb-3">
              <Card className="">
                {data ? (
                  <Card.Body className="card-white-rounded profile-data-form">
                    <Card.Title>Profile Data</Card.Title>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="blue-line"
                    ></Col>
                    <Row className="mt-4">
                      <Col lg={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type full name"
                            value={name}
                            onChange={handleNameChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group className="mb-3" controlId="formEmail">
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type e-mail"
                            value={email}
                            onChange={handleEmailChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} className="">
                        <Form.Group className="mb-3" controlId="formDivision">
                          <Form.Label>Job Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Type e-mail"
                            value={data.data.profile[0].jobTitleName}
                            disabled={true}
                          />
                        </Form.Group>
                      </Col>

                      <Col
                        lg={6}
                        className=""
                        style={{
                          paddingTop: "2rem",
                          paddingRight: "0.7rem",
                          paddingLeft: "0.7rem",
                        }}
                      >
                        <div className="d-grid gap-2">
                          <Button
                            bsPrefix
                            className="btn btn-md"
                            id="btn-update-profile"
                            onClick={updateProfile}
                          >
                            Update
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                )}
              </Card>
            </Col>
            <Col>
              <Card className="">
                {data ? (
                  <Card.Body className="card-white-rounded update-password-data-form">
                    <Card.Title>Change Password</Card.Title>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="blue-line"
                    ></Col>
                    <Row className="mt-4">
                      <Col lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="formRecentPassword"
                        >
                          <Form.Label>Recent Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Type your recent password"
                            onChange={handleCurrentPasswordChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="formNewPassword"
                        >
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Type your new password"
                            onChange={handleNewPasswordChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={6} className="">
                        <Form.Group
                          className="mb-3"
                          controlId="formNewPassword"
                        >
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm your new password"
                            onChange={handleRetypeNewPasswordChange}
                          />
                        </Form.Group>
                      </Col>

                      <Col
                        lg={6}
                        className=""
                        style={{
                          paddingTop: "2rem",
                          paddingRight: "0.7rem",
                          paddingLeft: "0.7rem",
                        }}
                      >
                        <div className="d-grid gap-2">
                          <Button
                            bsPrefix
                            className="btn btn-md"
                            id="btn-update-profile"
                            onClick={updatePassword}
                          >
                            Change Password
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                ) : (
                  <div className="skeleton-wrapper">
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                )}
              </Card>
            </Col>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        style={{ background: "rgba(0, 0, 0, 0)" }}
      >
        <Modal.Header closeButton style={{ backgroundColor: "white" }}>
          <Modal.Title style={{ backgroundColor: "white" }}>
            <h5 style={{ backgroundColor: "white" }}>Change Profile Picture</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "white" }}>
          <Form.Group
            controlId="formFile"
            className="mb-3"
            style={{ backgroundColor: "white" }}
          >
            <Form.Label style={{ backgroundColor: "white", color: "#0f4372" }}>
              Choose file
            </Form.Label>
            <Form.Control
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
          </Form.Group>

          {selectedFile && (
            <div>
              <h6>Preview:</h6>
              <img
                src={selectedFile}
                alt="Selected"
                style={{ maxWidth: "300px" }}
              />
              <h6>Crop:</h6>
              <div>
                <Cropper
                  image={selectedFile}
                  crop={crop}
                  zoom={zoom}
                  aspect={3 / 3}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  style={{ background: "transparent" }}
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Container style={{ backgroundColor: "white" }}>
            {/* {selectedFile && (
                                <div className=" row  align-items-center" style={{backgroundColor: 'white'}}>
                                    <div className=" col-auto" style={{backgroundColor: 'white'}}>
                                        <label htmlFor="inputZoom" className="col-form-label" style={{backgroundColor: 'white'}}>Zoom</label>
                                    </div>
                                    <div className=" col d-flex align-items-center " style={{backgroundColor: 'white'}}>
                                        <input type="range" className="form-range w-100" id="inputZoom" />
                                    </div>
                                </div>
                            )} */}

            <Row style={{ backgroundColor: "white" }} className="mt-3">
              <Col
                className="d-flex align-items-center text-muted"
                style={{ backgroundColor: "white" }}
              >
                <FontAwesomeIcon icon={faExclamationCircle} className="" />
                &nbsp; Drag the image to adjust
              </Col>
              <Col
                style={{ backgroundColor: "white" }}
                className="d-flex justify-content-end"
              >
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={showCroppedImage}
                  disabled={isButtonDisabled}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={show && modalId === "modal-learning-activity"}
        onHide={handleClose}
        backdrop="static"
        style={{ background: "rgba(0, 0, 0, 0)" }}
      >
        <Modal.Header>
          <Row className=" w-100">
            <Col lg="3" md="3">
              <h5>Learning Activity</h5>
            </Col>
            <Col
              lg="6"
              md="6"
              className="d-flex align-items-center justify-content-end  text-end"
            >
              <h6 className="m-0" style={{ fontSize: "12px" }}>
                <span
                  className="rounded-circle"
                  style={{ backgroundColor: "#f2c94c" }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>{" "}
                Points
              </h6>
              &nbsp;&nbsp;
              <h6 className="m-0" style={{ fontSize: "12px" }}>
                <span
                  className="rounded-circle"
                  style={{ backgroundColor: "#ff8f52" }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>{" "}
                Hours
              </h6>
              &nbsp;&nbsp;
              <h6 className="m-0" style={{ fontSize: "12px" }}>
                <span
                  className="rounded-circle"
                  style={{ backgroundColor: "#b1dafd" }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>{" "}
                Courses
              </h6>
            </Col>
            <Col lg="1" md="1" className="">
              <Dropdown onSelect={handleDropdownSelect}>
                <Dropdown.Toggle id="dropdown-basic">
                  {selectedItem} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faCalendar} className="ml-3" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1 Month" href="#action1">
                    1 Month
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="6 Months" href="#action2">
                    6 Month
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="12 Months" href="#action3">
                    12 Month
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col lg="2" md="2" className="text-end" sm={{ order: 1 }}>
              <button className="btn-close" onClick={handleClose}></button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="modal-body-rounded">
          <Row className="mb-3">
            <Col>{/* Content goes here */}</Col>
          </Row>

          {data !== null && (
            <Line data={JSON.parse(dataLearningActivity)} options={options} />
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
  //   }
}

export default MyProfile;
