import React, { useState } from 'react'
import { Badge, Button, Col, ProgressBar, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarAlt, faClock, faDesktop, faLayerGroup, faWindowMaximize } from '@fortawesome/free-solid-svg-icons';

function EventWebinarList({ params }) {

    const resTanggal = params.resTanggal;

    const dateTime = new Date(resTanggal);

    // Format the date as "Sun, 12 Dec 2021"
    const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
    const date = dateTime.toLocaleDateString('en-US', options);

    // Format the time as "07.30 pm"
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const time = dateTime.toLocaleTimeString('en-US', timeOptions);


    return (
        <Col className="card-event-list p-1 row mb-2">
            <Col md={4} lg={4} className=" d-flex flex-column justify-content-center align-items-center p-3">
                <div className="image-event-container">
                    <img src="/course-image.jpg" alt="Course Image" className="rounded" />
                </div>
            </Col>
            <Col md={8} lg={8} className="p-3">
                <Row>
                    <Col>
                        <h6 style={{ color: "#0f4372" }}>
                            <FontAwesomeIcon icon={faCalendar} /> &nbsp; {date} &nbsp; &#x2022; &nbsp;
                            <FontAwesomeIcon icon={faClock} /> &nbsp; {time}
                        </h6>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <Link style={{ textDecoration: 'none', backgroundColor: 'transparent' }} to={params.resUrl} target="_blank" rel="noopener noreferrer">
                            <Button className="btn-event-webinar" size="sm">
                                <FontAwesomeIcon icon={faWindowMaximize} /> Webinar
                            </Button>
                        </Link>
                    </Col>
                </Row>

                <Row className='mt-2'>
                    <Col>
                        <h5>
                            <b>{params.resName}</b>
                        </h5>
                    </Col>
                </Row>

                <Row className='mt-1'>
                    <Col className="d-flex gap-10" style={{ backgroundColor: 'white' }}>
                        <p style={{ color: "#0f4372" }}>
                            {params.resTag}
                        </p>
                    </Col>
                </Row>
            </Col>
        </Col>
    )
}

export default EventWebinarList;