import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment';
function WebinarCard({params}) {
  const dateString = params.resTanggal;
  const convertedDate = moment(dateString).format("ddd, DD MMM YYYY . hh.mm a");
  return (
    <Card className="card-course-parent mb-4" style={{ backgroundColor: 'white'}} >
      <Link style={{ textDecoration: 'none', backgroundColor: 'transparent' }} to={params.resUrl} target="_blank" rel="noopener noreferrer">
        <div className="position-relative" style={{ borderRadius: '15px' }}>
            
            <Card.Img className='card-course-image' src="/course-image.jpg" />
            <span className="badge badge-danger position-absolute bottom-0 start-0 m-2 p-2" style={{ backgroundColor: '#eb5757' }}>
              {params.resPoin} Points
            </span>
        </div>
        <Card.Body className='card-course d-none d-md-block d-lg-block' style={{ backgroundColor: 'white'}}>
            <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: "14px" }}>{convertedDate}</Card.Subtitle>
            <Card.Title style={{ backgroundColor: 'white'}} className='mb-2'>
                <h5 style={{fontWeight: 'bold'}}>{params.resName}</h5>
            </Card.Title>
            <Card.Subtitle className="mt-2 text-muted" style={{ fontSize: "14px" }}>{params.resTag}</Card.Subtitle>
        </Card.Body>

        <Card.Body className='card-course d-md-none d-lg-none p-0' style={{ backgroundColor: 'white', padding: '10px 0px 10px 0px !important'}}>
            <Card.Subtitle className="mb-2 mt-2 text-muted" style={{ fontSize: "12px" }}>{convertedDate}</Card.Subtitle>
            <Card.Title style={{ backgroundColor: 'white'}} className='mb-2'>
                <h6 style={{fontWeight: 'bold'}}>{params.resName}</h6>
            </Card.Title>
            <Card.Subtitle className="mt-2 mb-2 text-muted" style={{ fontSize: "14px" }}>{params.resTag}</Card.Subtitle>
        </Card.Body>
      </Link>
    </Card>
  )
}

export default WebinarCard