
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChartBar,
  faChartSimple,
  faStar,
  faStarHalf,
} from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row } from "react-bootstrap";

function CardLevelStatus({params}) {
    if(params == undefined){
        window.location('/auth');
    }
    const points = params.total_score;
    const ranks = params.rank;
    const star = params.badge_star;
    const badge = params.badge;
  return (    
    <Card>
        <Card.Body className="card-level-status">
        <Card.Title
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "17px", fontWeight: 'bold'  }}
        >
            Level Status

            <FontAwesomeIcon icon={faChartSimple} className="card-level-status-icon"/>
        </Card.Title>

        {badge && star >= 1 ? (
            <Row className="mt-4 justify-content-center" style={{ backgroundColor: "transparent" }}>
                {Array.from({ length: Math.floor(star) }, (_, index) => (
                <Col xs="auto" style={{ backgroundColor: "transparent" }} key={`star-${index}`}>
                    <FontAwesomeIcon icon={faStar} className={` ${badge}-star`} />
                </Col>
                ))}
                {star % 1 !== 0 && (
                <Col xs="auto" style={{ backgroundColor: "transparent" }}>
                    <FontAwesomeIcon icon={faStarHalf} className={` ${badge}-star`} />
                </Col>
                )}
            </Row>
        ) : (
        <Row className="mt-4 justify-content-center" style={{ backgroundColor: "transparent" }}>
            <Col xs="auto" style={{ backgroundColor: "transparent" }}>
            <FontAwesomeIcon icon={faStar} className={`no-star`} />
            </Col>
        </Row>
        )}

        
        
        <Row className="mt-4" style={{ backgroundColor: "transparent" }}>
            <Col sm={6} xs={6} className="mb-2" style={{ backgroundColor: "transparent" }}>
                <Card className="card-class-points">
                <Card.Body className="p-1">
                    <Card.Title className="vertical-align">
                    <p style={{ backgroundColor: "transparent", fontWeight: "300", margin: "5px 0" }}>Points</p>
                    <p style={{ backgroundColor: "transparent", fontWeight: "600", margin: "5px 0" }}>{points}</p>
                    </Card.Title>
                </Card.Body>
                </Card>
            </Col>
            <Col sm={6} xs={6} className="mb-2" style={{ backgroundColor: "transparent" }}>
                <Card className="card-class-ranks">
                <Card.Body className="p-1">
                    <Card.Title className="vertical-align">
                    <p style={{ backgroundColor: "transparent", fontWeight: "300", margin: "5px 0" }}>Ranks</p>
                    <p style={{ backgroundColor: "transparent", fontWeight: "600", margin: "5px 0" }}>{ranks}</p>
                    </Card.Title>
                </Card.Body>
                </Card>
            </Col>
        </Row>
        
        </Card.Body>
    </Card>
  );
}

export default CardLevelStatus;
