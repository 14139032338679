import React, { useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import TruncatedText from "./TruncatedText";
import { Link } from "react-router-dom";
import TruncatedSpanText from "./TruncatedSpanText";

function CardCourseProgress(props) {
  
  const [dataCourse, setDataCourse] = useState(null);

  const isMobile = window.innerWidth <= 575.98; 
  const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
  const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
  const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
  const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
  const isExtraExtraLarge = window.innerWidth >= 1400;

  useEffect(() => {
    setDataCourse(props.dataCourses.slice(-3).reverse());
  }, [props.dataCourses]);

  return (
    <Card>
      <Card.Body className="card-course-progress">
        <Card.Title
          className="d-flex justify-content-between align-items-center"
          style={{ fontSize: "13px", fontWeight: 'bold'  }}
        >
          Complete your course!{" "}
            <Link to='/course' className="view-all-activity" style={{fontSize: "13px"}}> 
                View All
            </Link>
        </Card.Title>
        <div className="card-course-progress-content">
          <ListGroup className="custom-list-group">

          {dataCourse !== null && dataCourse.map((item) => {
          const randomKey = Math.floor(Math.random() * 1000000); // Generate a random number

          return (
            <ListGroup.Item className="custom-list-group-item" key={randomKey}>
              <Link to={`/course/detail/${item.courseId}/${item.programId}/${item.userid}`} style={{ textDecoration: 'none', backgroundColor: 'transparent' }}>
                <div className="d-flex justify-content-between custom-list-item mb-2">
                  {/* <TruncatedSpanText icon={faLayerGroup} text={item.courseName} maxLength={15} /> */}

                  {
                    isMobile ? (
                        <TruncatedSpanText icon={faLayerGroup}  text={item.courseName} maxLength={30} />
                    ) : isSmall ? (
                        <TruncatedSpanText icon={faLayerGroup}  text={item.courseName} maxLength={70} />
                    ) : isMedium ? (
                      <TruncatedSpanText icon={faLayerGroup}  text={item.courseName} maxLength={70} />
                    )  : isLarge ? (
                      <TruncatedSpanText icon={faLayerGroup}  text={item.courseName} maxLength={14} />
                    )  : isExtraLarge ? (
                      <TruncatedSpanText icon={faLayerGroup}  text={item.courseName} maxLength={14} />
                    )  : isExtraExtraLarge ? (
                      <TruncatedSpanText icon={faLayerGroup}  text={item.courseName} maxLength={30} />
                    ) : null
                  }

                  <span className=""> {item.percent_complete}%</span>
                </div>
              </Link>
            </ListGroup.Item>
          );
        })}

          </ListGroup>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CardCourseProgress;
