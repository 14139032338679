import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell
  } from "@fortawesome/free-solid-svg-icons";

const Notifications = ({ count, children }) => {
  const isDropdownDisabled = count === 0;
  return (
    <Dropdown className='bg-light'>
      <Dropdown.Toggle
        variant="link"
        className='bg-light'
        id="notications-dropdown"
        disabled={isDropdownDisabled}
      >
        <div className="badge bg-light">
          <FontAwesomeIcon icon={faBell} className="badge-icon" style={{ backgroundColor: 'white' }} />
          {count > 0 && <span className="badge-number">{count}</span>}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-menu-end notification-drop-list '>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Notifications;