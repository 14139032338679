import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function TruncatedHeadingText({ textHeading, icon, text, maxLength }) {
  const headingStyle = {
    backgroundColor: "transparent",
    color: '#0f4372'
  };

  if (text.length <= maxLength) {
    return (
      React.createElement(
        textHeading,
        { title: text, style: headingStyle },
        icon && <FontAwesomeIcon icon={icon} />,
        text
      )
    );
  }

  const truncatedText = text.substring(0, maxLength) + "...";
  return (
    React.createElement(
      textHeading,
      { title: text, style: headingStyle },
      icon && <FontAwesomeIcon icon={icon} />,
      truncatedText
    )
  );
}

export default TruncatedHeadingText;