import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CardProgram = ({params}) => {
  const subCategory = params;
  const isMobile = window.innerWidth <= 575.98; 
  const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
  const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
  const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
  const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
  const isExtraExtraLarge = window.innerWidth >= 1400;
  
  let circle_width  = '70%';
  let circle_height = '70%';


if(isSmall){
    circle_width = "50%";
    circle_height = "50%";
} else if (isMedium){
    circle_width = "50%";
    circle_height = "50%";
} else if (isMobile){
    circle_width = "40%";
    circle_height = "40%";
}

  let subCategoryName = subCategory.subCategoryName || "Unknown";
  subCategoryName = subCategoryName.length > 25 ? subCategoryName.slice(0, 25) + '..' : subCategoryName;

  return (
    <Link to={`/program/detail/${subCategory.subCategoryId}`} style={{ textDecoration: 'none', backgroundColor: 'transparent' }}>
        <Card className="card-course-parent" >
            <Card.Body className='card-program  border-0' style={{ backgroundColor: '#daedfd'}}>
            <Card.Title style={{ fontWeight: 'bold' }} title={subCategory.subCategoryName}>{subCategoryName}</Card.Title>
            
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <svg width={circle_width} height={circle_height} viewBox="0 0 160 160">
                    <defs>
                        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#175084" />
                        <stop offset="100%" stopColor="#4197e5" />
                        </linearGradient>
                    </defs>
                    <circle cx="80" cy="80" r="60" stroke="white" strokeWidth="10" fill="transparent" />
                    <path
                        d="M 80,20 A 60 60 0 1 1 80 140 A 60 60 0 1 1 80 20"
                        stroke="url(#linear)"
                        strokeLinecap="round"
                        strokeWidth="10"
                        fill="transparent"
                        strokeDasharray={`${(subCategory.percent_completion * 376.9) / 100}, 376.9`}
                    />
                    <text x="50%" y="50%" textAnchor="middle" dominantBaseline="central" fontSize="24" fill="#0f4372" style={{ zIndex: 1 }}>
                        {subCategory.percent_completion}%
                    </text>
                </svg>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '15px'}}>
                <div style={{ backgroundColor: '#daedfd', border: 'none', marginRight: '10px' }}>
                    <p style={{ textAlign: 'center', margin: '0' }}>Average Score</p>
                    <p id="avgScoreLabel" style={{ textAlign: 'center', margin: '0', fontWeight: 'bold'  }}>{subCategory.avg_score}</p>
                </div>
                <div style={{ backgroundColor: '#daedfd', border: 'none'}}>
                    <p style={{ textAlign: 'center', margin: '0' }}>Rank</p>
                    <p id="avgRankLabel" style={{ textAlign: 'center', margin: '0', fontWeight: 'bold'  }}>{subCategory.rank} from 256</p>
                </div>
            </div>
        </Card.Body>
        </Card>
    </Link>
  );
};

export default CardProgram;