import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row, Stack } from "react-bootstrap";
import EllipsisButton from "../components/EllipsisButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import EllipsisButtonCourseNav from "../components/EllipsisButtonCourseNav";
import { getAuthData } from "../util/auth";
import { Link, json, redirect, useLoaderData } from "react-router-dom";
import CardCourse from "../components/CardCourse";
import CardScheduleList from "../components/CardScheduleList";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function MyClassPage() {

    const [searchQuery, setSearchQuery] = useState('');

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchProgress, setFetchProgress] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const nest_url = process.env.REACT_APP_NEST_URL;

    const secretCode = process.env.REACT_APP_SECRET_CODE;
    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    if (!token) {
        window.location.href = '/auth';
    }

    const handleSearch = (inputValue) => {
        console.log(inputValue.toLowerCase());
        const filteredData = data.filter(
            (item) =>
                item.courseName.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredData(filteredData);
    };

    const handleSortBySelect = (eventKey) => {
        setSortBy(eventKey);

        let sortedData = [...filteredData]; // Change from data to filteredData
        if (eventKey === "courseNameAsc") {
            sortedData.sort((a, b) =>
                a.courseName.localeCompare(b.courseName)
            );
            setSortBy('Course Name (Asc)');
        } else if (eventKey === "courseNameDesc") {
            sortedData.sort((a, b) =>
                b.courseName.localeCompare(a.courseName)
            );
            setSortBy('Course Name (Desc)');
        }

        setFilteredData(sortedData);
    };

    const [sortOption, setSortOption] = useState('default');
    const handleSort = (option) => {
        setSortOption(option);
    };

    const [sortBy, setSortBy] = useState('Sort By');

    useEffect(() => {
        setIsFetching(true);
        setFetchProgress(0);
        const fetchData = async () => {
            try {
                let url = nest_url + "public/me/my_class";

                const response = await fetch(url, {
                    method: "POST",
                    headers: headers,
                });

                if (!response.ok) {
                    throw new Error('Could not fetch data');
                }

                const responseBody = await response.json();
                setData(responseBody.data);
                setFetchProgress(100);
                setIsFetching(false);
            } catch (error) {
                setError(error);
                window.location.href = '/auth';
            }
        };

        fetchData();

    }, []);



    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    return (
        <>
            <Container fluid className="p-0 " >
                

                <Row className="">
                            <div className="d-flex justify-content-start pt-3 pb-1 mr-3">
                                <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <h6>Dashboard</h6> 
                                </Link>
                            </div>

                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-0">
                                <h1 className="h4">My Class</h1>
                            </div>
                        </Row>

                <Row className="">
                    <Card className="mb-4">
                        
                            {data ? (
                                data.filter(item => item.start_date === getCurrentDate()).length > 0 ? (
                                    <>
                                        <Card.Body className="card-today-schedule">
                                            <Card.Title
                                                className="d-flex justify-content-between align-items-center"
                                                style={{ fontSize: "17px", fontWeight: "bold" }}
                                            >
                                                Today Class Schedule
                                            </Card.Title>
                                            <p>{data.filter(item => item.start_date === getCurrentDate()).length} Class</p>
                                            <div className="p-2 mt-0">
                                                {data
                                                .filter(item => item.start_date === getCurrentDate())
                                                .map(item => <CardScheduleList key={item.inclassId} data={item} />)}
                                            </div>
                                        </Card.Body>
                                    </>
                                ) : (
                                    <Card.Body className="card-today-schedule">
                                            <Card.Title
                                                className="d-flex justify-content-between align-items-center"
                                                style={{ fontSize: "17px", fontWeight: "bold" }}
                                            >
                                                Today Class Schedule
                                            </Card.Title>
                                            <p>No classes scheduled for today.</p>
                                        </Card.Body>
                                )
                            ) : (
                                <div className="skeleton-wrapper w-100" style={{ backgroundColor: "transparent" }}>
                                    <Skeleton style={{ height: "50px", borderRadius: '15px' }} />
                                </div>
                            )}

                    </Card>

                    <Card>
                        {data !== null ? (
                            <Card.Body className="card-today-schedule">
                                <Row className="">
                                    <Col className="">
                                        <Card.Title
                                            className="d-flex justify-content-between align-items-center"
                                            style={{ fontSize: "17px", fontWeight: "bold" }}
                                        >
                                            All Class Schedule
                                        </Card.Title>
                                        {/* 1. Count all total data */}
                                        {data && <p>{data.length} Class</p>}
                                    </Col>
                                    <Col className="d-none d-md-block d-lg-block">
                                        <Stack direction="horizontal" gap={3}>
                                            <div className="search-input-container " >
                                                <input
                                                    type="text"
                                                    placeholder="Search Class"
                                                    className="search-input"
                                                    value={searchInput}
                                                    onChange={(e) => {
                                                        setSearchInput(e.target.value);
                                                        handleSearch(e.target.value); // Call handleSearch on input change
                                                    }}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                    className="search-icon"
                                                    onClick={() => handleSearch()}
                                                />
                                            </div>
                                            <div className="dropdown-container" >
                                                <DropdownButton
                                                    title={sortBy}
                                                    onSelect={handleSortBySelect}
                                                    // className="pill-button"
                                                    id="dropdown-sort"
                                                >
                                                    <Dropdown.Item eventKey="courseNameAsc" className="w-100">Course Name (Asc)</Dropdown.Item>
                                                    <Dropdown.Item eventKey="courseNameDesc" className="w-100">Course Name (Desc)</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </Stack>
                                        {/* <Row>

                                            <Col lg={8} className=" d-flex justify-content-end  " >
                                                <div className=" search-sort-container p-0" >
                                                    <div className="search-input-container " >
                                                        <input
                                                            type="text"
                                                            placeholder="Search Class"
                                                            className="search-input"
                                                            value={searchInput}
                                                            onChange={(e) => {
                                                                setSearchInput(e.target.value);
                                                                handleSearch(e.target.value); // Call handleSearch on input change
                                                            }}
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={faSearch}
                                                            className="search-icon"
                                                            onClick={() => handleSearch()}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} className="d-flex justify-content-end p-0">
                                                <div className="dropdown-container" style={{ width: '100%', height: '100%' }}>
                                                    <DropdownButton
                                                        title={sortBy}
                                                        onSelect={handleSortBySelect}
                                                        // className="pill-button"
                                                        id="dropdown-sort"
                                                    >
                                                        <Dropdown.Item eventKey="courseNameAsc">Course Name (Asc)</Dropdown.Item>
                                                        <Dropdown.Item eventKey="courseNameDesc">Course Name (Desc)</Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Col>

                                        </Row> */}
                                    </Col>

                                    <Col className="d-block d-md-none d-lg-none mb-2" sm={12} xs={12}>
                                        <Stack direction="horizontal" gap={3}>
                                            <Form.Control size="sm" className="me-auto" placeholder="Search Class..." 
                                            value={searchInput}
                                            onChange={(e) => {
                                                setSearchInput(e.target.value);
                                                handleSearch(e.target.value); // Call handleSearch on input change
                                            }}
                                            />
                                            <Dropdown>
                                                <Dropdown.Toggle size="sm" variant="success" id="dropdown-sort-small" stye={{ backgroundColor: "red !important" }}>
                                                    {sortBy}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        active={sortOption === 'courseNameAsc'}
                                                        onClick={() => handleSortBySelect('courseNameAsc')}
                                                    >
                                                        Course Name (Asc)
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        active={sortOption === 'courseNameDesc'}
                                                        onClick={() => handleSortBySelect('courseNameDesc')}
                                                    >
                                                        Course Name (Desc)
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </Stack>
                                    </Col>
                                </Row>

                                <div className="p-2 mt-0">
                                    {searchInput !== "" ? (
                                        filteredData && filteredData.length > 0 ? (
                                            filteredData.map((item) => (
                                                <CardScheduleList key={item.inclassId} data={item} />
                                            ))
                                        ) : (
                                            <p>No matching class found.</p>
                                        )
                                    ) : (
                                        data && data.length > 0 ? (
                                            data.map((item) => (
                                                <CardScheduleList key={item.inclassId} data={item} />
                                            ))
                                        ) : (
                                            <p>No class data available.</p>
                                        )
                                    )}
                                </div>
                            </Card.Body>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                        
                    </Card>
                </Row>

            </Container>
        </>

    );
}

export default MyClassPage;

