import { faCalendar, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import TruncatedSpanText from './TruncatedSpanText';
import TruncatedHeadingText from './TruncatedHeadingText';

const CardCertificate = ({ params }) => {

    const dateString = params.publishDate;
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      });
    
    const [month, day, year] = formattedDate.split(' ');

    const handleDownload = () => {
        window.open(params.cert_url);
    };

    const isMobile = window.innerWidth <= 575.98; 
    const isSmall = window.innerWidth >= 576 && window.innerWidth < 768;
    const isMedium = window.innerWidth >= 768 && window.innerWidth < 992;
    const isLarge = window.innerWidth >= 992 && window.innerWidth < 1200;
    const isExtraLarge = window.innerWidth >= 1200 && window.innerWidth < 1400;
    const isExtraExtraLarge = window.innerWidth >= 1400;

    let text_length;

    if (isMobile) {
      text_length = '10';
    } else if (isSmall) {
      text_length = '10';
    } else if (isMedium) {
      text_length = '15';
    } else if (isLarge) {
      text_length = '20';
    } else if (isExtraLarge) {
      text_length = '20';
    } else if (isExtraExtraLarge) {
      text_length = '20';
    } else {
      text_length = '20'; // Default value if none of the conditions match
    }
    return (
        <Card >
            <Card.Body className="card-cert-2 text-center">
                <img src="/assets_3.png" alt="Certificate" style={{ backgroundColor: '#fffcdb' }} />
                {/* <Row> */}
                    <Col className="d-flex flex-column align-items-center card-cert-info p-2 mt-4" style={{ height: '100%' }}>
                        {/* <h5 style={{ fontWeight: 'bold', backgroundColor: '#fff699' }}>{params.name}</h5> */}
                        <TruncatedHeadingText textHeading="h5" text={params.name} maxLength={text_length} />
                        <h6 className='mt-2' style={{ backgroundColor: '#fff699' }}><FontAwesomeIcon icon={faCalendar} /> {day.replace(/,/g, '')} {month} {year}</h6>
                    </Col>

                    <Col className="d-flex flex-column align-items-center card-cert-info p-2 mt-2" style={{ height: '100%', cursor: 'pointer' }}
                    onClick={handleDownload}
                    >
                        <h6 style={{ backgroundColor: '#fff699' }}>
                            Download <FontAwesomeIcon icon={faDownload} style={{ backgroundColor: '#fff699' }}/> 
                        </h6>
                    </Col>
                {/* </Row> */}
            </Card.Body>
        </Card>
    );
}

export default CardCertificate;