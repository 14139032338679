import React, { useEffect, useState } from 'react'
import { faCalendar, faCalendarAlt, faCertificate, faClock, faFile, faFileAlt, faLanguage, faLayerGroup, faSearch, faStar, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge, Button, Card, Col, Container, Form, Image, InputGroup, Row } from 'react-bootstrap';
import { Link, json, redirect, useParams } from 'react-router-dom';
import { getAuthData, ucfirst } from '../util/auth';
import CourseContentSummary from '../components/CourseContentSummary';
import ClassContentSummary from '../components/ClassContentSummary';
import EllipsisButton from '../components/EllipsisButton';
import QRCode from 'qrcode.react';

function MyClassTrainerActivity() {
    const [activeButton, setActiveButton] = useState(1);
    const [isChecked, setIsChecked] = useState(false);
    const handleButtonClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
    };

    const params = useParams();

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [participant, setParticipant] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchProgress, setFetchProgress] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/me/get_course_content";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    const fetchData = async () => {
        try {

            const response = await fetch(
                url,
                {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({
                        'courseid': params.courseId,
                        'subcategoryid': params.subCategoryId,
                        'userid': params.userId,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();

            setData(responseBody);
            setParticipant(responseBody.data.original.inclass_participant);
            setFetchProgress(100);
            setIsFetching(false);
        } catch (error) {
            setError(error);
            window.location.href = '/auth';
        }
    };

    useEffect(() => {
        setIsFetching(true);
        setFetchProgress(0);
        fetchData();
        // const fetchInterval = setInterval(fetchData, 2000);

        // return () => {
        //     clearInterval(fetchInterval);
        // };
    }, []);

    let ScoreBadgeColor = '#eb5757';
    let CourseStatusBadgeColor = '#f2994a';
    let ProgressBadgeColor = '#f2994a';


    const postData = async (empCode) => {
        if (data) {
            try {
                let url = nest_url + "public/colibricore/absenByTrainer";

                const response = await fetch(url, {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({
                        'token': data.data.original.inclass[0].inclassToken,
                        'empCode': empCode,
                        'courseId': data.data.original.inclass[0].courseId,
                        'subCategoryId': data.data.original.inclass[0].subCategoryId
                    }),
                });

                if (!response.ok) {
                    throw new Error('Could not fetch data');
                }

                const responseBody = await response.json();

                if (responseBody.success) {
                    fetchData();
                }
            } catch (error) {
                console.log('error ', error);
                setError(error);
                // window.location.href = '/auth';
            }
        }
    };

    if (data) {
        const score = data.data.original.grade;
        const courseStatus = data.data.original.completionStatus;
        const courseProgress = data.data.original.percent_complete;

        return (
            <>
                <Container fluid className="p-0 " >
                    <Row className="m-0">
                        <div className="d-flex justify-content-start pt-3 pb-2 mr-3">
                            <Link to={`/myclass`} style={{ textDecoration: 'none' }}>
                                <h6>My Class</h6> 
                            </Link>
                            <h6>&nbsp; &gt; &nbsp;</h6>
                            <h6>{data.data.original.fullname}</h6>
                        </div>
                    </Row>

                    <Row className="m-0 ">
                        <Col lg={6}>
                            <EllipsisButton style={{ width: '50px !important' }}
                                buttonNumber={1}
                                isActive={activeButton === 1}
                                onClick={handleButtonClick}
                                title={"Class Detail"}
                            />

                            <EllipsisButton
                                buttonNumber={2}
                                isActive={activeButton === 2}
                                onClick={handleButtonClick}
                                title={"Participant"}
                            />

                            <EllipsisButton
                                buttonNumber={3}
                                isActive={activeButton === 3}
                                onClick={handleButtonClick}
                                title={"QR Code"}
                            />
                        </Col>



                        {activeButton === 1 && (
                            <>
                                <Col lg={8} className="mt-3">
                                    <Card >
                                        <Card.Body className="card-course-content">
                                            <Card.Title
                                                className="d-flex justify-content-between align-items-center"
                                                style={{ fontSize: "28px", fontWeight: 'bold' }}
                                            >
                                                {data.data.original.fullname}
                                            </Card.Title>
                                            <div className='col-md-6 bg-white' >
                                                <table className="bg-white" style={{ border: 'none', backgroundColor: 'white' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: '10px', border: 'none', backgroundColor: 'white' }}>
                                                                Score
                                                                <br />
                                                                <span className="badge badge-danger p-2 w-100 text-start" style={{ backgroundColor: ScoreBadgeColor }}>
                                                                    <FontAwesomeIcon icon={faStar} style={{ marginRight: '5px', backgroundColor: 'transparent ' }} />
                                                                    {score}
                                                                </span>
                                                            </td>
                                                            <td style={{ padding: '10px', border: 'none', backgroundColor: 'white' }}>
                                                                Course Status
                                                                <br />
                                                                <span className="badge badge-danger p-2  w-100 text-start" style={{ backgroundColor: CourseStatusBadgeColor, fontWeight: 'normal' }}>
                                                                    {ucfirst(courseStatus)}
                                                                </span>
                                                            </td>
                                                            <td style={{ padding: '10px', border: 'none', backgroundColor: 'white' }}>
                                                                Progress
                                                                <br />
                                                                <span className="badge badge-danger p-2  w-100 text-start" style={{ backgroundColor: ProgressBadgeColor, fontWeight: 'normal' }}>
                                                                    {courseProgress} %
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <ClassContentSummary params={data.data} />

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={4} className="mt-3">
                                    <Card >

                                        <Card.Body style={{ backgroundColor: 'white', borderRadius: '15px', border: 'none' }}>
                                            <Card.Img variant="top" src="/course-image.jpg" style={{ borderRadius: '15px' }} />
                                            <Link to={`/course/detail/start/${params.courseId}/${params.subCategoryId}/${params.userId}`} style={{ textDecoration: 'none' }}>
                                                <div className="d-grid gap-2 mt-3">
                                                    <button className="btn btn-success" type="button" id="startNow" style={{ borderRadius: '15px', color: 'white' }}>
                                                        Start Now
                                                    </button>
                                                </div>
                                            </Link>


                                            <Card className='mt-3' >
                                                <Card.Body className="card-course-summary-sort">

                                                    <ul className="list-group list-group-flush">
                                                        <li className="list-group-item">
                                                            <div className="d-flex w-100 justify-content-between">
                                                                <small><FontAwesomeIcon icon={faCalendarAlt} className="nav-icon-sidebar" />Deadline</small>
                                                                <small>{data.data.original.deadline}</small>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="d-flex w-100 justify-content-between">
                                                                <small><FontAwesomeIcon icon={faFileAlt} className="nav-icon-sidebar" />Lectures</small>
                                                                <small>{data.data.original.lectures}</small>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="d-flex w-100 justify-content-between">
                                                                <small><FontAwesomeIcon icon={faClock} className="nav-icon-sidebar" />Durations</small>
                                                                <small>{data.data.original.duration}</small>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="d-flex w-100 justify-content-between">
                                                                <small><FontAwesomeIcon icon={faUserGroup} className="nav-icon-sidebar" />Enrolled</small>
                                                                <small>{data.data.original.enrolled}</small>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="d-flex w-100 justify-content-between">
                                                                <small><FontAwesomeIcon icon={faLanguage} className="nav-icon-sidebar" />Language</small>
                                                                <small>{data.data.original.language}</small>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="d-flex w-100 justify-content-between">
                                                                <small><FontAwesomeIcon icon={faCertificate} className="nav-icon-sidebar" />Certiticate</small>
                                                                <small>{data.data.original.certificate}</small>
                                                            </div>
                                                        </li>
                                                    </ul>


                                                </Card.Body>
                                            </Card>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </>

                        )}

                        {activeButton === 2 && (
                            <Col lg={12} className='mt-3'>
                                <Card >
                                    <Card.Body style={{ backgroundColor: 'white', borderRadius: '15px', border: 'none' }}>
                                        <Col lg={12} md={12} className="mt-2 ">
                                            <Row>
                                                <Col className="d-flex flex-column align-items-start" lg={6} md={12} xs={12} style={{ backgroundColor: "white" }}>
                                                    <h5 style={{ backgroundColor: 'transparent' }}>{data.data.original.fullname}</h5>
                                                    <div className="mt-0" style={{ backgroundColor: 'transparent' }}>
                                                        <h6 style={{ backgroundColor: 'transparent' }}>{participant.length} Participant</h6>
                                                    </div>
                                                </Col>
                                                <Col lg={6} md={12} xs={12} className="text-end " style={{ backgroundColor: "white" }}>
                                                    <Row className="d-flex justify-content-end " style={{ backgroundColor: "transparent" }}>
                                                        <Col lg={8} md={8} sm={8} style={{ backgroundColor: "transparent" }}>

                                                            <div className="search-sort-container" style={{ backgroundColor: "transparent" }}>
                                                                <div className="search-input-container" style={{ backgroundColor: "transparent" }}>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Search Participant"
                                                                        className="search-input"
                                                                        value={searchQuery}
                                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                                    />
                                                                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <Row style={{ backgroundColor: "white" }}>

                                                {participant
                                                    .filter((participant) =>
                                                        participant.empName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                                        participant.empCode.toLowerCase().includes(searchQuery.toLowerCase())
                                                    )
                                                    .map((participant, index) => (
                                                        <Card key={index} className='mt-3' style={{ backgroundColor: "transparent" }}>
                                                            <Card.Body className="card-about-class">
                                                                <Row className="d-flex align-items-center p-0">
                                                                    <Col lg={1} className="">
                                                                        {index + 1}
                                                                    </Col>
                                                                    <Col lg={10} className=" ">
                                                                        <Row>
                                                                            <Col sm={1} className="">
                                                                                <Image className="image-participant" src={`data:image/jpeg;base64,${participant.foto}`} roundedCircle />
                                                                            </Col>
                                                                            <Col sm={11} className="">
                                                                                <Row className="d-flex justify-content-start h-100" style={{ backgroundColor: "transparent", padding: "0 0 0 15px" }}>
                                                                                    <Col className=" d-flex flex-column p-0" lg={6} md={12} xs={12} style={{ backgroundColor: "transparent" }}>
                                                                                        <h5 style={{ backgroundColor: 'transparent', marginTop: 'auto' }}>{participant.empName}</h5>
                                                                                        <h6 style={{ backgroundColor: 'transparent', marginBottom: 'auto' }}>{participant.empCode}</h6>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={1} className="p-0">
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={`default-checkbox-${index}`}
                                                                            label="Present"
                                                                            checked={participant.present_time !== "-" ? true : false}
                                                                            onChange={() => {
                                                                                setIsChecked(!isChecked);
                                                                                postData(participant.empCode);
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    ))}

                                            </Row>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}


                        {activeButton === 3 && (
                            <Col lg={12} className='mt-3'>
                                <Card >
                                    <Card.Body style={{ backgroundColor: 'white', borderRadius: '15px', border: 'none' }}>
                                        <Row className="d-flex justify-content-start " style={{ backgroundColor: "white" }}>
                                            <Col className="d-flex flex-column align-items-center" lg={12} md={12} xs={12} style={{ backgroundColor: "white" }}>
                                                <h4 style={{ backgroundColor: "white" }}>{data.data.original.fullname}</h4>
                                                <div className="mt-0" style={{ backgroundColor: 'white' }}>
                                                    <h6 style={{ backgroundColor: 'white' }}>QR Code</h6>
                                                </div>
                                            </Col>
                                            <Col className="mt-3 d-flex flex-column align-items-center " lg={12} md={12} xs={12} style={{ backgroundColor: "white" }}>
                                                <div className="p-3" style={{ border: '1px solid #0f4372', backgroundColor: "white", borderRadius: '15px' }}>
                                                    <QRCode value={data.data.original.inclass[0].inclassToken} size={300} />
                                                </div>
                                            </Col>
                                            <Col className="mt-3 d-flex flex-column align-items-center" lg={12} md={12} xs={12} style={{ backgroundColor: "white" }}>
                                                <h6 style={{ backgroundColor: "white" }}>Scan QR Code di atas untuk melakukan absensi kehadiran.</h6>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}


                    </Row>
                </Container>
            </>
        )
    }

}

export default MyClassTrainerActivity;
