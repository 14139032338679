import React, { useState } from "react";
import { Badge, Button, Card, Col, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup, ListGroup, Row, Stack } from "react-bootstrap";
import EllipsisButton from "../components/EllipsisButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import EllipsisButtonCourseNav from "../components/EllipsisButtonCourseNav";
import { getAuthData } from "../util/auth";
import { Link } from "react-router-dom";
import CardCourse from "../components/CardCourse";
import { useEffect } from "react";
import CardViewMessage from "../components/CardViewMessage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function InboxPage() {
    const [activeButton, setActiveButton] = useState(1);
    const [filteredInbox, setFilteredInbox] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedMessageIds, setSelectedMessageIds] = useState([]);
    const MySwal = withReactContent(Swal)
    const [selectedMessageId, setSelectedMessageId] = useState(null);

    const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/inbox/getMessage";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    const fetchData = async () => {
        try {

            const response = await fetch(
                url,
                {
                    method: "POST",
                    headers: headers,
                }
            );

            if (!response.ok) {
                throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();

            setData(responseBody.data.inbox);

        } catch (error) {
            setError(error);
            window.location.href = '/auth';
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterInbox = (activeButton) => {
        if (activeButton === 1) {
            // No filter applied, show all messages
            setFilteredInbox(data);
        } else if (activeButton === 2) {
            // Filter for Daily Quiz messages
            setFilteredInbox(data.filter(item => item.msgType === 'daily_quiz'));
        } else if (activeButton === 3) {
            // Filter for News messages
            setFilteredInbox(data.filter(item => item.msgType === 'news'));
        } else if (activeButton === 4) {
            // Filter for Classroom messages
            setFilteredInbox(data.filter(item => item.msgType === 'inclass'));
        }
    };

    useEffect(() => {
        filterInbox(activeButton);
    }, [data]);

    const filteredData = filteredInbox ? filteredInbox.filter((item) => {
        const titleMatch = item.msgTitle.toLowerCase().includes(searchQuery.toLowerCase());
        const descMatch = item.msgDesc.toLowerCase().includes(searchQuery.toLowerCase());
        return titleMatch || descMatch;
    }) : [];

    const handleButtonClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
        filterInbox(buttonNumber);
    };
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const ShortenText = ({ text, maxLength }) => {
        if (text.length <= maxLength) {
            return <span>{text}</span>;
        }

        const shortenedText = text.substring(0, maxLength) + '...';

        return shortenedText;
    };

    const BadgeType = ({ msgType }) => {
        let label = "";
        let class_ = ""
        if (msgType == 'inclass') {
            label = 'Classroom';
            class_ = 'badge-classroom';
        } else if (msgType == 'daily_quiz') {
            label = 'Daily Quiz';
            class_ = 'badge-quiz';
        } else if (msgType == 'news') {
            label = 'News';
            class_ = 'badge-news';
        }

        return <span className={class_} >{label}</span>;

    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: '2-digit' });
        const month = date.toLocaleDateString('en-US', { month: 'short' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }

    const getMessageCountByType = (msgType) => {
        if (data) {
            return data.filter(item => item.msgType === msgType).length;
        }
        return 0;
    };

    const handleClick = (msgId) => {
        setSelectedMessageId(msgId);
    };

    const handleDeleteMessages = async () => {
        // console.log(selectedMessageIds)
        MySwal.fire({
            title: 'Do you want to delete these messages ?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            confirmButtonColor: '#0f4372',
            cancelButtonColor: '#d33',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteMessages();
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })

    }

    const deleteMessages = async () => {
        let url = nest_url + "public/inbox/deleteMessage";
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ 'msgId': selectedMessageIds })
            });

            if (!response.ok) {
                throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();
            console.log(responseBody.success);
            if (responseBody.success) {
                toast.success("Messages deleted successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                fetchData();

                if (selectedMessageIds.includes(selectedMessageId)) {
                    setSelectedMessageId(null);
                }
            }
            // onData('unread');
        } catch (error) {
            // setError(error);
            // window.location.href = '/auth';
        }
    }

    const handleDataFromChild = (data) => {
        setSelectedMessageId(null);
        let message_toast;
        if (data === 'unread') {
            message_toast = 'Message marked as unread!';
        } else if (data === 'delete') {
            message_toast = 'Message deleted successfully!';
        }

        toast.success(message_toast, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

        fetchData();
    };

    return (
        <>
            <Container fluid className="p-0 " >
                

                <Row className="">

                    <Row className="">
                            <div className="d-flex justify-content-start pt-3 pb-1 mr-3 ">
                                <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <h6>Dashboard</h6> 
                                </Link>
                            </div>
                        </Row>

                    <Col>
                      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-0 ">
                        <h1 className="h4">Inbox</h1>
                      </div>
                    </Col>

                    <Col lg={7} sm={12} className=" d-flex justify-content-end align-items-center">
                        <div className=" " style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                            <EllipsisButtonCourseNav
                                buttonNumber={1}
                                isActive={activeButton === 1}
                                onClick={() => handleButtonClick(1)}
                                title={`All (${data ? data.length : 0})`}
                            />

                            <EllipsisButtonCourseNav
                                buttonNumber={2}
                                isActive={activeButton === 2}
                                onClick={() => handleButtonClick(2)}
                                title={`Daily Quiz (${getMessageCountByType('daily_quiz')})`}
                            />

                            <EllipsisButtonCourseNav
                                buttonNumber={3}
                                isActive={activeButton === 3}
                                onClick={() => handleButtonClick(3)}
                                title={`News (${getMessageCountByType('news')})`}
                            />

                            <EllipsisButtonCourseNav
                                buttonNumber={4}
                                isActive={activeButton === 4}
                                onClick={() => handleButtonClick(4)}
                                title={`Classroom (${getMessageCountByType('inclass')})`}
                            />
                        </div>
                    </Col>

                </Row>

                <Row className="p-1 g-3 mb-4">
                    <Col lg={5} className="">
                        <Card className="mb-4">
                        {data ? (
                            <Card.Body className="card-today-schedule p-0">
                                <ListGroup className="" variant="flush" style={{ borderRadius: '15px' }}>
                                    <ListGroup.Item className="p-4">
                                        <div className="search-input-container w-100">
                                            <input
                                                type="text"
                                                placeholder="Search Message"
                                                className="form-control-lg search-input"
                                                value={searchQuery}
                                                onChange={handleSearch}
                                            />
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                className="search-icon"
                                            />
                                        </div>
                                    </ListGroup.Item>


                                    <ListGroup.Item >
                                            <Stack direction="horizontal" gap={3} className=" w-100" style={{padding: '0 1rem 0 1rem'}}>
                                                <div className="">
                                                    <Form.Check type="checkbox" id="check-all"
                                                        checked={selectAll}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setSelectAll(isChecked);
                                                            const messageIds = isChecked ? filteredData.map(item => item.msgId) : [];
                                                            setSelectedMessageIds(messageIds);
                                                        }}
                                                    />
                                                </div>
                                                <div className="w-100">
                                                    Select All
                                                </div>
                                                <div className="">
                                                    <Button
                                                        className="ms-auto"
                                                        variant="light"
                                                        id="btn-delete"
                                                        disabled={selectedMessageIds.length === 0}
                                                        onClick={() => {
                                                            // Perform actions on the selectedMessageIds
                                                            // console.log("Selected Message IDs:", selectedMessageIds);
                                                            handleDeleteMessages();
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} style={{ color: '#86a0b89d' }} />
                                                    </Button>
                                                </div>
                                            </Stack>
                                        </ListGroup.Item>

                                    {filteredData.length > 0 ? (
                                        filteredData.map((item, index) => (
                                            <ListGroup.Item key={item.msgId} style={{ cursor: 'pointer' }} className={item.msgStatus === 'read' ? 'msg-read' : 'msg-unread'}
                                                onClick={() => {
                                                    handleClick(item.msgId);
                                                }}
                                            >

                                                <Stack direction="horizontal" gap={3} className="p-3 w-100">

                                                    <div className="">
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={selectedMessageIds.includes(item.msgId)}
                                                            onChange={(e) => {
                                                                const messageId = item.msgId;
                                                                const isChecked = e.target.checked;
                                                                setSelectedMessageIds(prevIds => {
                                                                    if (isChecked) {
                                                                        return [...prevIds, messageId];
                                                                    } else {
                                                                        return prevIds.filter(id => id !== messageId);
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="w-100">
                                                        <Card className="p-0">
                                                            <Card.Body className="p-0 w-100">
                                                                <Col className="">
                                                                    <Row className="">
                                                                        <Col className="">
                                                                            <Card.Title>{item.msgTitle}</Card.Title>
                                                                            <Card.Subtitle className="mb-2 text-muted">{formatDate(item.msgDate)}</Card.Subtitle>
                                                                        </Col>
                                                                        <Col className="text-end" lg={4}>
                                                                            <BadgeType msgType={item.msgType} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Card.Text>
                                                                    <ShortenText text={item.msgDesc} maxLength={50} />
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>

                                                </Stack>

                                            </ListGroup.Item>
                                        ))
                                    ) : (
                                        <ListGroup.Item>
                                            <div className="text-center">
                                                No message found.
                                            </div>
                                        </ListGroup.Item>
                                    )}

                                </ListGroup>
                            </Card.Body>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                            
                        </Card>
                    </Col>

                    <Col className="">
                        {selectedMessageId !== null && (
                            <CardViewMessage
                                messageId={selectedMessageId}
                                onData={handleDataFromChild}
                            />
                        )}
                    </Col>
                </Row>

            </Container>
            <ToastContainer />
        </>

    );
}

export default InboxPage;
