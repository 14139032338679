
import { Badge, Button, Card, Col, Dropdown, Modal, Row } from "react-bootstrap";
import CardLevelStatus from "./CardLevelStatus";
import CardLearningHours from "./CardLearningHours";
import CardCourseLearned from "./CardCourseLearned";
import { useEffect, useState } from "react";
import { Chart, CategoryScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { getAuthData } from "../util/auth";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function CardLearningOverview({ dataLevelStatus, dataLearningHours, dataCourseLearned }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchProgress, setFetchProgress] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const pointsColor = '#f2c94c';
  const hoursColor = '#ff8f52';
  const courseColor = '#b1dafd';

  const nest_url = process.env.REACT_APP_NEST_URL;

  const secretCode = process.env.REACT_APP_SECRET_CODE;
  const token = getAuthData();

  const headers = {
    Authorization: `Bearer ${token.jwt}`,
    secret: secretCode,
    'Content-Type': 'application/json',
  };

  const [selectedItem, setSelectedItem] = useState("6 Months");

  const fetchData = async (periode) => {
    try {
      setIsFetching(true);
      setFetchProgress(0);

      let url = nest_url + "public/me/getLearningActivitySummary";

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          periode: periode,
        }),
      });

      if (!response.ok) {
        throw new Error('Could not fetch data');
      }

      const responseBody = await response.json();
      setData(responseBody.data);

      setFetchProgress(100);
      setIsFetching(false);
    } catch (error) {
      setError(error);
      window.location.href = '/auth';
    }
  };

  const handleDropdownSelect = (eventKey) => {
    setSelectedItem(eventKey);
    fetchData(eventKey); // Call the API with the selected periode
  };

  useEffect(() => {
    fetchData(selectedItem);
  }, [selectedItem]);

  const options = {
    scales: {
      y: {
        type: 'linear',
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Set display property to false to hide the legends
      },
    },
  };

  return (
    <>
      <Card >
        <Card.Body className="card-overview">
          <Card.Title
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "17px", fontWeight: 'bold' }}
          >
            Overview
            <a href="#" className="view-all-activity" onClick={handleShow}>
              View My Learning Activity
            </a>
          </Card.Title>
          <Row className="mt-3" style={{ backgroundColor: "transparent" }}>
            <Col lg={4} className="mt-3 mt-lg-0" style={{ backgroundColor: "white" }}>
              {dataLevelStatus !== null && (
                <CardLevelStatus params={dataLevelStatus} />
              )}
            </Col>

            <Col lg={4} className="mt-3 mt-lg-0" style={{ backgroundColor: "white" }}>

              {dataLearningHours !== null && (
                <CardLearningHours params={dataLearningHours} />
              )}
            </Col>

            <Col lg={4} className="mt-3 mt-lg-0" style={{ backgroundColor: "white" }}>
              {dataCourseLearned !== null && (
                <CardCourseLearned params={dataCourseLearned} />
              )}
            </Col>
          </Row>

        </Card.Body>
      </Card>

      <Modal size="lg" show={show} onHide={handleClose} backdrop="static" style={{ background: 'rgba(0, 0, 0, 0)' }}>

        {data !== null ? (
                  <>
                    <Modal.Header>
                      <Row className=" w-100">
                        <Col lg="3" md="3">
                          <h5>Learning Activity</h5>
                        </Col>
                        <Col lg="6" md="6" className="d-flex align-items-center justify-content-end  text-end">
                          <h6 className="m-0" style={{ fontSize: '12px' }}>
                            <span className="rounded-circle" style={{ backgroundColor: '#f2c94c' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Points
                          </h6>
                          &nbsp;&nbsp;
                          <h6 className="m-0" style={{ fontSize: '12px' }}>
                            <span className="rounded-circle" style={{ backgroundColor: '#ff8f52' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Hours
                          </h6>
                          &nbsp;&nbsp;
                          <h6 className="m-0" style={{ fontSize: '12px' }}>
                            <span className="rounded-circle" style={{ backgroundColor: '#b1dafd' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Courses
                          </h6>

                        </Col>
                        <Col lg="1" md="1" className="">
                          <Dropdown onSelect={handleDropdownSelect}>
                            <Dropdown.Toggle id="dropdown-basic">
                              {selectedItem} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon icon={faCalendar} className="ml-3" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="1 Month" href="#action1" >1 Month</Dropdown.Item>
                              <Dropdown.Item eventKey="6 Months" href="#action2" >6 Month</Dropdown.Item>
                              <Dropdown.Item eventKey="12 Months" href="#action3" >12 Month</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                        <Col lg="2" md="2" className="text-end" sm={{ order: 1 }}>
                          <button className="btn-close" onClick={handleClose}></button>
                        </Col>
                      </Row>
                    </Modal.Header>
                    <Modal.Body className="modal-body-rounded">
                      <Row className="mb-3">
                        <Col>
                          {/* Content goes here */}
                        </Col>
                      </Row>

                      <Line data={JSON.parse(data)} options={options} />

                    </Modal.Body>
                    </>
                  ) : (
                    <div className="" style={{ backgroundColor: "transparent" }} >
                        <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                          <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                        </div>
                    </div>
                  )}
        
      </Modal>
    </>

  );
}

export default CardLearningOverview;
