import React, { useEffect, useState, useRef } from "react";
import {
  faCalendar,
  faCalendarAlt,
  faCertificate,
  faClock,
  faFile,
  faFileAlt,
  faLanguage,
  faLayerGroup,
  faStar,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { Link, json, redirect, useParams } from "react-router-dom";
import { getAuthData, ucfirst } from "../util/auth";
import CourseContentSummary from "../components/CourseContentSummary";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CourseActivity() {
  const params = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const nest_url = process.env.REACT_APP_NEST_URL;
  const url = nest_url + "public/me/get_course_content";
  const secretCode = process.env.REACT_APP_SECRET_CODE;
  const token = getAuthData();

  const headers = {
    Authorization: `Bearer ${token.jwt}`,
    secret: secretCode,
    "Content-Type": "application/json",
  };

  const componentRef = useRef(null);

  useEffect(() => {
    async function fetchEvents() {
      setIsLoading(true);
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            courseid: params.courseId,
            subcategoryid: params.subCategoryId,
            userid: params.userId,
          }),
        });
        const responseBody = await response.json();
        if (!responseBody.success) {
          const error = new Error(
            "An error occurred while fetching the course"
          );
          error.code = responseBody.status;
          error.info = "error";
          throw error;
        }
        setData(responseBody);
      } catch (error) {
        setError(error);
        window.location.href = "/auth";
      } finally {
        setIsLoading(false);
      }
    }

    fetchEvents();
  }, []);

  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [data]);

  let ScoreBadgeColor = "#eb5757";
  let CourseStatusBadgeColor = "#f2994a";
  let ProgressBadgeColor = "#f2994a";
  let CompleteBadgeColor = "#198754";

  // if(!data){
  //     return null;
  // }

  // if(data.data){
  // const score = data.data.original.grade;
  // const courseStatus   = data.data.original.completionStatus;
  // const courseProgress =  data.data.original.percent_complete;
  return (
    <>
      <div ref={componentRef}>
        <Container fluid className="p-0 ">
          <Row className="">
            <div className="d-flex justify-content-start pt-3 pb-2 mr-3">
              <Link to={`/`} style={{ textDecoration: "none" }}>
                <h6>Dashboard</h6>
              </Link>
              <h6>&nbsp; &gt; &nbsp;</h6>
              <Link to={`/course`} style={{ textDecoration: "none" }}>
                <h6>Course</h6>
              </Link>
            </div>
          </Row>

          <Row className="m-0">
            <Col lg={8} className="mb-3">
              {data !== null ? (
                <Card>
                  <Card.Body className="card-course-content">
                    <Card.Title
                      className="d-flex justify-content-between align-items-center"
                      style={{ fontSize: "28px", fontWeight: "bold" }}
                    >
                      {data.data.original.fullname}
                    </Card.Title>
                    <div className="col-md-6 bg-white">
                      <table
                        className="bg-white"
                        style={{ border: "none", backgroundColor: "white" }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: "10px",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            >
                              Score
                              <br />
                              <span
                                className="badge badge-danger p-2 w-100 text-start"
                                style={{ backgroundColor: ScoreBadgeColor }}
                              >
                                <FontAwesomeIcon
                                  icon={faStar}
                                  style={{
                                    marginRight: "5px",
                                    backgroundColor: "transparent ",
                                  }}
                                />
                                {data.data.original.grade}
                              </span>
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            >
                              Course Status
                              <br />
                              <span
                                className="badge badge-danger p-2  w-100 text-start"
                                style={{
                                  backgroundColor: CourseStatusBadgeColor,
                                  fontWeight: "normal",
                                }}
                              >
                                {ucfirst(data.data.original.completionStatus)}
                              </span>
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            >
                              Progress
                              <br />
                              <span
                                className="badge badge-danger p-2  w-100 text-start"
                                style={{
                                  backgroundColor: ProgressBadgeColor,
                                  fontWeight: "normal",
                                }}
                              >
                                {data.data.original.percent_complete} %
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <CourseContentSummary
                      params={data.data}
                      parameter={params}
                    />
                  </Card.Body>
                </Card>
              ) : (
                <div className="" style={{ backgroundColor: "transparent" }}>
                  <div
                    className="skeleton-wrapper"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                </div>
              )}
            </Col>
            <Col lg={4} className="">
              {data !== null ? (
                <Card>
                  <Card.Body
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      border: "none",
                    }}
                  >
                    <Card.Img
                      variant="top"
                      src="/course-image.jpg"
                      style={{ borderRadius: "15px" }}
                    />
                    <Link
                      to={`/course/detail/start/${params.courseId}/${params.subCategoryId}/${params.userId}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="d-grid gap-2 mt-3">
                        <button
                          className="btn btn-success"
                          type="button"
                          id="startNow"
                          style={{ borderRadius: "15px", color: "white" }}
                        >
                          Start Now
                        </button>
                      </div>
                    </Link>

                    <Card className="mt-3">
                      <Card.Body className="card-course-summary-sort">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                              <small>
                                <FontAwesomeIcon
                                  icon={faCalendarAlt}
                                  className="nav-icon-sidebar"
                                />
                                Deadline
                              </small>
                              <small>{data.data.original.deadline}</small>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                              <small>
                                <FontAwesomeIcon
                                  icon={faFileAlt}
                                  className="nav-icon-sidebar"
                                />
                                Lectures
                              </small>
                              <small>{data.data.original.lectures}</small>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                              <small>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="nav-icon-sidebar"
                                />
                                Durations
                              </small>
                              <small>{data.data.original.duration}</small>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                              <small>
                                <FontAwesomeIcon
                                  icon={faUserGroup}
                                  className="nav-icon-sidebar"
                                />
                                Enrolled
                              </small>
                              <small>{data.data.original.enrolled}</small>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                              <small>
                                <FontAwesomeIcon
                                  icon={faLanguage}
                                  className="nav-icon-sidebar"
                                />
                                Language
                              </small>
                              <small>{data.data.original.language}</small>
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                              <small>
                                <FontAwesomeIcon
                                  icon={faCertificate}
                                  className="nav-icon-sidebar"
                                />
                                Certificate
                              </small>
                              <small>{data.data.original.certificate}</small>
                            </div>
                          </li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </Card>
              ) : (
                <div className="" style={{ backgroundColor: "transparent" }}>
                  <div
                    className="skeleton-wrapper"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
  // }
}

export default CourseActivity;
