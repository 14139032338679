import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import EllipsisButton from "../components/EllipsisButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import EllipsisButtonCourseNav from "../components/EllipsisButtonCourseNav";
import { getAuthData } from "../util/auth";
import { Link, json, redirect, useLoaderData } from "react-router-dom";
import CardCourse from "../components/CardCourse";
import CardScheduleList from "../components/CardScheduleList";
import WebinarCard from "../components/WebinarCard";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function WebinarPage() {
  const [data, setData] = useState(null);
  const [sortedData, setSortedData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchProgress, setFetchProgress] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(null);

  const nest_url = process.env.REACT_APP_NEST_URL;
  const url = nest_url + "public/dashboard/get_webinar";
  const secretCode = process.env.REACT_APP_SECRET_CODE;

  const token = getAuthData();

  const headers = {
    Authorization: `Bearer ${token.jwt}`,
    secret: secretCode,
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    setIsFetching(true);
    setFetchProgress(0);
    const fetchData = async () => {
      try {

        const response = await fetch(
          url,
          {
            method: "POST",
            headers: headers,
          }
        );

        if (!response.ok) {
          throw new Error('Could not fetch data');
        }

        const responseBody = await response.json();

        setData(responseBody.data);
        setFilteredData(responseBody.data);
        setFetchProgress(100);
        setIsFetching(false);
      } catch (error) {
        setError(error);
        window.location.href = '/auth';
      }
    };
    fetchData();
    // const fetchInterval = setInterval(fetchData, 2000);

    // return () => {
    //   clearInterval(fetchInterval);
    // };
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    if (!query) {
      setFilteredData(data); 
    } else {
      setFilteredData(
        data.filter((item) =>
          item.resName.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      <Container fluid className="p-0 " >
                        <Row className="">
                            <div className="d-flex justify-content-start pt-3 pb-1 mr-3">
                                <Link to={`/`} style={{ textDecoration: 'none' }}>
                                    <h6>Dashboard</h6> 
                                </Link>
                            </div>

                            <Row>
                              <Col>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-0">
                                  <h1 className="h4">Webinar</h1>
                                </div>
                              </Col>
                              <Col lg={4} className=" d-flex justify-content-end ">
                                <div className=" search-sort-container p-0 w-100"  >
                                  <div className="search-input-container " >
                                    <input
                                      type="text"
                                      placeholder="Search Webinar"
                                      className="search-input"
                                      value={searchQuery}
                                      onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <FontAwesomeIcon
                                      icon={faSearch}
                                      className="search-icon"
                                      onClick={() => handleSearch()}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                        </Row>
        

        <Row className="mt-2 mt-lg-2">
          <Card className="mb-4">

                  {data !== null ? (
                    <Card.Body className="card-today-schedule">
                      <Row className=" ">
                        {filteredData &&
                          filteredData
                            .filter((item) => {
                              if (!searchQuery) {
                                return true; // No search query, show all items
                              } else {
                                // Filter items based on the search query
                                return item.resName.toLowerCase().includes(searchQuery.toLowerCase());
                              }
                            })
                            .map((item) => (
                              <Col className="" xs={6} sm={6} lg={4} key={`item-${item.resId}`}>
                                <WebinarCard params={item} />
                              </Col>
                            ))}
                      </Row>
                    </Card.Body>
                  ) : (
                    <div className="" style={{ backgroundColor: "transparent" }} >
                        <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                          <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                        </div>
                    </div>
                  )}

          </Card>


        </Row>

      </Container>
    </>

  );
}

export default WebinarPage;

