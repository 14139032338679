import { faAngleDown, faAngleUp, faCalendarAlt, faCertificate, faClock, faFileAlt, faLanguage, faLayerGroup, faStar, faStarHalf, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Tabs, Tab, Nav, Row, Col, Card, Accordion, Button, Table, AccordionButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ucwords } from '../util/general';

function ClassContentSummary({ params, parameter }) {
  const isMobile = window.innerWidth <= 767; // Check if viewing on a mobile device
  const star = params.original.ratings;
  const badge = 'gold';
  const course = params;
  const courseContents = params.original.contents;
  const courseModules = params.original.contents[0].modules;
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  console.log(parameter);
  console.log(params);
  console.log('courseModules ' + courseModules);

  let start_date = "";
  let start_time = "";
  let end_time  = "";

  if(params.original.inclass[0] !== undefined){
    start_date = params.original.inclass[0].start_date;
    start_time = params.original.inclass[0].start_time;
    end_time = params.original.inclass[0].end_time;
  }

  let totalModules = 0;
  for (let i = 0; i < courseContents.length; i++) {
    totalModules += courseContents[i].modules.length;
  }

  if (isMobile) {
    return (
      <>
        <div id="mobile-tabs" className='border' >
          <Tabs defaultActiveKey="info"  >
            <Tab eventKey="info" title="Info" style={{ backgroundColor: 'white' }} >
              <div className="tab-content mt-3" style={{ backgroundColor: '#fcfcfc' }}>
                <div className="tab-pane-content p-3 " style={{ backgroundColor: '#fcfcfc' }}>
                  <div className="row" style={{ backgroundColor: '#fcfcfc' }}>
                    <div className="col-sm p-0" style={{ backgroundColor: '#fcfcfc' }}>

                      <div className="card p-0 mb-1" style={{ backgroundColor: '#fcfcfc' }}>
                        <div className="row g-0 p-1 align-items-center" style={{ backgroundColor: '#fcfcfc' }}>

                          <div className="col-md-4 col-4 p-0 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#fcfcfc' }}>
                            <img
                              style={{ backgroundColor: '#fcfcfc', width: '70%', height: 'auto', maxWidth: '100px' }}
                              src="/profil-pic.jpg"
                              alt="Profile"
                              className="circle-image-dropdown-img increased-image-size-mobile"
                            />
                          </div>

                          <div className="col-md-8 col-8 p-0 d-flex align-items-center">
                            <div className="card-body p-2" style={{ backgroundColor: '#fcfcfc' }}>
                              <div className="d-flex flex-column" style={{ backgroundColor: '#fcfcfc' }}>
                                <h6 className="card-title m-0" style={{ fontSize: '15px', fontWeight: 'bold', backgroundColor: '#fcfcfc' }}>Created By</h6>
                                <h6 className="card-title mt-2" style={{ fontSize: '15px', backgroundColor: '#fcfcfc' }}>Me</h6>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                    <div className="col-sm p-0 border-top" style={{ backgroundColor: '#fcfcfc' }}>
                      <div className="card p-1 mt-0 " style={{ backgroundColor: '#fcfcfc' }}>
                        <div className="row g-0 p-0 align-items-center" >
                          <div className="col-md-12 p-0 d-flex align-items-center">
                            <div className="card-body p-2" style={{ backgroundColor: '#fcfcfc' }}>
                              <div className="d-flex flex-column " style={{ backgroundColor: '#fcfcfc' }}>
                                <h6 className="card-title m-0" style={{ backgroundColor: '#fcfcfc', fontSize: '15px', fontWeight: 'bold' }}>Program</h6>
                                <h6 className="card-title mt-2" style={{ backgroundColor: '#fcfcfc', fontSize: '15px' }}>{params.original.subCategoryName}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm p-0 border-top" style={{ backgroundColor: '#fcfcfc' }}>
                      <div className="card p-1 mt-0 " style={{ backgroundColor: '#fcfcfc' }}>
                        <div className="row g-0 p-0 " >
                          <div className="col-md-12 p-0  ">
                            <div className="card-body p-2" style={{ backgroundColor: '#fcfcfc' }}>
                              <div className="" style={{ backgroundColor: '#fcfcfc' }}>
                                <h6 className="card-title m-0" style={{ backgroundColor: '#fcfcfc', fontSize: '15px', fontWeight: 'bold' }}>Review</h6>
                                <div className=' w-100' style={{ backgroundColor: '#fcfcfc' }}>
                                  <span className='mt-2 ' style={{ display: 'inline-block', backgroundColor: '#fcfcfc' }}>
                                    {Array.from({ length: Math.floor(star) }, (_, index) => (
                                      <FontAwesomeIcon key={`star-${index}`} icon={faStar} className={`bronze-star ${badge}-star`} style={{ color: 'gold', fontSize: '20px' }} />
                                    ))}
                                    {star % 1 !== 0 && (
                                      <FontAwesomeIcon icon={faStar} className={`bronze-star ${badge}-star`} style={{ color: 'gold', fontSize: '20px' }} />
                                    )}
                                    <small style={{ color: '#0f4372', fontSize: '13px' }}> 5.0 ( 1k reviews )</small>
                                  </span>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="about" title="About" style={{ borderRadius: '15px', border: 'none' }}>
              <Card className='mt-3' >
                <Card.Body className="card-about" >
                  <Card.Title
                    className="d-flex justify-content-between align-items-center"
                    style={{ fontSize: "17px", fontWeight: 'bold' }}
                  >
                    About Course

                  </Card.Title>

                  <p>{params.original.contents[0].summary}</p>

                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="content" title="Content" style={{ borderRadius: '15px', border: 'none' }}>
              <Card className='mt-3'>
                <Card.Body className="card-course-content-list h-100">
                  <Card.Title
                    className="d-flex justify-content-between align-items-center"
                    style={{ fontSize: "17px", fontWeight: 'bold' }}
                  >
                    Course
                    <Link to='/course' className="view-all-activity">
                      Start Now
                    </Link>
                  </Card.Title>

                  <p className="mb-1">4 Parts . 14 Lessons . 3.5 Hours</p>

                  <Accordion className="mt-2" defaultActiveKey="0" >

                    {courseModules && courseModules.map(item => (
                      <Accordion.Item eventKey={`item-${item.id}`} key={`item-${item.id}`}
                      >
                        <Accordion.Header>{item.name}</Accordion.Header>
                        <Accordion.Body style={{ backgroundColor: '#fcfcfc' }}>
                          <Table bsPrefix>
                            <tbody >
                              <tr style={{ cursor: 'pointer' }}>
                                <td style={{ width: "5%", backgroundColor: "#fcfcfc" }}>
                                  <a href={item.open_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                    <FontAwesomeIcon icon={faLayerGroup} className="nav-icon-sidebar" />
                                  </a>
                                </td>
                                <td style={{ width: "70%", backgroundColor: "#fcfcfc" }}>
                                  <a href={item.open_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>{item.name}</a>
                                </td>
                                <td className="text-end" style={{ width: "25%", backgroundColor: "#fcfcfc" }}>
                                  <a href={item.open_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>{item.modplural}</a>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>

                </Card.Body>
              </Card>
            </Tab>
          </Tabs>
        </div>
      </>

    );
  }

  return (
    <>
      <div id="info-class" className="p-3 border mt-3" style={{ backgroundColor: '#fcfcfc !important' }}>
        <div className="row  p-0" >

          <div className="col-3 p-0 " style={{ backgroundColor: '#fcfcfc' }}>
            <div className="card p-2" style={{ backgroundColor: '#fcfcfc !important' }}>
              <div className="row g-0 p-0 align-items-center" style={{ backgroundColor: '#fcfcfc' }} >

                <div className="col-md-4 p-0 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#fcfcfc !important' }}>
                  <img style={{ backgroundColor: '#fcfcfc !important' }}
                    src="/profil-pic.jpg"
                    alt="Profile"
                    className="circle-image-dropdown-img increased-image-size"
                  />
                </div>

                <div className="col-md-8 p-0 d-flex align-items-center">
                  <div className="card-body p-2" style={{ backgroundColor: '#fcfcfc' }}>
                    <div className="d-flex flex-column " style={{ backgroundColor: '#fcfcfc' }}>
                      <h6 className="card-title m-0" style={{ backgroundColor: '#fcfcfc', fontSize: '15px', fontWeight: 'bold' }}>Created By</h6>
                      <h6 className="card-title mt-2" style={{ backgroundColor: '#fcfcfc', fontSize: '14px' }}>{params.original.created_by}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col p-0 border-left" style={{ backgroundColor: '#fcfcfc' }} >
            <div className="card p-1 mt-0 " >
              <div className="row g-0 p-0 align-items-center" >
                <div className="col-md-12 p-0 d-flex align-items-center">
                  <div className="card-body p-2" style={{ backgroundColor: '#fcfcfc' }}>
                    <div className="d-flex flex-column " style={{ backgroundColor: '#fcfcfc' }}>
                      <h6 className="card-title m-0" style={{ backgroundColor: '#fcfcfc', fontSize: '15px', fontWeight: 'bold' }}>Program</h6>
                      <h6 className="card-title mt-2" style={{ backgroundColor: '#fcfcfc', fontSize: '15px' }}>{params.original.subCategoryName}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col p-0 border-left" style={{ backgroundColor: '#fcfcfc' }} >
            <div className="card p-1 mt-0 " >
              <div className="row g-0 p-0 " >
                <div className="col-md-12 p-0  ">
                  <div className="card-body p-2" style={{ backgroundColor: '#fcfcfc' }}>
                    <div className="" style={{ backgroundColor: '#fcfcfc' }}>
                      <h6 className="card-title m-0" style={{ backgroundColor: '#fcfcfc', fontSize: '15px', fontWeight: 'bold' }}>Review</h6>
                      <div className=' w-100' style={{ backgroundColor: '#fcfcfc' }}>
                        <span className='mt-2 ' style={{ display: 'inline-block', backgroundColor: '#fcfcfc !important' }}>
                          {Array.from({ length: Math.floor(star) }, (_, index) => (
                            <FontAwesomeIcon key={`star-${index}`} icon={faStar} className={`bronze-star ${badge}-star`} style={{ color: 'gold', fontSize: '20px' }} />
                          ))}
                          {star % 1 !== 0 && (
                            <FontAwesomeIcon icon={faStar} className={`bronze-star ${badge}-star`} style={{ color: 'gold', fontSize: '20px' }} />
                          )}
                          <small style={{ color: '#0f4372', fontSize: '13px', backgroundColor: '#fcfcfc' }}> {params.original.ratings} ( 1k reviews )</small>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <Card className='mt-3' style={{ backgroundColor: '#fcfcfc !important', color: '#0f4372' }}>
        <Card.Body className="card-about-class" style={{ color: '#0f4372', backgroundColor: '#fcfcfc !important' }}>

          <Table>
            <tbody>
              <tr>
                <td className="td-40" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <h6>Assignment Teacher</h6>
                </td>
                <td className="td-60" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <small>{params.original.inclass[0] !== undefined ? params.original.inclass[0].empName : ""}</small>
                </td>
              </tr>
              <tr>
                <td className="td-40" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <h6>Venue</h6>
                </td>
                <td className="td-60" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <small>{params.original.inclass[0] !== undefined ? params.original.inclass[0].inclassVenue : ""}</small>
                </td>
              </tr>
              <tr>
                <td className="td-40" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <h6>Schedule</h6>
                </td>
                <td className="td-60" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <small>{start_date} &#183; {start_time} - {end_time}</small>
                </td>
              </tr>
              <tr>
                <td className="td-40" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <h6>Equipment</h6>
                </td>
                <td className="td-60" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <small>{params.original.inclass[0] !== undefined ? params.original.inclass[0].inclassEquipment : ""}</small>
                </td>
              </tr>
              <tr>
                <td className="td-40" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <h6>Dress</h6>
                </td>
                <td className="td-60" style={{ color: '#0f4372', backgroundColor: '#fcfcfc' }}>
                  <small>{params.original.inclass[0] !== undefined ? params.original.inclass[0].inclassDresscode : ""}</small>
                </td>
              </tr>
            </tbody>
          </Table>

        </Card.Body>
      </Card>

      <Card className='mt-3' style={{ backgroundColor: '#fcfcfc !important' }}>
        <Card.Body className="card-about-class">
          <Card.Title
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "17px", fontWeight: 'bold' }}
          >
            About Class

          </Card.Title>

          <p>{params.original.inclass[0] !== undefined ? params.original.inclass[0].inclassDescription : ""}</p>

        </Card.Body>
      </Card>


      <Card className='mt-3'>
        <Card.Body className="card-class-content-list h-100">
          <Card.Title
            className="d-flex justify-content-between align-items-center"
            style={{ fontSize: "17px", fontWeight: 'bold' }}
          >
            Class Content

            <Link
              to={`/course/detail/start/${parameter.courseId}/${parameter.subCategoryId}/${parameter.userId}`}
              className="text-muted" style={{ fontSize: '15px', fontWeight: 'normal', textDecoration: 'none', position: 'relative' }}>
              Start Now
              <span
                style={{
                  position: 'absolute',
                  bottom: '-5px',
                  left: '0',
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#d9d9d9'
                }}
              />
            </Link>
          </Card.Title>
          
          <p className="mb-1">{courseContents.length} Parts . {totalModules} Lessons </p>

          <Accordion className="mt-2">

            {courseContents && courseContents.map((item, index) => (
              <Accordion.Item eventKey={`item-${item.id}`} key={`item-${item.id}`}>
                <Accordion.Header onClick={() => setIsAccordionOpen(prevState => ({
                  ...prevState,
                  [index]: !prevState[index]
                }))}>
                  <Col style={{ backgroundColor: 'transparent' }}>
                    <AccordionButton
                      bsPrefix
                      style={{ border: 'none', color: '#0f4372', marginRight: '10px', backgroundColor: 'transparent' }}
                      onClick={() => setIsAccordionOpen(prevState => ({
                        ...prevState,
                        [index]: !prevState[index]
                      }))}
                    >
                      <FontAwesomeIcon style={{ backgroundColor: 'transparent' }} icon={isAccordionOpen[index] ? faAngleUp : faAngleDown} />
                    </AccordionButton>
                    <b style={{ backgroundColor: 'transparent' }}>{item.name}</b>
                  </Col>
                  <Col lg={2} className="text-end" style={{ backgroundColor: 'transparent' }}>
                    {item.modules.length} Lessons
                  </Col>
                </Accordion.Header>

                <Accordion.Body style={{ backgroundColor: '#f0f7fd' }}>
                  {item.modules.length > 0 && item.modules.map((moduleItem, moduleIndex) => (

                    <Row key={moduleIndex} className="mb-2" style={{ backgroundColor: 'red' }}>
                      <Col className="" style={{ fontSize: '14px', backgroundColor: '#f0f7fd' }}>
                        <FontAwesomeIcon icon={faLayerGroup} className="nav-icon-sidebar" />
                        {moduleItem.name}
                      </Col>
                      <Col lg={3} className="text-end" style={{ fontSize: '14px', backgroundColor: '#f0f7fd' }}>
                        {ucwords(moduleItem.modname)}
                      </Col>
                    </Row>

                  ))}
                </Accordion.Body>

              </Accordion.Item>
            ))}
          </Accordion>

        </Card.Body>
      </Card>
    </>
  );
}


export default ClassContentSummary;